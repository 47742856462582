<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-10">
                        <div class="card otp-card">
                                <div class="header pb-1 text-center">
                                    <p class="lead px-3">Ring Ring...</p>
                                </div>
                                <div class="body pt-1">
                                    <p class="text-center mb-3">Enter the verification code we just sent via text to your mobile phone <strong>+12105806855</strong></p>
                                    <p class="text-center mb-3"><a class="mobile-number" href="#">Change your Mobile Number</a></p>
                                    <div class="otp-form">
                                        <div id="otp" class="d-flex justify-content-center">
                                            <div class="form-group">
                                                <v-otp-input
                                                    ref="otpInput"
                                                    input-classes="otp-input form-control m-2"
                                                    separator=" "
                                                    :num-inputs="5"
                                                    :should-auto-focus="true"
                                                    :is-input-num="false"
                                                    @on-change="handleOnChange"
                                                    @on-complete="handleOnComplete"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="otp-timer text-center" id="otp_timer">
                                        <router-link to="#" @click.native="resendOtp()">Resend OTP</router-link>
                                        <p>
                                            <span class="text-danger">{{ wrongOtpMsg }}</span>
                                        </p>
                                        <p class="mt-3 mb-0"> 
                                            You can request a new code if it does not arrive in: 
                                            <label class="timer-text" id="timer_countdown"></label>
                                        </p>
                                    </div>
                                </div>
                        </div>
                        <div class="body text-center pb-4">
                            <img :src="welcome_messages.standApp_image" class="banner-img-powered" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:'OtpComponent',
    components: {
        
    },data(){
        return{
            show_banner: false,
            clearTimer:0,
            welcome_messages:{
                heading:'Welcome to ONE WORLD',
                sub_heading:'Manage all your business receipts and payments in one place',
                banner_li: [
                    '<li><p class="mb-0 d-flex align-items-center"><span>All your payments</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All your collections</p></li> <li><p class="mb-0 d-flex align-items-center"> <span>Your invoicing</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All the ways your customers pay</span></p></li>'
                ],
                button_text:'Sign up for free in 3 minutes',
                banner_image: require(`@/assets/home_image/banner.svg`),
                standApp_image:  require(`@/assets/home_image/Powered_by_StandApp.svg`),
            },
            wrongOtpMsg:''
        }
    },methods: {
        redirectLoginPage: function() {
           this.$router.push({ path: '/authentication/page-login-v2' })
        },

        //timer counter
        countDownTimer() {
            var duration = 24 * 60 * 60
            var timer = duration, hours, minutes, seconds;
            this.clearTimer = setInterval(function () {
                if(document.getElementById("timer_countdown")){
                    hours = parseInt((timer /3600)%24, 10)
                    minutes = parseInt((timer / 60)%60, 10)
                    seconds = parseInt(timer % 60, 10);

                    hours = hours < 10 ? "0" + hours : hours;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    seconds = seconds < 10 ? "0" + seconds : seconds;
                    document.getElementById("timer_countdown").innerHTML = hours +":"+minutes + ":" + seconds;
                }
                --timer;
            }, 1000);
        },
        
        //reset timer 
        resendOtp(){
            clearInterval(this.clearTimer);
            return this.countDownTimer();
        },

        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },1000)
        },

        handleOnComplete(value) {
            // console.log('OTP completed: ', value);
            if(value == '11111'){
                this.wrongOtpMsg = 'Please Enter Valid OTP'
            } else{
                this.redirectLoginPage();
            }
        },
        
        // handleOnChange(value) {
        //     console.log('OTP changed: ', value);
        // }
    },created() {
        //OTP Timer
        clearInterval(this.clearTimer)
        this.countDownTimer()
    },beforeDestroy(){
        clearInterval(this.clearTimer)
    }
}
</script>
<style scoped>

</style>