<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
           <div class="col-lg-9 col-md-12">
                <div class="card pay-or-transfer no-shadow card-top-line">
                    <div class="body">
                        <b-tabs content-class="px-0" nav-class="nav-tabs-new2" :no-nav-style="true">
                            <b-tab class="transfer-tab" title="Transfer" active>
                                <b-card class="no-shadow" body-class="p-0">
                                    <div class="counterparties-area">
                                        <div class="d-flex justify-content-between align-items-center mb-4">
                                            <h6 class="mb-0">Beneficiaries</h6>
                                            <div class="see-all">
                                                <a href="javascript:void(0);">See all</a>
                                            </div>
                                        </div>
                                        <ul class="list-unstyled mb-0 transfers-list">
                                            <li class="mb-0" v-for="(counterPartiesSee,index) in counterPartiesSees" :key="index">
                                                <b-card class="shadow-all mb-2 card-body-p-2">
                                                    <div class="d-flex align-items-center">
                                                        <div class="left">
                                                            <i aria-hidden="true" class="fa">{{ counterPartiesSee.name | short_hand }}</i>
                                                        </div>
                                                        <div class="right">
                                                            <strong class="font-16">{{ counterPartiesSee.name }}</strong>
                                                            <p>{{ counterPartiesSee.total_accounts }} accounts</p>
                                                        </div>
                                                    </div>
                                                </b-card>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="transfer-info-txt mt-5">
                                        <ul class="list-unstyled mb-0">
                                            <li>
                                                <label>Payment approval</label>
                                                <b-card class="shadow-all">
                                                    <p class="mb-0 d-flex"><i class="fa fa-clock-o font-20 mr-2" aria-hidden="true"></i> No payments to review</p>
                                                </b-card>
                                            </li>
                                            <li>
                                                <label>Draft</label>
                                                <b-card class="shadow-all">
                                                    <p class="mb-0 d-flex"><i class="fa fa-clock-o font-20 mr-2" aria-hidden="true"></i> No draft payments</p>
                                                </b-card>
                                            </li>
                                            <li>
                                                <label>Failed</label>
                                                <b-card class="shadow-all">
                                                    <p class="mb-0 d-flex"><i class="fa fa-clock-o font-20 mr-2" aria-hidden="true"></i> No Failed payments</p>
                                                </b-card>
                                            </li>
                                        </ul>
                                    </div>
                                </b-card>
                            </b-tab>
                            <b-tab title="Requests"><p>Requests</p></b-tab>
                            <b-tab title="Scheduled"><p>Scheduled</p></b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
export default {
    name:'BeneficiariesComponent',
    components: {
        BreadCrumb,
    },data (){
        return{
            counterPartiesSees:[
                {
                    name:'PAYINC GROUP LIMITED',
                    total_accounts:6,
                },
                {
                    name:'Jones Amegbor',
                    total_accounts:3,
                },
                {
                    name:'7 LCH SA',
                    total_accounts:1,
                },
                {
                    name:'Natwest Markets PLC',
                    total_accounts:5,
                },
                {
                    name:'Societe Generale S.A.',
                    total_accounts:3,
                }
            ]
        }
    },filters: {
        short_hand (company_name) {
            // You can put your logic here.
            let words = company_name.split(" ")
            let short_hand = words[0][0] + words[words.length-1][0]
            return short_hand // <-- The return value as Per logic
        }
    }
}

</script>