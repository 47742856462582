<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Manage Business</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search business" aria-label="Search business" aria-describedby="search-business" v-model="searchText" v-on:keyup.enter="refreshBusinessTable">
                                    <div class="input-group-append" @click="refreshBusinessTable()">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                                <!-- <b-button class="fliter-btn btn-lg btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                    <div class="d-flex justify-content-between p-3">
                                        <div class="form-group form-select single-multiselect">
                                            <span class="float-label">
                                                <multiselect
                                                    placeholder="Select Filter Type"
                                                    v-model="filterBy"  
                                                    :show-labels="false" 
                                                    :options="filterOption" 
                                                    label="name"
                                                    :searchable="true">
                                                </multiselect> 
                                            </span>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group form-select single-multiselect">
                                            <span class="float-label">
                                                <multiselect
                                                    placeholder="Select Filter Type"
                                                    v-model="condition"  
                                                    :show-labels="false" 
                                                    :options="conditionList" 
                                                    label="name"
                                                    :searchable="true">
                                                </multiselect> 
                                            </span>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Enter Value" v-model="filterValue" />
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-primary h-100" @click="isVisibleFilter = false; refreshBusinessTable()">Apply</button>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false; resetBusinessValue()">Cancel</button>
                                        </div>
                                    </div>
                                </b-collapse> -->
                            </div>
                        </div>
                        <!-- table datas-->
                        <b-table 
                            ref = "businessTable"
                            responsive
                            hover 
                            outlined
                            table-class="js-basic-example table-custom mb-0"
                            head-variant="light"
                            :items="getBusinessList" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(status)="data">
                                <p class="function-badge badge-group-table mb-0">
                                    <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                </p>
                            </template>
                            <template #cell(uuid)="data">
                                <b-button 
                                    id="button1" 
                                    variant="outline-primary" 
                                    size="sm" 
                                    v-b-tooltip.hover
                                    :data-original-title="data.value.businessid"
                                    :title="data.value.businessid" 
                                    @click.prevent="copyTooltip($event)">
                                    <i class="fa fa-2x fa-barcode"></i>
                                </b-button>
                            </template>
                            <template #cell(name)="row">
                                <a class=" cursor-pointer font-color font-weight-bold" @click="setBusiness(row)">{{ row.value }} <i class="fa fa-link text-theme"></i></a>
                                <!-- <router-link class="font-color font-weight-bold" to="/business/details">{{ row.value }} <i class="fa fa-link text-theme"></i></router-link> -->
                            </template>
                            <template #cell(action)="data">
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item @click="updateStatus(data.item.uuid,'approved')">Approved</b-dropdown-item>
                                    <b-dropdown-item @click="updateStatus(data.item.uuid,'rejected')">Rejected</b-dropdown-item>
                                    <b-dropdown-item @click="updateStatus(data.item.uuid,'freezed')">Freezed</b-dropdown-item>
                                    <b-dropdown-item @click="updateStatus(data.item.uuid,'suspended')">Suspended</b-dropdown-item>
                                    <b-dropdown-item @click="updateStatus(data.item.uuid,'dormant')">Dormant</b-dropdown-item>
                                    <b-dropdown-item @click="deleteItem(data.item.uuid)">Delete</b-dropdown-item>
                                    <b-dropdown-item @click="showLinkBusiness(data)">Link Business</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
        <b-modal id="link_business" title="Link Business" hide-footer no-stacking no-close-on-backdrop>
            <ul class="list-unstyled mb-0 payment-single-transfer">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(linkBusinessForm)" id="single-form">
                        <li class="mb-3">
                            <strong> Link with Business : <span>{{linkBusiness.selectedBusiness}}</span></strong>
                        </li>
                        <li class="mb-2">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search by MerchantID" aria-label="Search by MerchantID" aria-describedby="search-business" v-model="linkBusiness.searchText" v-on:keyup.enter="setValue">
                                <div class="input-group-append" @click="setValue()">
                                    <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                </div>
                            </div>
                            <span class="text-danger error-msg" v-if="apiErrorCode=='E0147-104'|| apiErrorCode== 'E0147-105' || apiErrorCode=='V0147-001' || apiErrorCode=='0075-000'">{{apiErrorMsg}}</span>
                        </li>
                        <li class="mb-1">
                            <p> Business Name : <span>{{linkBusiness.setBusinessName}}</span></p>
                            <p> Country Name : <span>{{linkBusiness.setCountry}}</span></p>
                            <p> Category Name : <span>{{linkBusiness.setCategory}}</span></p>
                            <!-- <p> Email : <span>{{linkBusiness.setEmail}}</span></p>
                            <p> Phone Number : <span>{{linkBusiness.setPhone}}</span></p> -->
                        </li>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" variant="primary btn-mw" size="lg">Link Business</b-button>
                            <b-button @click="$bvModal.hide('link_business')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </ul>
        </b-modal>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
// const AddBusinessModel = require('@/components/core/AddBusinessModel.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
import shared from "@/shared.js";
// const Multiselect = require('vue-multiselect').default
const { ValidationObserver } = require("vee-validate")

export default {
    name:'BusinessComponent',
    components: {
        BreadCrumb,
        'main-loader':LoaderComponent,
        // Multiselect,
        ValidationObserver,
        // ValidationProvider
        // 'business-model':AddBusinessModel
    },data (){
        return{
            //All Acounts list table json
            items:[],
           
            //All Acounts list table header fields
            fields: [
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'Business Name',sortable: true,thClass:'th_sortfix'},
                { key: 'countryname', label: 'Country Name',sortable: true,thClass:'th_sortfix'},
                { key: 'merchant_id', label: 'Merchant ID',sortable: true,thClass:'th_sortfix'},
                { key: 'dor', label: 'Date of Registration',sortable: true,thClass:'th_sortfix'},
                // { key: 'owner', label: 'Owner',sortable: true,thClass:'th_sortfix'},
                { key: 'ltd_to', label: 'LTD T/O',sortable: true,thClass:'th_sortfix'},
                { key: 'account_type', label: 'Account Type',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'Status',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Action'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All Acounts list table pagination options
            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            searchText: '',
            filterBy: '',
            filterValue: '',
            condition: '',

            breadCrumbIteams:[
                {
                    title:'Business',
                    links:''
                }
            ],

            linkBusiness: {
                selectedBusinessId:'',
                selectedBusiness:'',
                searchText:'',
                setSubBusinessId:'',
                setBusinessName:'',
                setCountry:'',
                setCategory:''
                // setEmail:'',
                // setPhone:''
            },
            apiErrorMsg : '',
            apiErrorCode: ''
        }
    },methods: {
        //check Single Transfer form Account Number field is empty
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageJump = e.target.value
                } else {
                   this.currentPage = this.currentPageJump = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageJump = 1;
            }
        },

        //Recent transactions table Details copy
        copyTooltip: function(e){
            var tooltipText = e.currentTarget.getAttribute('data-original-title');
            const inputCopyText = document.createElement('input')
            inputCopyText.value = tooltipText
            document.body.appendChild(inputCopyText)
            inputCopyText.select()
            document.execCommand('copy')
            document.body.removeChild(inputCopyText)
        },

        //generate random tooltip
        rendomNumber(){
            return Math.floor(Math.random()*90000) + 10000
        },
        async getBusinessList(ctx) {
           try{
                this.$refs.loader.show();
                let page  = '';
                let size = '';
                let sortby = '';
                let sort = false;
                if(ctx.currentPage != undefined) {
                    page =  ctx.currentPage;
                } else {
                    page = 1;
                }
                if(ctx.perPage != undefined) {
                    size = ctx.perPage;
                } else {
                    size = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'createdat'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sort = 'DESC'
                } else {
                    sort = 'ASC';
                }
                
                
                this.currentPageJump = page;
                this.items = [];
                let paramData = {
                    sorting : sort,
                    sortby : sortby,
                    pagesize : size,
                    pagenumber : page,
                    search : this.searchText,
                    // filterby : this.filterBy ? this.filterBy.value : '',
                    // filtervalue : this.filterValue,
                    // condition : this.condition ? this.condition.value : '=='
                }

                const response = await this.$http.get(this.$baseurl+"business/list",{
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                    },
                });
                // console.log(response);
                if(response.data.code == '0075-000'){
                    response.data.details.forEach(element => {
                        let statusObj = {};
                        // element.isapproved==0?
                        // (statusObj.name ='Inactive', statusObj.class= 'badge-danger')
                        // :(statusObj.name ='Active', statusObj.class= 'badge-success')

                        if(element.status == 0) {
                            statusObj.name ='Pending';
                            statusObj.class= 'badge-danger';
                        } else if(element.status == 1) {
                            statusObj.name ='Approved';
                            statusObj.class= 'badge-success';
                        } else if(element.status == 2) {
                            statusObj.name ='Rejected';
                            statusObj.class= 'badge-warning';
                        } else if (element.status == 3) {
                            statusObj.name ='Freezed';
                            statusObj.class= 'badge-danger';
                        } else if(element.status == 4) {
                            statusObj.name ='Suspended';
                            statusObj.class= 'badge-danger';
                        } else if(element.status == 5) {
                            statusObj.name ='Dormant';
                            statusObj.class= 'badge-danger';
                        } else {
                            statusObj.name ='Pending';
                            statusObj.class= 'badge-danger';
                        }
                        
                        let businessItem = {
                            uuid:{businessid:element.businessid},
                            name:element.name,
                            countryname:element.countryname,
                            merchant_id: element.merchandid,
                            dor:element.registrationDate==null?'-':element.registrationDate,
                            owner: '',
                            ltd_to:element.annualturnover==null?'-':element.annualturnover,
                            account_type:element.categoryname,
                            status:statusObj,
                            action:''
                        };
                        this.items.push(businessItem);
                    })
                    this.totalRows = response.data.query.total; 
                    this.$refs.loader.hide();
                    return this.items;

                }
            } catch(error) {
                this.$refs.loader.hide();
                return [];
            }
        },

        refreshBusinessTable() {
            this.$refs.businessTable.refresh();
        },

        resetBusinessValue(){
            this.filterBy= '',
            this.filterValue= '',
            this.condition= '',
            this.refreshBusinessTable();
        },

        setBusiness(row) {
            sessionStorage.setItem('businessIdFromBusinessList', row.item.uuid);
            sessionStorage.setItem('countryId', row.item.country);
            sessionStorage.setItem('businessName', row.item.name);
            this.$router.push({path:'/business/details'});
        },

        updateStatus(businessID, status){
            shared.updateConfirm().then((result) => {
                // console.log(businessID, status);
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    let dataJson = {
                        "status": status,
                        "isdeleted": false
                    }
                    // console.log(dataJson);
                    this.$http.put(this.$baseurl+"business/actions/"+businessID.businessid, dataJson,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    }).then((res) => {
                        // console.log(res);
                        if(res.data.code == '0102-000') {
                            shared.toastrSuccess(res.data.message);
                            this.$refs.loader.hide();
                            this.refreshBusinessTable();
                        } else {
                            this.$refs.loader.hide();
                            this.refreshBusinessTable();
                        }
                    }).catch((error) => {
                        //this.$swal('Error!', error.response.data.message, 'error', 'Close');
                        this.$refs.loader.hide();
                        this.refreshBusinessTable();
                        shared.toastrError(error.response.data.message);
                    });
                }
            })
        },

        deleteItem(businessID) {
            shared.deleteConfirm().then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    let dataJson = {
                        "isdeleted": true
                    }
                    // console.log(dataJson);
                    this.$http.put(this.$baseurl+"business/actions/"+businessID, dataJson,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    }).then((res) => {
                        // console.log(res);
                        if(res.data.code == '0102-000') {
                            shared.toastrSuccess(res.data.message);
                            this.$refs.loader.hide();
                            this.refreshBusinessTable();
                        } else {
                            this.$refs.loader.hide();
                            this.refreshBusinessTable();
                        }
                    }).catch((error) => {
                        //this.$swal('Error!', error.response.data.message, 'error', 'Close');
                        this.$refs.loader.hide();
                        this.refreshBusinessTable();
                        shared.toastrError(error.response.data.message);
                    });           
                }
            })
        },

        showLinkBusiness(row){
            // console.log(row.item);
            this.apiErrorCode = "";
            this.apiErrorMsg = "";
            this.linkBusiness.selectedBusinessId = row.item.uuid;
            this.linkBusiness.selectedBusiness = row.item.name;
            this.linkBusiness.setBusinessId = '';
            this.linkBusiness.setBusinessName = '';
            this.linkBusiness.setCountry = '';
            this.linkBusiness.setCategory = '';
            this.linkBusiness.searchText = '';
            this.$bvModal.show('link_business');
        },

        async setValue(){
            this.apiErrorCode = "";
            this.apiErrorMsg = "";
            let paramData = {
                sorting : 'ASC',
                sortby : 'createdat',
                pagesize : 10,
                pagenumber : 1,
                search : this.linkBusiness.searchText,
                searchfield : 'merchandid'
            }
            const response = await this.$http.get(this.$baseurl+"business/list",{
                params: paramData,
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") 
                },
            });
            // console.log(response);
            if(response.data.code == '0075-000'){
                if(response.data.query.total > 0){
                    this.apiErrorMsg = '';
                    this.apiErrorCode = '';
                    response.data.details.forEach(element => {
                        this.linkBusiness.setSubBusinessId=element.businessid,
                        this.linkBusiness.setBusinessName=element.name,
                        this.linkBusiness.setCountry=element.countryname,
                        this.linkBusiness.setCategory=element.categoryname
                        // this.linkBusiness.setEmail='test@mailinator.com',
                        // this.linkBusiness.setPhone='123456789'
                    })
                }else{
                    this.apiErrorCode = response.data.code;
                    this.apiErrorMsg = 'Provide valid merchant id';
                    this.linkBusiness.setSubBusinessId='';
                    this.linkBusiness.setBusinessName='';
                    this.linkBusiness.setCountry='';
                    this.linkBusiness.setCategory='';
                }
            }
        },

        linkBusinessForm(){
            let linkBusinessData = {
                businessid:this.linkBusiness.selectedBusinessId,
                subbusinessid:this.linkBusiness.setSubBusinessId
            }
            this.$http.post(this.$baseurl+"business/link/business", linkBusinessData,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                },
            }).then((res) => {
                // console.log(res);
                if(res.data.code == '0147-100') {
                    shared.toastrSuccess(res.data.message);
                    this.$bvModal.hide('link_business');
                    this.refreshBusinessTable();
                    this.apiErrorMsg = '';
                    this.apiErrorCode = '';
                } else {
                    this.apiErrorCode = res.data.code;
                    this.apiErrorMsg = res.data.message;
                }
            }).catch((error) => {
                this.apiErrorCode = error.response.data.code;
                if(error.response.data.detail){
                    this.apiErrorMsg = error.response.data.detail;
                }else {
                    this.apiErrorMsg = error.response.data.message;
                }
            });
        }
    },mounted() {
        if(sessionStorage.getItem("jwtToken")){
            this.getBusinessList();
            this.totalRows = this.items.length
        } else {
            this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            this.$router.push({path:'/standapp-login'});
        }
    },computed: {
        
    }
}

</script>
<style lang="sass">

</style>
