<template>
    <div class="right-content mx-auto my-auto">
        <ValidationObserver v-slot="{ passes }">
            <div class="header pb-3 text-center">
                <p class="lead px-3">Reset Password</p>
            </div>
            <div class="body">
                <form class="form-auth-small" @submit.prevent="passes(resetPasswordSubmit)" id="reset_password" autocomplete="off">
                    <ValidationProvider name="reset_email" rules="required|email" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !resetPasswordData.reset_email || errors[0],'success':resetPasswordData.reset_email && !errors[0]}">
                            <span class="float-label">
                                <input type="email" v-model="resetPasswordData.reset_email" class="form-control" name="email1" id="reset_email" placeholder="Email*" :disabled="resetPasswordData.readonlyMode">
                                <label for="reset_email" class="control-label">Email*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !resetPasswordData.reset_email || errors[0] "></i>
                                <i class="fa fa-check" v-if="resetPasswordData.reset_email && !errors[0]"></i>
                            </span>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="reset_passcode" rules="required|max:5" v-slot="{ errors }">
                        <div class="form-group" :class="{'errors': errors[0],'success':resetPasswordData.reset_passcode && !errors[0]}">
                            <span class="float-label">
                                <input type="text" class="form-control" id="reset_passcode" v-model="resetPasswordData.reset_passcode" placeholder="Passcode OTP*" v-validate="'length:5'">
                                <label for="reset_passcode" class="control-label">Passcode*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                <i class="fa fa-check" v-if="resetPasswordData.reset_passcode && !errors[0]"></i>
                            </span>
                        </div>
                    </ValidationProvider>
                    <b-button type="submit" block variant="primary" size="lg">continue</b-button>
                </form>
            </div>
        </ValidationObserver>
        <div class="otp-timer text-center" id="otp_timer">
            <span v-if="showResendOtp" class="mt-3 mb-0">
                <p>Code hasn't arrived? 
                    <a class="text-danger" href="javascript:void(0)" @click="resendOtp()">Resend OTP</a>
                </p>
                <!-- <p class="mt-3 mb-0"> 
                    You may be experiencing mobile network issues, check network settings on your phone
                </p> -->
            </span>
            <p>
                <span class="text-danger">{{ wrongOtpMsg }}</span>
            </p>
            <p class="mt-3 mb-0" v-if="!showResendOtp"> 
                You can request a new code if it does not arrive in: 
                <label class="timer-text text-danger" id="timer_countdown">02:00</label>
            </p>
            <!-- <a href="javascript:void(0);" @click="resendOtp()">Resend OTP</a>
            <p>
                <span class="text-danger">{{ wrongOtpMsg }}</span>
            </p>
            <p class="mt-3 mb-0"> 
                You can request a new code if it does not arrive in: 
                <label class="timer-text text-danger" id="timer_countdown"></label>
            </p> -->
        </div>
    </div>
</template>

<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
import { mapState } from 'vuex'
export default {
    name:'StandappResetPasswordComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        resetPasswordSubmit() {
            let resetPasswordData = {
                email: this.resetPasswordData.reset_email,
                passwordCode: this.resetPasswordData.reset_passcode
            }
            // console.log(resetPasswordData);
            this.$http.post(this.$baseurl+'business/auth/forgotPassword/validate', resetPasswordData)
            .then((res) => {
                //Perform Success Action
                // console.log(res);
                if(res.data.code == '0006-000') {
                    sessionStorage.setItem("authorizationToken", res.data.token);
                    let resMessage = res.data.message//'Your password is successfully updated'
                    this.$swal('Success!', resMessage, 'success', 'OK');
                    this.$router.push({ path: '/standapp-recover-password' })
                } else {
                    this.$swal('Error!', res.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });
        },

        //timer counter
        countDownTimer() {
            var duration = 2 * 60
            var timer = duration, minutes, seconds;
            var that = this;
            this.clearTimer = setInterval(function () {
                if(timer > 0) {
                    if(document.getElementById("timer_countdown")){
                        minutes = parseInt((timer / 60)%60, 10)
                        seconds = parseInt(timer % 60, 10);

                        minutes = minutes < 10 ? "0" + minutes : minutes;
                        seconds = seconds < 10 ? "0" + seconds : seconds;
                        document.getElementById("timer_countdown").innerHTML = minutes + ":" + seconds;
                    }
                    --timer;
                    // if(timer <= 0){
                    //     that.showResendOtp = true;
                    // } else {
                    //     that.showResendOtp = false;
                    // }
                } else {
                    document.getElementById("timer_countdown").innerHTML = "00:00";
                    clearInterval(this.clearTimer);
                    this.clearTimer = 0;
                    if(timer <= 0){
                        that.showResendOtp = true;
                    } else {
                        that.showResendOtp = false;
                    }
                }
            }, 1000);
        },
        
        //reset timer 
        resendOtp(){
            let resendOtpData = {
                email: this.resetPasswordEmail.userEmail, 
            }
            // console.log(resendOtpData);
            this.$http.post(this.$baseurl+'business/register/resend', resendOtpData)
            .then((res) => {
                //Perform Success Action
                this.$swal('Success!', res.data.message, 'success', 'OK');
                clearInterval(this.clearTimer);
                return this.countDownTimer();
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });
        },

        // showPassword(field_name) {
        //     if(field_name == 'type_1'){
        //         this.resetPasswordData.password_type = this.resetPasswordData.password_type === 'password' ? 'text' : 'password';
        //     }
        // },
    },data(){
        return{
            resetPasswordData: {
                readonlyMode : true,
                reset_email: '',
                reset_passcode:'',
                password_type: 'password',
            },
            showResendOtp:false
        }
    },computed: mapState({
        resetPasswordEmail: state => state.resetPassword.resetPasswordDetail
    }),created() {
        //OTP Timer
        clearInterval(this.clearTimer)
        this.countDownTimer()
        this.resetPasswordData.reset_email = this.resetPasswordEmail.userEmail;
    },beforeDestroy(){
        clearInterval(this.clearTimer)
    }
}
</script>