<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="body project_report">
                        <div class="table-responsive">
                            <table class="table m-b-0 table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Status</th>
                                        <th>Project</th>
                                        <th>Progress</th>
                                        <th>Team</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span class="badge badge-success">Active</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">InfiniO 4.1</a></h6>
                                            <small>Created 14.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="48" aria-valuemin="0" aria-valuemax="100" style="width: 48%;"></div>                                                
                                            </div>
                                            <small>Completion with: 48%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">
                                                <li><img src="../../../assets/xs/avatar1.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar2.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar3.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="badge badge-success">Active</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">Many desktop publishing packages and web</a></h6>
                                            <small>Created 18.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" style="width: 78%;"></div>                                                
                                            </div>
                                            <small>Completion with: 78%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">
                                                <li><img src="../../../assets/xs/avatar10.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar7.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar2.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar5.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="badge badge-default">InActive</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">iNext - One Page Responsive Template</a></h6>
                                            <small>Created 14.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="29" aria-valuemin="0" aria-valuemax="100" style="width: 29%;"></div>                                                
                                            </div>
                                            <small>Completion with: 29%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">                                                
                                                <li><img src="../../../assets/xs/avatar2.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar3.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="badge badge-success">Active</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">Oakleaf Admin - Bootstrap html5 with SASS</a></h6>
                                            <small>Created 18.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="13" aria-valuemin="0" aria-valuemax="100" style="width: 13%;"></div>                                                
                                            </div>
                                            <small>Completion with: 13%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">                                                
                                                <li><img src="../../../assets/xs/avatar7.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar1.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar3.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="badge badge-success">Active</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">InfiniO 4.1</a></h6>
                                            <small>Created 14.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="48" aria-valuemin="0" aria-valuemax="100" style="width: 48%;"></div>                                                
                                            </div>
                                            <small>Completion with: 48%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">
                                                <li><img src="../../../assets/xs/avatar1.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar2.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar3.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="badge badge-success">Active</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">Many desktop publishing packages and web</a></h6>
                                            <small>Created 18.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" style="width: 78%;"></div>                                                
                                            </div>
                                            <small>Completion with: 78%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">
                                                <li><img src="../../../assets/xs/avatar10.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar7.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar2.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar5.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="badge badge-warning">Pending</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">InfiniO 4.1</a></h6>
                                            <small>Created 14.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="48" aria-valuemin="0" aria-valuemax="100" style="width: 48%;"></div>                                                
                                            </div>
                                            <small>Completion with: 48%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">
                                                <li><img src="../../../assets/xs/avatar1.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar2.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar3.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="badge badge-primary">Closed</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">Massive Event - Conference and Event</a></h6>
                                            <small>Created 18.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>                                                
                                            </div>
                                            <small>Completion with: 100%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">
                                                <li><img src="../../../assets/xs/avatar10.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar7.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar2.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar5.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="badge badge-warning">Pending</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">Startup - OnePage Business Corporate Template</a></h6>
                                            <small>Created 14.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="56" aria-valuemin="0" aria-valuemax="100" style="width: 56%;"></div>                                                
                                            </div>
                                            <small>Completion with: 56%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">
                                                <li><img src="../../../assets/xs/avatar3.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar1.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar5.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar9.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="badge badge-success">Active</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">Falcon - Bootstrap Admin Template + UI Kit</a></h6>
                                            <small>Created 18.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="48" aria-valuemin="0" aria-valuemax="100" style="width: 48%;"></div>                                                
                                            </div>
                                            <small>Completion with: 48%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">
                                                <li><img src="../../../assets/xs/avatar4.jpg" alt="Avatar"></li>                                                
                                                <li><img src="../../../assets/xs/avatar1.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="badge badge-success">Active</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">Oreo Admin Bootstrap 4</a></h6>
                                            <small>Created 14.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="73" aria-valuemin="0" aria-valuemax="100" style="width: 73%;"></div>                                                
                                            </div>
                                            <small>Completion with: 73%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">
                                                <li><img src="../../../assets/xs/avatar5.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar3.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar8.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar7.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="badge badge-success">Active</span>
                                        </td>
                                        <td class="project-title">
                                            <h6><a href="javascript:void(0);">Blazing Saddles Angular 5</a></h6>
                                            <small>Created 18.Mar.2018</small>
                                        </td>
                                        <td>
                                            <div class="progress progress-xs">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="23" aria-valuemin="0" aria-valuemax="100" style="width: 23%;"></div>                                                
                                            </div>
                                            <small>Completion with: 23%</small>
                                        </td>
                                        <td>
                                            <ul class="list-unstyled team-info">
                                                <li><img src="../../../assets/xs/avatar1.jpg" alt="Avatar"></li>
                                                <li><img src="../../../assets/xs/avatar5.jpg" alt="Avatar"></li>
                                            </ul>
                                        </td>
                                        <td class="project-actions">
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary mr-1"><i class="icon-eye"></i></a>
                                            <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="icon-pencil"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
export default {
    name:'ProjectsListComponent',
    components: {
        BreadCrumb,
    }
}
</script>