<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-10 d-flex flex-column h-100 justify-content-between">
                        <div class="card">
                            <div class="header pb-1 text-center">
                                <p class="lead px-3">Finally, where do we send your receipts?</p>
                            </div>
                            <div class="body">
                                <p class="text-center mb-4">Provide an email you want us to save on your PayAngel account.</p>
                                <ValidationObserver v-slot="{ passes }">
                                    <form class="form-auth-small" @submit.prevent="passes(emailVarificationFormSubmit)" id="registration" ref="registration">
                                        <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !email || errors[0],'success':email && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="email" v-model="email" class="form-control" name="email1" id="email" placeholder="Email*">
                                                    <label for="email" class="control-label">Email*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !email || errors[0] "></i>
                                                    <i class="fa fa-check" v-if="email && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <b-button type="submit" block variant="gradiunt" size="lg">CONTINUE</b-button>
                                        <div class="bottom text-center mt-2">
                                            <router-link class="text-body text-decoration-underline" to="/dashboard-v1">I will do this later</router-link>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </div>
                        </div>
                        <div class="body text-center">
                            <img src="@/assets/home_image/Powered_by_StandApp.svg" class="banner-img-powered" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
export default {
    name:'EmailVarification',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        emailVarificationFormSubmit() {
            this.$router.push({ path: '/dashboard-v1' })
        }
    },data(){
        return{
            email:'',
        }
    }
}
</script>