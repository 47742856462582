<template>
    <div class="header pb-0">
        <div class="d-flex">
            <div class="title-icon pr-3">
                <img :src="img" alt="">
            </div>
            <div class="title-text m-b-20">
                <h5 class="text-uppercase mb-1">{{ title }}</h5>
                <p class="mb-0">{{ subTitle }}</p>
            </div>
        </div>
        <card-actions :actions = "dashboardActions" :isLight = true v-if="isActions"></card-actions>
        <span class="border-bottom d-block"></span>
    </div>
</template>
<script>
const CardActions = require('@/components/core/CardActions.vue').default
export default {
    name:'MoneyTransferHeader',
    props: {
        img: String,
        title:String,
        subTitle: String,
        isActions: Boolean,
    },components: {
        'card-actions': CardActions
    },data(){
        return{
            dashboardActions: [
                {
                    icon_class: "fa fa-send-o",
                    key: "Increase daily limit",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "Pay Bills/Fees",
                    url: "javascript:void(0);"
                },
            ]
        }
    }
}
</script>