<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-10">
                        <div class="card register-form-card">
                            <ValidationObserver v-slot="{ passes }">
                                <div class="header pb-1 text-center">
                                    <p class="lead px-3">Reset Password</p>
                                </div>
                                <div class="body">
                                    <form class="form-auth-small" @submit.prevent="passes(resetPasswordSubmit)" id="reset_password">
                                        <ValidationProvider name="reset_email" rules="required|email" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !resetPasswordData.reset_email || errors[0],'success':resetPasswordData.reset_email && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="email" v-model="resetPasswordData.reset_email" class="form-control" name="email1" id="reset_email" placeholder="Email*">
                                                    <label for="reset_email" class="control-label">Email*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !resetPasswordData.reset_email || errors[0] "></i>
                                                    <i class="fa fa-check" v-if="resetPasswordData.reset_email && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="reset_passcode" rules="required" v-slot="{ errors }">
                                            <div class="form-group" :class="{'errors': errors[0],'success':resetPasswordData.reset_passcode && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="text" class="form-control" id="reset_passcode" v-model="resetPasswordData.reset_passcode" placeholder="Passcode*">
                                                    <label for="reset_passcode" class="control-label">Passcode*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="resetPasswordData.reset_passcode && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <b-button type="submit" block variant="primary" size="lg">continue</b-button>
                                    </form>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="body text-center pb-4">
                            <img :src="welcome_messages.standApp_image" class="banner-img-powered" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
export default {
    name:'ResetPasswordComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        resetPasswordSubmit() {
            this.$router.push({ path: '/authentication/page-login-v2' })
        }
    },data(){
        return{
            welcome_messages:{
                standApp_image:  require(`@/assets/home_image/Powered_by_StandApp.svg`),
            },
            
            resetPasswordData: {
                reset_email: '',
                reset_passcode:'',
            },
        }
    },mounted () {
        this.onLoad();
    }
}
</script>
<style>

</style>