<template>
    <div class="card pricing2">
        <div class="body">
            <div class="pricing-plan">
                <img src="./../../assets/space-ship.png" alt="" class="pricing-img">
                <h2 class="pricing-header">Ultima</h2>
                <ul class="pricing-features">
                    <li>Responsive Design</li>
                    <li>Color Customization</li>
                    <li>HTML5 &amp; CSS3</li>
                    <li>Styled elements</li>
                </ul>
                <span class="pricing-price">$349</span>
                <a href="javascript:void(0);" class="btn btn-outline-primary">Join Now</a>
                </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Ultima',
}
</script>
