<template>
    <div class="container-fluid">
        <div class="block-header">
                <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-4 col-md-12">
                <feeds></feeds>
                <subscribe></subscribe>
                <followers></followers>
                <chat></chat>
                <design-team></design-team>
                <browser-stat></browser-stat>
                <stats-list></stats-list>
            </div>

            <div class="col-lg-4 col-md-12">
                <time-line></time-line>
                <list></list>
                <twitter-feed></twitter-feed>
                <ultima></ultima>
            </div>

            <div class="col-lg-4 col-md-12">
                <comments></comments>
                <to-do-list></to-do-list>
                <referrals></referrals>
                <pricing></pricing>
                <invoice></invoice>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const LucidFeeds = require('@/components/core/LucidFeeds.vue').default
const Timeline = require('@/components/core/Timeline.vue').default
const Comments = require('@/components/core/Comments.vue').default
const Subscribe = require('@/components/core/Subscribe.vue').default
const InboxList = require('@/components/core/InboxList.vue').default
const ToDoList = require('@/components/core/ToDoList.vue').default
const Followers = require('@/components/core/Followers.vue').default
const TwitterFeed = require('@/components/core/TwitterFeed.vue').default
const AnaliticalReferrals = require('@/components/core/AnaliticalReferrals.vue').default
const AnaliticalChat = require('@/components/core/AnaliticalChat.vue').default
const Ultima = require('@/components/core/Ultima.vue').default
const Pricing = require('@/components/core/Pricing.vue').default
const DesignTeam = require('@/components/core/DesignTeam.vue').default
const BrowserStat = require('@/components/core/BrowserStat.vue').default
const Invoice = require('@/components/core/Invoice.vue').default
const StatsList = require('@/components/core/StatsList.vue').default

export default {
    name: 'DataComponent',
    components: {
       BreadCrumb,
        'feeds': LucidFeeds,
        'time-line': Timeline,
        'comments': Comments,
        'subscribe': Subscribe,
        'list': InboxList,
        'to-do-list': ToDoList,
        'followers': Followers,
        'twitter-feed': TwitterFeed,
        'referrals': AnaliticalReferrals,
        'chat': AnaliticalChat,
        'ultima': Ultima,
        'pricing': Pricing,
        'design-team': DesignTeam,
        'browser-stat': BrowserStat,
        'invoice': Invoice,
        'stats-list': StatsList
    },methods: {
      
    }
}
</script>
