<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="banner-left">
                            <div class="left-top">
                                <div class="mobile-top">
                                    <h2 class="banner-heading">{{ welcome_messages.heading }}</h2>
                                    <h4 class="banner-title">{{ welcome_messages.sub_heading }}</h4>
                                    <div class="banner-list-container">
                                        <template v-if="show_banner">
                                            <vue-typed-js :strings="welcome_messages.banner_li" :loop="true" :typeSpeed="10">
                                                <ul class="list-unstyled banner-list typing"></ul>
                                            </vue-typed-js>
                                        </template>
                                    </div>
                                    <ul class="list-unstyled banner-list">
                                        <li v-for="(list,index) in welcome_messages.banner_lists" :key="index">
                                            <p class="mb-0 d-flex align-items-center"><img :src="list.image" alt=""><span v-html="list.innertext"></span></p>
                                        </li>
                                    </ul>
                                    <!-- <router-link class="btn banner-sign-up" to="/standapp-register">{{ welcome_messages.button_text }}</router-link> -->
                                </div>
                            </div>
                            <div class="left-bot">
                                <div class="d-flex justify-content-between align-items-end">
                                    <div class="partner-slider">
                                        <div class="our-partner-title">
                                            <h5>Our Partners</h5>
                                        </div>                               
                                        <ul class="d-flex flex-wrap our-patner list-unstyled">
                                            <li v-for="(partnerimg,index) in partnersList" :key="index">
                                                <div class="item">
                                                    <img :src="partnerimg" alt="partner" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img :src="welcome_messages.banner_image" class="banner-img" alt="">
        </div>
    </section>
</template>
<script>
const { VueTypedJs } = require('vue-typed-js');
export default {
    name:'StandappHomeComponent',
    components:{'vue-typed-js':VueTypedJs},
    methods: {
        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },3000)
        }
    },
    data(){
        return{
            show_banner: false,
            welcome_messages:{
                heading:'Welcome to ONE WORLD',
                sub_heading:'Manage all your business receipts and payments in one place',
                banner_li: [
                    '<li><p class="mb-0 d-flex align-items-center"><span><strong>One </strong> Account</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span><strong>Global </strong> Capability</p></li> <li><p class="mb-0 d-flex align-items-center"> <span><strong>Superior </strong> Technology</span></p></li>'
                ],
                button_text:'Sign up for free in 3 minutes',
                banner_image: require(`@/assets/standapp/banner-standapp.svg`),
            },

            partnersList:[
                require(`@/assets/partner/partner-1.png`),
                require(`@/assets/partner/partner-3.png`),
                require(`@/assets/partner/partner-5.png`),
                require(`@/assets/partner/partner-7.png`),
                // require(`@/assets/partner/partner-9.png`),
                // require(`@/assets/partner/partner-11.png`),
                require(`@/assets/partner/partner-13.png`)
            ]
        }
    },
    mounted () {
        this.onLoad();
        // console.log(process.env.VUE_APP_URL);
    }
}
</script>
