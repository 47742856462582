<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Partner</h2>
                    </div>
                    <div class="body pt-0">
                        <b-table 
                            ref="partnerTable"
                            responsive
                            hover 
                            table-class="js-basic-example table-custom mb-0 border"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(status)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked">
                                    <!--  @click="updateStatus($event, data)" -->
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
import shared from "@/shared.js";

export default {
    name:'PartnerComponent',
    components: {
        BreadCrumb,
        'main-loader':LoaderComponent
    },data(){
        return{
            isVisibleFilter: false,

            transactions_toprows:[
                {
                    icon:'fa-dollar',
                    text:'Amount',
                    price:'$22,500',
                    progress_class:'bg-danger',
                    progressbar_width:'87',
                    compared_text:'19% compared to last week'
                },
                {
                    icon:'fa-bar-chart-o',
                    text:'Count',
                    price:'$500',
                    progress_class:'bg-success',
                    progressbar_width:'28',
                    compared_text:'19% compared to last week'
                },
                {
                    icon:'fa-area-chart',
                    text:'Avg Anount',
                    price:'215',
                    progress_class:'bg-info',
                    progressbar_width:'41',
                    compared_text:'19% compared to last week'
                },
                {
                    icon:'fa-users',
                    text:'No. of Customers',
                    price:'21,215',
                    progress_class:'bg-warning',
                    progressbar_width:'75',
                    compared_text:'19% compared to last week'
                }
            ],

            dashboardActions: [
                {
                    icon_class: "fa fa-send-o",
                    key: "Export as PDF",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "Export as CSV",
                    url: "javascript:void(0);"
                },
            ],

            paymentActions: [
                {
                    icon_class: "fa fa-send-o",
                    key: "Single Payment",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "Bulk Upload",
                    url: "javascript:void(0);"
                },
            ],

            options: [
                { value: 'pending', text:'Pending' },
                { value: 'approved', text: 'Approved' },
                { value: 'rejected', text: 'Rejected' },
            ],

            items:[
                // {name:'Buy',price:'11900.12',action:''},
                // {name:'Sell',price:'11905.09',action:''},
                // {name:'Buy',price:'11901.85',action:''},
                // {name:'Buy',price:'11899.28',action:''},
            ],
           
            fields: [
                { key: 'name', label: 'Partner Name',sortable: true,thClass:'th_sortfix'},
                { key:'partnerType', label: 'Partner Type'},
                { key: 'status', label:'Status' },
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            filterOption: [
                { value: 'name', name:'Partner Name' },
                { value: 'status', name:'Status' }
            ],

            conditionList: [
                { value: '==', name:'equals to' },
                { value: '>=', name:'greater than or equals to' },
                { value: '>', name:'greater than' },
                { value: '<', name:'less than' },
                { value: '<=', name:'less than or equals to' },
                { value: 'like', name:'like' }
            ],

            
            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },methods: {
        onChange(event,index) {
          var classVar =''
            return this.items.map((item,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    item.dropdawn.class = classVar
                }
            });
        },

        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageJump = e.target.value
                } else {
                   this.currentPage = this.currentPageJump = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageJump = 1;
            }
        },

        async getPartnerData() {
            try {
                this.items = [];
                const response = await this.$http.get(this.$baseurl+"partner/list", { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.details.map((item, index) => {
                    let partnerLists = {
                        name:item.name,
                        partnerType:item.partnertype,     
                        status:{id:'status_'+index,isChecked:item.isactive},
                    };
                    this.items.push(partnerLists);
                }); 
            } catch (error) {
                console.log(error);
            }
        },

        refreshPartnerTable() {
            this.$refs.partnerTable.refresh();
        },

        resetFilterValue(){
            this.filterBy= '',
            this.filterValue= '',
            this.condition= '',
            this.refreshPartnerTable();
        },

        changeStatus(id, walletId, value){
            // console.log(walletId,value);
            this.$refs.loader.show();
            // console.log(event.target.checked, key, row.item.domestic.countryId);
            let dataJson = {
                walletid : walletId,
                status : value
            }
            // console.log(dataJson);
            this.$http.put(this.$baseurl+"transactions/funds/"+id, dataJson,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                },
            }).then((res) => {
                // console.log(res);
                if(res.data.code == '0101-000') {
                    this.refreshPartnerTable();
                    this.$refs.loader.hide();
                    shared.toastrSuccess(res.data.message);
                } else {
                    this.refreshPartnerTable();
                    this.$refs.loader.hide();
                }
            }).catch((error) => {
                //this.$swal('Error!', error.response.data.message, 'error', 'Close');
                this.refreshPartnerTable();
                this.$refs.loader.hide();
                shared.toastrError(error.response.data.message);
            });
        }
    },mounted() {
        if(sessionStorage.getItem("jwtToken")){
            // this.totalRows = this.items.length;
            this.getPartnerData();
        } else {
            shared.toastrError('Please login to continue');
            this.$router.push({path:'/standapp-login'});
        }
    }
}
</script>
