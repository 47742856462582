<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-12">
                <div class="card no-shadow">
                    <div class="header">
                        <h6 class="font-16 font-weight-normal m-0">Share You Rewards Code</h6>
                    </div>
                    <div class="body">
                        <div class="top-title">
                            <h5 class="text-midnight-blue font-weight-bold">Hello Jones,</h5>
                            <p>Start earning money now by sharing your Payangel Reward Code <span class="text-green font-weight-bold">AM101721</span> with your friends and family! When they use it for the first time they get a free transfer and you earn £5.00</p>
                        </div>
                        <div class="row clearfix row-deck">
                            <div class="col-lg-4 col-md-12">
                                <div class="card card-top-line mb-3 mb-lg-0">
                                    <div class="header">
                                        <h6 class="font-16 font-weight-normal m-0">Share your Rewards Code on Social Media and Earn</h6>
                                    </div>
                                    <div class="body">
                                        <ul class="list-unstyled mb-0 reward-social d-flex">
                                            <li class="fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
                                            <li class="tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
                                            <li class="gp"><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="card card-top-line mb-0">
                                    <div class="header">
                                        <h6 class="font-16 font-weight-normal m-0">Share Your Rewards Code with Friend and Earn</h6>
                                    </div>
                                    <div class="body">
                                        <ValidationObserver v-slot="{ passes }">
                                            <form  id="rewards" @submit.prevent="passes(addRewards)">
                                                <ul class="d-flex flex-column flex-sm-row list-unstyled mb-0 reward-email">
                                                    <li>   
                                                        <ValidationProvider name="Name" rules="required" v-slot="{ errors}">
                                                            <div class="form-group mb-0" :class="{'errors': errors.length && !rewards.Name || errors[0],'success':rewards.Name && !errors[0]}">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control form-control-lg" id="fn" placeholder="Name" v-model="rewards.Name">
                                                                    <label for="fn" class="control-label">Name</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !rewards.Name || errors[0] "></i>
                                                                    <i class="fa fa-check" v-if="rewards.Name && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </li>
                                                    <li class="mx-0 mx-sm-3 my-3 my-sm-0">
                                                        <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                                                            <div class="form-group mb-0" :class="{'errors': errors.length && !rewards.email || errors[0],'success':rewards.email && !errors[0]}">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control form-control-lg" id="email" placeholder="Email Address" v-model="rewards.email">
                                                                    <label for="email" class="control-label">Email Address</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !rewards.email || errors[0]"></i>
                                                                    <i class="fa fa-check" v-if="rewards.email && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </li>
                                                    <li>
                                                        <button class="btn btn-danger btn-lg">Share</button>
                                                    </li>
                                                </ul>
                                            </form>
                                        </ValidationObserver>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card no-shadow">
                    <div class="header">
                        <h6 class="font-16 font-weight-normal m-0">My Earnings Reports</h6>
                    </div>
                    <div class="body">
                        <div class="row clearfix row-deck">
                            <div class="col-lg-6 col-md-12">
                                <div class="card card-top-line">
                                    <div class="header">
                                        <h6 class="font-16 font-weight-normal m-0 text-uppercase">Earnings - Total (£0.00)</h6>
                                    </div>
                                    <div class="body">
                                        <chart :options="earningsLineChart" style="width: 100%;" class="payangel-rewards"></chart>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="card card-top-line">
                                    <div class="header">
                                        <h6 class="font-16 font-weight-normal m-0 text-uppercase">Conversions / My Referrals</h6>
                                    </div>
                                    <div class="body">
                                        <chart :options="conversionsLineChart" style="width: 100%;" class="payangel-rewards"></chart>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="card card-top-line mb-0">
                                    <div class="header">
                                        <h6 class="font-16 font-weight-normal m-0">Referrals Statement</h6>
                                    </div>
                                    <div class="body">
                                        <b-table 
                                            responsive
                                            table-class="mb-0 table-custom table-referrals"
                                            head-variant="light"
                                            :items="items" 
                                            :fields="fields"
                                            :sort-by.sync="sortBy"
                                            :sort-desc.sync="sortDesc"
                                            >
                                            <template #head()="{label,  field: { key }}">
                                                {{ label }}
                                                <template>
                                                    <i v-if="sortBy !== key" class=""></i>
                                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                                </template>  
                                            </template>
                                            <template #cell(info)="data">
                                                <div class="media-object flag-img mr-2">
                                                    <img :src="data.value.img" width="50" class="rounded-circle" alt="">
                                                    <span class="flag-icon fi-md fi-round" :class="data.value.countryFlag"></span>
                                                </div>
                                                <span class="user-name">{{ data.value.name }}</span>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
export default {
    name:'RewardsComponent',
    components:{
        BreadCrumb,
        ValidationObserver,
        ValidationProvider
    },data(){
        return{
            rewards:{
                name:'',
                email:''
            },
            sortBy: '',
            sortDesc: false,
            items:[
                {
                    info:{img:require(`@/assets/xs/avatar5.jpg`),countryFlag:'flag-icon-ca',name:'Jones'},
                    email:'jones@payangle.com',
                    referral:'#00212',
                    status:'Done',
                    total:'$ 5.00'
                },
                {
                    info:{img:require(`@/assets/xs/avatar3.jpg`),countryFlag:'flag-icon-ca',name:'Charlotte Jake'},
                    email:'charlotte@payangle.com',
                    referral:'#00212',
                    status:'Done',
                    total:'$ 10.00'
                },
                {
                    info:{img:require(`@/assets/xs/avatar2.jpg`),countryFlag:'flag-icon-ca',name:'Grayson Shams'},
                    email:'grayson@payangle.com',
                    referral:'#00212',
                    status:'Done',
                    total:'$ 15.00'
                },
                {
                    info:{img:require(`@/assets/xs/avatar1.jpg`),countryFlag:'flag-icon-ca',name:'Jacob Camly'},
                    email:'jacob@payangle.com',
                    referral:'#00212',
                    status:'Done',
                    total:'$ 2       5.00'
                }
            ],

            fields: [
                { key: 'info', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'email', label: 'Email',sortable: true,thClass:'th_sortfix'},
                { key: 'referral', label: 'Referral ID'},
                { key: 'status', label: 'Referral Status'},
                { key: 'total', label: 'Total Earnings'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            earningsLineChart:{
                color: ["#50e3c2"],
                grid: {
                    top: '7',
                    right: '0',
                    left: '35'
                },
                xAxis: {
                    data: ['January', 'February', 'March', 'April', 'May', 'June'],
                    axisTick: {
                        alignWithLabel: true,
                    },
                },
                yAxis: {
                    minInterval: 10,
                },
                tooltip : {
                    trigger: 'item',
                    formatter:function(params){
                        var param = params;
                        return param.marker+" "+"£"+param.value+".00";
                    }
                },
                series: [
                    {
                        data: [26,5,30,5,3,24],
                        type: 'line',
                        smooth: true,
                    }
                ]
            },

            conversionsLineChart:{
                color: ["#50e3c2","#444444"],
                grid: {
                    top: '7',
                    right: '0',
                    left: '35'
                },
                xAxis: {
                    data: ['January', 'February', 'March', 'April', 'May', 'June'],
                    axisTick: {
                        alignWithLabel: true,
                    },
                },
                yAxis: {
                    minInterval: 20,
                },
                legend: {
                    icon: "square",
                    data: ['CONVERSIONS', 'MY REFERRALS'],
                    y:'bottom',
                },
                tooltip : {
                    trigger: 'item',
                    formatter:function(params){
                        var param = params;
                        return param.marker+" "+param.value;
                    }
                },
                series: [
                    {
                        name:'CONVERSIONS',
                        data: [30, 40, 19, 30, 20, 20],
                        smooth: true,
                        type: 'line',
                    },
                    {   
                        name:'MY REFERRALS',
                        data: [40, 39, 25, 15, 41, 25],
                        smooth: true,
                        type: 'line'
                    }
                ]
            }
        }
    },methods:{
        addRewards(){
            console.log("submit");
        }
    }
}
</script>
<style>
.payangel-rewards{
    height: 200px !important;
}
</style>