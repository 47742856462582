<template>
    <div class="right-content mx-auto my-auto">
        <ValidationObserver v-slot="{ passes }">
            <div class="header pb-3 text-center">
                <p class="lead px-3">For security purposes, we recommend you to change your password now</p>
            </div>
            <div class="body">
                <form class="form-auth-small" @submit.prevent="passes(updatePassword)" id="registration" ref="registration" autocomplete="off">
                    
                    <ValidationProvider name="password" rules="required" v-slot="{ errors}">
                        <div class="form-group set-password" :class="{'errors': errors.length && !passwordformdata.current_password,'success':passwordformdata.current_password, 'mb-0':apiErrorCode == 'V0005-000'|| apiErrorCode == 'E0005-007' || apiErrorCode=='E0005-001'}">
                            <span class="float-label">
                                <input :type="passwordformdata.current_password_type" class="form-control" id="signin-password" v-model="passwordformdata.current_password" placeholder="Password*">
                                <label for="signin-password" class="control-label">Current Password*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !passwordformdata.current_password"></i>
                                <i class="fa fa-check" v-if="passwordformdata.current_password"></i>
                                <i class="fa fa-eye" :class="passwordformdata.current_password_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword('type_0')"></i>
                            </span>
                        </div>
                    </ValidationProvider> 
                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'V0005-000' || apiErrorCode == 'E0005-007' || apiErrorCode=='E0005-001'">{{apiErrorMsg}}</span> 





                    <ValidationProvider name="password"  rules="required" v-slot="{ errors }" vid="confirmation">
                        <div class="form-group set-password" :class="{'errors': (passwordformdata.passwords != '' && !errorsDisplay) || errors.length > 0,'success': passwordformdata.passwords && errorsDisplay}">
                            <span class="float-label">
                                <input :type="passwordformdata.password_type" @input="password_check" v-model="passwordformdata.passwords" class="form-control" name="password" id="signup-password" placeholder="Password*">
                                <label for="signup-password" class="control-label">New Password*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="(passwordformdata.passwords != ''  && !errorsDisplay) || errors.length > 0"></i>
                                <i class="fa fa-check" v-if="passwordformdata.passwords && errorsDisplay"></i>
                                <i class="fa fa-eye" :class="passwordformdata.password_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword('type_1')"></i>
                            </span>
                            <div class="password-validation" :class="{'d-none':passwordformdata.passwords && errorsDisplay}">
                                <p :class="{'error':passwordformdata.passwords.length < 8}"><i :class="passwordformdata.passwords.length > 7 ? 'fa fa-check' : 'fa fa-times'"></i> Longer than 7 characters</p>
                                <p :class="{'error':!has_uppercase}"><i :class="has_uppercase ? 'fa fa-check' : 'fa fa-times'"></i> Has a capital letter</p>
                                <p :class="{'error':!has_lowercase}"><i :class="has_lowercase ? 'fa fa-check' : 'fa fa-times'"></i> Has a lowercase letter</p>
                                <p :class="{'error':!has_number}"><i :class="has_number ? 'fa fa-check' : 'fa fa-times'"></i> Has a number</p>
                                <p :class="{'error':!has_special}"><i :class="has_special ? 'fa fa-check' : 'fa fa-times'"></i> Has a special character</p>
                            </div>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="confirm_password" rules="required|confirmed:confirmation" v-slot="{ errors}">
                        <div class="form-group set-password" :class="{'errors': errors.length && !passwordformdata.confirm_passwords || errors[0],'success':passwordformdata.confirm_passwords && !errors[0]}">
                            <span class="float-label">
                                <input :type="passwordformdata.confirm_type" v-model="passwordformdata.confirm_passwords" class="form-control" name="confirmation_password" id="confirm_password" placeholder="Confirm Password*">
                                <label for="confirm_password" class="control-label">Confirm Password*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !passwordformdata.confirm_passwords || errors[0] "></i>
                                <i class="fa fa-check" v-if="passwordformdata.confirm_passwords && !errors[0]"></i>
                                <i class="fa fa-eye" :class="passwordformdata.confirm_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword('type_2')"></i>
                            </span>
                        </div>
                    </ValidationProvider>
                    <b-button type="submit" block variant="primary" size="lg">UPDATE PASSWORD</b-button>
                </form>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
import { mapState } from 'vuex';
//import shared from "@/shared.js";
export default {
    name:'StandappSetPasswordComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        updatePassword() {
            if(this.passwordformdata.passwords.length > 7 && this.has_number && this.has_lowercase &&  this.has_uppercase && this.has_special){
                // alert(this.$baseurl);
                let passwordData = {
                    oldpassword: this.passwordformdata.current_password, 
                    newpassword: this.passwordformdata.passwords,
                    change_token: sessionStorage.getItem('jwtToken')
                }
                // console.log(registrationData);
                this.$http.put(this.$baseurl+"auth/changepwd", passwordData,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    }).then((res) => {
                        // console.log(res);
                    //Perform Success Action
                    if(res.data.code === '0000-000') {
                        //this.$swal('Success!', res.data.message, 'success', 'OK');
                        //shared.toastrSuccess(res.data.message);
                        sessionStorage.removeItem('jwtToken');
                        this.$router.push({ path: '/standapp-login' });
                    } else {
                        //this.$swal('Error!', res.data.message, 'error', 'Close');
                        //shared.toastrError(res.data.message);
                        //this.$router.push({path:'/standapp-register'});
                        this.apiErrorCode = res.data.code;
                        this.apiErrorMsg = res.data.message;
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    //console.log(error.response.data.code);
                    //this.$swal('Error!', error.response.data.message, 'error', 'Close');
                    // this.apiErrorCode = error.response.data.code;
                    // this.apiErrorMsg = error.response.data.message;
                    this.apiErrorCode = error.response.data.code;
                    if(error.response.data.detail == undefined) {
                        this.apiErrorMsg = error.response.data.message;
                    } else {
                        this.apiErrorMsg = error.response.data.detail;
                    }
                });
            }
        },

        showPassword(field_name) {
            if(field_name == 'type_1'){
                this.passwordformdata.password_type = this.passwordformdata.password_type === 'password' ? 'text' : 'password';
            }else if(field_name == 'type_2'){
                this.passwordformdata.confirm_type = this.passwordformdata.confirm_type === 'password' ? 'text' : 'password';
            } else if(field_name == 'type_0'){
                this.passwordformdata.current_password_type = this.passwordformdata.current_password_type === 'password' ? 'text' : 'password';
            }
        },

        password_check: function () {
            this.has_number    = /\d/.test(this.passwordformdata.passwords)
            this.has_lowercase = /[a-z]/.test(this.passwordformdata.passwords)
            this.has_uppercase = /[A-Z]/.test(this.passwordformdata.passwords)
            this.has_special   = /[^A-Za-z0-9]/.test(this.passwordformdata.passwords)
            if(this.passwordformdata.passwords.length > 7 && this.has_number && this.has_lowercase &&  this.has_uppercase && this.has_special){
                return this.errorsDisplay = true
            }else{
                return this.errorsDisplay = false
            }
        }
    },data(){
        return{
            errorsDisplay:false,
            passwordformdata: {
                robot: false,
                passwords: '',
                confirm_passwords:'',
                current_password: '',
                password_type: 'password',
                confirm_type: 'password',
                current_password_type: 'password',
            },
            
            has_number:    false,
            has_lowercase: false,
            has_uppercase: false,
            has_special:   false,
            apiErrorCode : '',
            apiErrorMessage : ''

        }
    }, computed: mapState({
        businessRegistrationDetail: state => state.registration.businessDetail,
        personalDetail: state => state.personalInfo  
    })
}
</script>