<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <b-modal id="add_fund_option" :title="modalTitle" hide-footer @shown="resetForm">
                <div class="modal-area">
                    <ValidationObserver v-slot="{ passes }">
                        <ul class="list-unstyled mb-0 payment-single-transfer">
                            <form id="funding_option" @submit.prevent="passes(addFundingOption)">
                                <li>  
                                    <ValidationProvider name="name" rules="required" v-slot="{ errors}">   
                                        <div class="form-group" :class="{'errors': errors.length && !addFundDatas.name || errors[0],'success':addFundDatas.name && !errors[0],'mb-0':apiErrorCode == 'E0065-003'} ">
                                            <span class="float-label">
                                                <input type="text" v-model="addFundDatas.name" class="form-control" name="name " id="name " placeholder="Name*" @input="checkNameFormat($event, 'name')">
                                                <label for="category_name " class="control-label">Add Payout Option Name*</label>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="errors.length && !addFundDatas.name || errors[0] "></i>
                                                <i class="fa fa-check" v-if="addFundDatas.name && !errors[0]"></i>
                                            </span>
                                            <span class="text-danger error-msg" v-if="fieldName == 'name'">{{fieldErrorMsg}}</span>
                                        </div>
                                    </ValidationProvider>
                                    <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0100-302'">{{apiErrorMsg}}</span> 

                                </li>
                                <li>
                                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                        <b-button v-if="payoutOptionId==''" type="submit" variant="primary btn-mw" size="lg">Add Payout Option </b-button>
                                        <b-button v-if="payoutOptionId!=''" type="submit" variant="primary btn-mw" size="lg">Update Payout Option </b-button>
                                        <b-button @click="$bvModal.hide('add_fund_option')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                    </div>
                                </li>
                               
                            </form>
                        </ul>
                    </ValidationObserver>
                </div>
            </b-modal>            
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Payout Option</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="d-flex align-items-center flex-column flex-md-row-reverse justify-content-center justify-content-md-start mb-3 payment-dropdown">
                            <b-button size="lg" variant="primary btn-mw" @click="createFundOption()">Add Payout Option <i class="fa fa-plus-square"></i></b-button>
                        </div>
                        <b-table 
                            responsive
                            hover 
                            table-class="js-basic-example table-custom mb-0 border"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(action)="row">
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item @click="showEdit(row)">Edit</b-dropdown-item>
                                    <b-dropdown-item  @click="deletePayoutOption(row.item.id)">Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <!-- <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
       
        <main-loader ref="loader"></main-loader>
    </div>
    
</template>

<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
//const AddPayoutOption = require('@/components/admin/payout_option/AddPayoutOptionModal.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
import shared from "@/shared.js";
const { ValidationObserver,ValidationProvider} = require('vee-validate')


export default {
    name:'PayoutOptionComponent',
    components: {
        BreadCrumb,
       // 'add-payout-option': AddPayoutOption,
        'main-loader':LoaderComponent,
        ValidationObserver,
        ValidationProvider,

    },data(){
        return{
            addFundDatas:{
                name:'',
            },
            // items:[
            //     {name:'Bank Deposit',action:''},
            //     {name:'Mobile Money',action:''},
            //     {name:'Bank Card',action:''}
            // ],
            items: [],

            fields: [
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Action'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            payoutOptionId: '',
            fieldName : '',
            fieldErrorMsg : '',
            apiErrorMsg : '',
            apiErrorCode: '',
            modalTitle: ''
        }
    },methods: {
        showEdit(element) {
            this.addFundDatas.name = element.item.name;
            this.payoutOptionId = element.item.id;
            this.$bvModal.show('add_fund_option');
        },

        deletePayoutOption(payoutOptionId) {
            shared.deleteConfirm().then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    this.$http.delete(this.$baseurl+"payout/options/name/"+payoutOptionId,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                        },
                    }).then((res) => {
                        if(res.data.code == '0100-300') {
                            shared.toastrSuccess('Payout Option Successfully deleted');
                            this.getPayoutListData();
                            this.$refs.loader.hide();
                        } else {
                            this.$refs.loader.hide();
                            shared.toastrError(res.data.message);
                        }
                    }).catch((error) => {
                        this.apiErrorCode = error.response.data.code;
                        if(this.apiErrorCode == "E0065-005") {
                            this.$refs.loader.hide();
                            shared.toastrError(error.response.data.message);
                            
                        }/* else if(this.apiErrorCode == "E0004-003") {
                            this.$store.dispatch('contactDetail/addContact', loginData);
                            this.$router.push({ path: '/standapp-otp' })
                        }*/ else {
                            this.apiErrorMessage = error.response.data.message;
                        }
                    });



                }
            })
        },        
        checkNameFormat(e, fieldName) {
            let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
            let charLength = e.target.value.length;
            var splitStr = e.target.value.split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                if(splitStr[0]!='')
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            }
            if(splitStr[0]=='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
            }

            this.addFundDatas[fieldName] = splitStr.join(' ');
            if(/^[A-Za-z'-\s]+$/.test(char)) {
                if(charLength >= 2 && charLength <= 35 && splitStr[0]!='') {
                    this.fieldErrorMsg = '';
                    this.fieldName = '';
                    return true; // Match with regex 
                } else {
                    this.fieldName = fieldName;
                    this.fieldErrorMsg = 'Please provide minimum two characters without spaces at start.';
                }
            } else {
                this.fieldName = fieldName;
                this.fieldErrorMsg = 'Please provide valid value for funding option name';
                e.preventDefault(); // If not match, don't add to input text
            }

        },        
        createFundOption(){
            this.payoutOptionId = '';
            this.$bvModal.show('add_fund_option');
        },
        addFundingOption(){
            if(this.payoutOptionId!='') {
                this.updateFundingOption();   
            } else {
                let categoryData = {
                    name: this.addFundDatas.name,
                   
                };
                
                this.$http.post(this.$baseurl+"payout/options/name", categoryData,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                    },
                }).then((res) => {
                    console.log(res);
                    if(res.data.code == '0100-300') {
                        shared.toastrSuccess('Funding Option Successfully Added');
                        this.getPayoutListData();
                        this.$bvModal.hide('add_fund_option');
                        this.apiErrorMsg = '';
                        this.apiErrorCode = '';
                    }
                }).catch((error) => {
                    this.apiErrorCode = error.response.data.code;
                    this.apiErrorMsg = error.response.data.message;
                });
            }
           
        },
        updateFundingOption() {
            let categoryData = {
                name: this.addFundDatas.name,
                isactive: true
                
            };
            this.$http.put(this.$baseurl+"payout/options/name/"+this.payoutOptionId, categoryData,{
                 headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                },
            }).then((res) => {
                if(res.data.code == '0100-300') {
                    //this.$swal('Success!', res.data.message, 'success', 'Close');
                    shared.toastrSuccess(res.data.message);
                    this.getPayoutListData();
                    this.$bvModal.hide('add_fund_option');
                    
                }
            }).catch((error) => {
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMsg = error.response.data.message;
            });             
        },

        resetForm(){
            if(this.payoutOptionId== '') {
                this.addFundDatas.name = '';
                this.fieldErrorMsg = '';
                this.apiErrorMsg = '';
                this.apiErrorCode = '';
                this.fieldName = '';
                this.modalTitle = 'Add Payout Option';
                
            } else {
                this.fieldErrorMsg = '';
                this.apiErrorMsg = '';
                this.apiErrorCode = '';
                this.fieldName = '';
                this.modalTitle = 'Edit Payout Option';

            }
        },        
        async getPayoutListData() {
            try {
                    this.items = [];
                       const response = await this.$http.get(this.$baseurl+"payout/options/name",{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") 
                        },
                    });
                    if(response.data.code === '0100-300') {
                        if(response.data.details.length > 0) {
                            response.data.details.forEach(element => {
                                let complianceItem = {
                                    id:element.id,
                                    name:element.name,
                                    // categoryId:element.categoryid,
                                    // typeId: element.typeid,
                                    // typeName: element.typename,
                                    // types:{selectedCategoryName: element.name,selectedCategoryId: element.categoryid,selectedTypeId: element.typeid,selectedTypeName:element.typename,typesOption:this.typeOptions},
                                    // action: ''
                                };
                                this.items.push(complianceItem);
                            })
                        }
                    }
            }catch(error) {
                console.log(error);
            }

        }
       
    }, mounted() {
        if(sessionStorage.getItem("jwtToken")){
            // this.totalRows = this.items.length;
            // this.getFundData();
            this.getPayoutListData();
        } else {
            shared.toastrError('Please login to continue');
            this.$router.push({path:'/standapp-login'});
        }
    }
}
</script>