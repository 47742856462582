<template>
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="card card-top-line no-shadow">
                        <div class="body">
                            <div class="mb-3">
                                <h6>Add Invoice Information</h6>
                            </div>
                            <ValidationObserver v-slot="{ passes }">
                                <form @submit.prevent="passes(invoiceDetailsForm)" id="invoice-info">
                                    <ul class="list-unstyled mb-0 common-form small-form">
                                        <li>
                                            <div class="form-group">
                                                <span class="float-label">
                                                    <input type="text" v-model="invoiceInfo.invoiceNumber" class="form-control" id="invoiceNumber" placeholder="Invoice Number" :disabled="true">
                                                    <label for="invoiceNumber" class="control-label">Invoice Number</label>
                                                    <div class="line"></div>
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <ValidationProvider name="currency" rules="required" v-slot="{ errors}">
                                                <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !invoiceInfo.currency,'success':invoiceInfo.currency}">
                                                    <span class="float-label">
                                                        <label for="functions">Invoice Currency*</label>
                                                        <multiselect
                                                            placeholder=""
                                                            v-model="invoiceInfo.currency"  
                                                            :show-labels="false" 
                                                            :options="currencyOptions" 
                                                            :searchable="false"
                                                            label="name" 
                                                            track-by="name" >
                                                            <template slot="option" slot-scope="props">
                                                                <div class="option-title">
                                                                    <span class="mr-2" :class="props.option.flagClass"></span>
                                                                    <span>{{ props.option.name }}</span>
                                                                </div>
                                                            </template>
                                                        </multiselect> 
                                                        <i class="fa fa-times" v-if="errors.length && !invoiceInfo.currency"></i>
                                                        <i class="fa fa-check" v-if="invoiceInfo.currency"></i>
                                                    </span>
                                                </div>
                                            </ValidationProvider>
                                        </li>
                                        <li>
                                            <ValidationProvider name="date" rules="required" v-slot="{ errors }">
                                                <div class="form-group datepicker" :class="{'errors': errors[0],'success':invoiceInfo.date && !errors[0]}">
                                                    <span class="float-label">
                                                        <b-input-group>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">Invoice Date*</span>
                                                                <span class="input-group-text" @click="$refs.datepicker.showCalendar()"><i class="icon-calendar"></i></span>
                                                            </div>
                                                            <datepicker class="form-control" :open-date="invoiceInfo.defaultDateSet"  ref="datepicker" :bootstrap-styling="false" placeholder="" v-model="invoiceInfo.date" :disabled="invoiceInfo.readonlyMode" :typeable="true"></datepicker>
                                                        </b-input-group>
                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                        <i class="fa fa-check" v-if="invoiceInfo.date && !errors[0]"></i>
                                                    </span>
                                                </div>
                                            </ValidationProvider>
                                        </li>
                                        <li>
                                            <ValidationProvider name="dueDate" rules="required" v-slot="{ errors }">
                                                <div class="form-group datepicker" :class="{'errors': errors[0],'success':invoiceInfo.startDate && !errors[0]}">
                                                    <span class="float-label">
                                                        <b-input-group>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">Due Date*</span>
                                                                <span class="input-group-text" @click="$refs.dueDate.showCalendar()"><i class="icon-calendar"></i></span>
                                                            </div>
                                                            <datepicker class="form-control" ref="dueDate" :bootstrap-styling="false" placeholder="Due date*" v-model="invoiceInfo.dueDate"></datepicker>
                                                        </b-input-group>
                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                        <i class="fa fa-check" v-if="invoiceInfo.dueDate && !errors[0]"></i>
                                                    </span>
                                                </div>
                                            </ValidationProvider>
                                        </li>
                                        <li>
                                            <div class="form-group" >
                                                <span class="float-label">
                                                    <b-input-group append="%">
                                                        <b-form-input type="text" v-model="invoiceInfo.tax" id="tax" data-index="index" placeholder="Tax" @keypress="onlyNumber($event)"></b-form-input>
                                                    </b-input-group>
                                                    <label for="tax" class="control-label">Tax</label>
                                                    <div class="line"></div>
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-group" >
                                                <span class="float-label">
                                                    <b-input-group append="%">
                                                        <b-form-input type="text" v-model="invoiceInfo.vat" id="vat" data-index="index" placeholder="Vat" @keypress="onlyNumber($event)"></b-form-input>
                                                    </b-input-group>
                                                    <label for="vat" class="control-label">VAT</label>
                                                    <div class="line"></div>
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <b-input-group>
                                                <input type="text" class="form-control" name="discount" id="discount" placeholder="Discount" @keypress="onlyNumber($event)">
                                                <b-input-group-append>
                                                    <b-dropdown variant="outline-secondary" menu-class="border-0 shadow" right>
                                                        <template #button-content>{{ invoiceInfo.discount }}</template>
                                                        <b-dropdown-item v-for="(optionInfo,index) in discountOptions" :key="index"  @click="activeDiscountOptions(index)" :active="index == invoiceInfo.discountActiveIndex">
                                                            {{ optionInfo.name }}
                                                        </b-dropdown-item>
                                                    </b-dropdown> 
                                                </b-input-group-append>
                                            </b-input-group>   
                                        </li>
                                        <li>
                                            <div class="d-flex flex-column flex-md-row justify-content-center mt-5">
                                                <router-link  to="/invoice/list" class="btn btn-secondary btn-mw btn-lg">Cancel</router-link>
                                                <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Continue</b-button>
                                            </div>
                                        </li>
                                    </ul>
                                </form> 
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const Multiselect = require('vue-multiselect').default
const Datepicker = require('vuejs-datepicker').default

export default {
    name:'AddInvoiceInfoComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        Datepicker,
    },data(){
        return{
            invoiceInfo:{
                invoiceNumber:'608978',
                curancy:null,
                date:null,
                dueDate:null,
                tax:'',
                vat:'',
                discount:'%',
                discountActiveIndex: 0
            },

            currencyOptions: [
                { name: 'Ghanaian Cedi', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"},
                { name: 'British Pound' , flagClass:"flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'Indian Rupee', flagClass: "flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'CFP Franc', flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States Dollar', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
                { name: 'Deutsche Mark',flagClass: "flag-icon flag-icon-de fi-xl fi-round"},
                { name: 'Euro', flagClass: "flag-icon flag-icon-eu fi-xl fi-round"},
                { name: 'Russian Ruble', flagClass:"flag-icon flag-icon-ru fi-xl fi-round"},
            ],

            discountOptions:[
                {
                    name:'%'
                },
                {
                    name:'Amt'
                }
            ]
        }
    },methods:{
        invoiceDetailsForm(){
            this.$router.push({path:'/invoice/add-invoice-item', query:{ type: this.$route.query.type }});
        },

        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },

        activeDiscountOptions(menuIndex){
            this.invoiceInfo.discountActiveIndex = menuIndex;
            this.invoiceInfo.discount = this.discountOptions[menuIndex].name;
        },
    }
}
</script>