<template>
    <div id="ip-container" class="ip-container">
        <div class="ip-main">

            <!-- Dynamic Color -->
            <div class="setting-btn">
                <button class="btn btn-primary btn-sm" v-b-modal.color_picker><i class="fa fa-gear"></i></button>
            </div>
            <!-- Settings: Theme dynamics -->
            <b-modal id="color_picker" tabindex="-1" title="PayAngel color Setting (for demo purpose)" centered hide-footer modal-class="customer-home-modal setting-modal">
                <div class="modal-body"> 
                    <div class="dt-setting">
                        <ul class="list-group list-unstyled">
                            <li class="list-group-item d-flex justify-content-between align-items-center py-2 px-2">
                                <label>Primary Color</label>
                                <sketch-picker :value="primaryColorCode" @input="updatePrimaryColor" :presetColors="[]" :disableAlpha="true" :disableFields="true" class="btn btn-sm"></sketch-picker>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center py-2 px-2">
                                <label>Secondary Color</label>
                                <sketch-picker :value="secondaryColorCode" @input="updateSecondaryColor" :presetColors="[]" :disableAlpha="true" :disableFields="true" class="btn btn-sm"></sketch-picker>
                            </li>
                        </ul>
                    </div>
                </div>
            </b-modal>
            <!-- Banner -->
            <section class="banner-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5 col-12">
                            <div class="banner-text">
                                <h2>Simply the best money transfer and payments service for Africans</h2>
                                <div class="banner-ul">
                                   <template v-if="show_banner">
                                        <vue-typed-js :strings="banner_li" :loop="true" :typeSpeed="10">
                                            <ul class="typing"></ul>
                                        </vue-typed-js>
                                   </template>
                                </div>
                                <div class="banner-form text-center">
                                    <h3>Scan the QR Code to download the app<img class="img-fluid" src="@/assets/img/apple-icon.png" alt="android"><img class="img-fluid" src="@/assets/img/android-icon.png" alt="apple"></h3>
                                    <img class="mx-auto" src="@/assets/img/qr-code.svg" width="100" alt="">
                                </div>
                                <div class="safe-text">
                                    <h4>Safe and Secure</h4>
                                    <ul class="d-flex flex-wrap">
                                        <li><img class="img-fluid" src="@/assets/img/card-1.svg" alt="card" /></li>
                                        <li><img class="img-fluid" src="@/assets/img/card-2.svg" alt="card" /></li>
                                        <li><img class="img-fluid" src="@/assets/img/card-3.svg" alt="card" /></li>
                                        <li><img class="img-fluid" src="@/assets/img/card-4.svg" alt="card" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-12">
                            <div class="banner-img move-img">
                                <img src="@/assets/img/Mask-Group-3.png" class="img-fluid" alt="" id="move_img" v-on:mousemove="moveImgParallax">
                            </div>
                            <div class="ios-android ios-android-2 desktop-lg-on text-center banner-form">
                                <h3>Scan the QR Code to download the app<img class="img-fluid" src="@/assets/img/apple-icon.png" alt="android"><img class="img-fluid" src="@/assets/img/android-icon.png" alt="apple"></h3>
                                <img src="@/assets/img/qr-code.svg" width="100" alt="">
                            </div>
                            <div class="safe-text safe-text-2 desktop-lg-on">
                                <h4>Safe and Secure</h4>
                                <ul class="d-flex flex-wrap">
                                    <li><img class="img-fluid" src="@/assets/img/card-1.svg" alt="card" /></li>
                                    <li><img class="img-fluid" src="@/assets/img/card-2.svg" alt="card" /></li>
                                    <li><img class="img-fluid" src="@/assets/img/card-3.svg" alt="card" /></li>
                                    <li><img class="img-fluid" src="@/assets/img/card-4.svg" alt="card" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Send Money -->
            <section class="send-money">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-12">
                            <div class="business-text">
                                <div class="desktop-md-hide">
                                    <div class="vert-move">
                                        <div class="cd-product-mockup cd-product-mockup-shadow">
                                            <img class="img-fluid" src="@/assets/img/Send-money-1.png" alt="Preview image" />
                                        </div>
                                    </div>
                                    <div class="vert-move1">
                                        <div class="cd-product-mockup cd-product-mockup1">
                                            <img class="img-fluid" src="@/assets/img/Send-money-2.png" alt="Preview image" />
                                        </div>
                                    </div>
                                    <div class="vert-move3">
                                        <div class="cd-product-mockup">
                                            <img class="img-fluid" src="@/assets/img/Send-money-3.png" alt="Preview image" />
                                        </div>
                                    </div>
                                </div>
                                <div class="desktop-lg-on"><img class="img-fluid" src="@/assets/img/Send-money.png"></div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-12">
                            <div class="money-text">
                                <div class="title-section">
                                    <h3 class="title">Send money from your mobile 24/7</h3>
                                    <p>Download the PayAngel app and send money to family and friends on the go.</p>
                                </div>
                                <div class="button-section">
                                    <a href="#" class="btn btn-gradiunt w-100">Download</a>
                                </div>
                                <div class="ios-android">
                                    <ul class="d-flex flex-wrap">
                                        <li><a href="#"><img class="ios" src="@/assets/img/apple-btn.png" /></a></li>
                                        <li><a href="#"><img class="android" src="@/assets/img/google-play-btn.png" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Paid Friend -->
            <section class="paid-friend bg-gradiunt">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6 col-12">
                            <div class="paid-text">
                                <div class="title-section">
                                    <h3 class="title text-white">Get paid when your friends & family send money</h3>
                                </div>
                                <div class="button-section">
                                    <a href="#" class="btn btn-white-border">JOIN & EARN NOW</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="paid-img move-img">
                                <img class="img-fluid" src="@/assets/img/get-paid-image.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Why PayAngel -->
            <section class="why-payangel">
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-md-10 col-12">
                            <div class="title-section text-center">
                                <h3 class="title" >Why choose PayAngel</h3>
                                <p>We are not just another money transfer service for Africans.<br /> PayAngel is designed by Africans specifically for Africans with a focus on our peculiar needs. So why choose us?</p>
                            </div>
                            <div class="wrap">
                                <div class="row align-items-center">
                                    <div class="col-sm-12 col-lg-6 col-12 d-flex flex-wrap justify-content-center">
                                        <div class="why-round">
                                            <div class="why-round-img">
                                                <img src="@/assets/img/Group-275.svg" alt="Group">
                                            </div>
                                            <div id="showcase" class="noselect imground">
                                                <cloudCarousel ref="whyChoosePayangelCourosel" :options="options">
                                                    <cloud-carousel-item class="cloud9-item" data-title="To be Safe and Secure" data-description="We understand that you worked hard for your money and will want ">
                                                        <img src="@/assets/img/why-icon-1.svg" alt="icon" />
                                                        <img class="why-active" src="@/assets/img/why-icon-active-1.svg" alt="icon" />
                                                    </cloud-carousel-item>
                                                    <cloud-carousel-item class="cloud9-item" data-title="We offer the Friendliest Customer Service" data-description="Do you have questions or need help with anything including transaction updates? Not a problem. You can reach our friendly customer service team directly on mobile and WhatsApp. You are guaranteed a personal
                                                        and warm service. ">
                                                        <img src="@/assets/img/why-icon-2.svg" alt="icon" />
                                                        <img class="why-active" src="@/assets/img/why-icon-active-2.svg" alt="icon" />
                                                    </cloud-carousel-item>
                                                    <cloud-carousel-item class="cloud9-item" data-title="It is Fast and Reliable" data-description="Over 95% of our transfers are received by the recipient within minutes. We work hard to make sure we give you options that allow you to the your money across to the end point fast and safely when it’s needed.">
                                                        <img src="@/assets/img/why-icon-3.svg" alt="icon" />
                                                        <img class="why-active" src="@/assets/img/why-icon-active-3.svg" alt="icon" />
                                                    </cloud-carousel-item>
                                                    <cloud-carousel-item class="cloud9-item" data-title="Our service is Easy, Simple and Convenient" data-description="The days of going to the local store rain or shine are over. PayAngel allows you to send money from the comfort of your home, 24/7. You get to save your details too so next time it’s a breeze.">
                                                        <img src="@/assets/img/why-icon-4.svg" alt="icon" />
                                                        <img class="why-active" src="@/assets/img/why-icon-active-4.svg" alt="icon" />
                                                    </cloud-carousel-item>
                                                    <cloud-carousel-item class="cloud9-item" data-title="The service is Designed with you in mind" data-description="You don't go to the store and choose any service; you pick one that solves all your problems and serves you. Why do it any different with your money transfer?">
                                                        <img src="@/assets/img/why-icon-5.svg" alt="icon" />
                                                        <img class="why-active" src="@/assets/img/why-icon-active-5.svg" alt="icon" />
                                                    </cloud-carousel-item>
                                                    <cloud-carousel-item class="cloud9-item" data-title="We are committed to Saving you money" data-description="We do not just pay lip service to saving you money and deceive you with marketing gimmicks. PayAngel was designed to helps Africans fight back and help us save.">
                                                        <img src="@/assets/img/why-icon-6.svg" alt="icon" />
                                                        <img class="why-active" src="@/assets/img/why-icon-active-6.svg" alt="icon" />
                                                    </cloud-carousel-item>
                                                </cloudCarousel>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6 col-12">
                                        <div class="round-arrow">
                                            <div id="nav" class="noselect">
                                                <div class="tab-pane fade show active" id="nav-0" role="tabpanel" aria-labelledby="nav-home-tab" style="color: black;">
                                                    <h3 id="item-title">{{ whyChoosePayangelTitle }}</h3>
                                                    <p id="item-text">{{ whyChoosePayangelDesc }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- How Work -->
            <section class="how-work bg-gray">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <div class="title-section text-center">
                                <h3 class="title">How it works</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="work-box text-center">
                                <img class="img-fluid" src="@/assets/img/how-work-1.png" />
                                <h3>1. Register for free.</h3>
                                <p>Sign up online or in our app for free. All you need is an email address, or a Google or Facebook account.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="work-box text-center">
                                <img class="img-fluid" src="@/assets/img/how-work-2.png" />
                                <h3>2. Add recipient’s details.</h3>
                                <p>Fill in the details of your recipient’s bank account. If you don’t know their details, we can request them for you.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="work-box text-center">
                                <img class="img-fluid" src="@/assets/img/how-work-3.png" />
                                <h3>3. Enter an amount to send.</h3>
                                <p>Tell us how much you want to send. We’ll show you our fees upfront, and tell you when your money should arrive.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="work-box text-center">
                                <img class="img-fluid" src="@/assets/img/how-work-4.png" />
                                <h3>4. Verify your identity.</h3>
                                <p>For some currencies, or for large transfers, we need a photo of your ID. This helps us keep your money safe.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="work-box text-center">
                                <img class="img-fluid" src="@/assets/img/how-work-5.png" />
                                <h3>5. Pay for your transfer.</h3>
                                <p>Send your money with a bank transfer, or a debit or credit card.</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="work-box text-center">
                                <img class="img-fluid" src="@/assets/img/how-work-6.png" />
                                <h3>6. That’s it.</h3>
                                <p>We’ll handle the rest. You can track your transfer in your account, and we'll tell your recipient it's coming.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Videopay -->
            <section class="videopay desktop-lg-hide">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <video width="100%" height="100%" src="@/assets/img/how-it-works-2.mp4" anima-src="https://anima-uploads.s3.amazonaws.com/projects/6044e585c82ef0bdbf43daa2/files/how-it-works-2.mp4" loop=""
                                autoplay="autoplay" playsinline="" muted=""></video>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Bundle of Choices -->
            <section class="bundle">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="bundle-box bg-gradiunt-2">
                            <div class="title-section text-center">
                                <h3 class="title text-white">Bundle of Choices</h3>
                                <p class="text-white">We don’t impose on you; we offer you choices so you can choose what works best for you. Be the boss and take control of your money. What’s more, you don’t need a middleman you can pay for goods and services directly and get your monies worth (no middleman tax).</p>
                            </div>
                        </div>
                        <div class="bundle-ul">
                            <ul class="d-flex flex-wrap">
                                <li>
                                    <div class="bundle-list">
                                        <h4>Choose how you send</h4>
                                        <ul>
                                            <li>Mobile Phone</li>
                                            <li>Website</li>
                                            <li>Telephone Service</li>
                                            <li>WhatsApp Chat</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div class="bundle-list">
                                        <h4>Choose how you pay</h4>
                                        <ul>
                                            <li>Debit Card</li>
                                            <li>Credit Card</li>
                                            <li>Faster Payment</li>
                                            <li>SEPA Instant Pay</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div class="bundle-list">
                                        <h4>Choose how the recipient receives</h4>
                                        <ul>
                                            <li>Mobile Money Wallet</li>
                                            <li>Bank Deposit</li>
                                            <li>Cash Collection</li>
                                            <li>Airtime Top-up</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Testimonial -->
            <section class="testimonial">
                <div class="container">
                    <div class="row t-p-text">
                        <div class="col-lg-8 col-12">
                            <div class="title-section">
                                <h3 class="title">What our customers<br> are saying:</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="testimonial-section testimonial-div">
                            <div class="testi-user-img">
                                <div class="swiper-container gallery-thumbs">
                                    <div class="swiper-wrapper">
                                        <carousel-3d :autoplay="true" @before-slide-change="onBeforeSlideChange"> 
                                            <slide v-for="(slide, i) in slides" :key="i" :index="i">
                                                <img :src="require(`@/assets/img/user.png`)">
                                            </slide>
                                        </carousel-3d>
                                    </div>
                                </div>
                            </div>
                            <div class="user-saying">
                                <!-- Slides -->
                                <VueSlickCarousel ref="customerCommentCourosel" v-bind="testimonialSettings">
                                    <div class="swiper-slide">
                                        <div class="quote">
                                            <img class="quote-icon" src="@/assets/img/quotes.png" alt="">
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd. “</p>
                                            <h4 class="name">Name Surname</h4>
                                            <span class="designation">CEO of Lorem Ipsum Ltd</span>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="quote">
                                            <img class="quote-icon" src="@/assets/img/quotes.png" alt="">
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd. “</p>
                                            <h4 class="name">Name Surname</h4>
                                            <span class="designation">CEO of Lorem Ipsum Ltd</span>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="quote">
                                            <img class="quote-icon" src="@/assets/img/quotes.png" alt="">
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd. “</p>
                                            <h4 class="name">Name Surname</h4>
                                            <span class="designation">CEO of Lorem Ipsum Ltd</span>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="quote">
                                            <img class="quote-icon" src="@/assets/img/quotes.png" alt="">
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd. “</p>
                                            <h4 class="name">Name Surname</h4>
                                            <span class="designation">CEO of Lorem Ipsum Ltd</span>
                                        </div>
                                    </div>
                                </VueSlickCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Partner With -->
            <section class="partner-with">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-12">
                            <div class="partner-img">
                                <img class="img-fluid title" src="@/assets/img/business-meet.svg" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="partner-element">
                                <div class="title-section">
                                    <h3 class="title">Partner With Us</h3>
                                    <p>Contact us today to see how we can help your business receive money and payments from abroad</p>
                                </div>
                                <ValidationObserver v-slot="{ passes }">
                                    <form @submit.prevent="passes(callbackCaptcha)" id="contact_form">
                                        <div class="row">
                                            <div class="col-12">
                                                <ValidationProvider ref="businessName" name="businesst_name" rules="required" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !contactFormFields.businesst_name,'success':contactFormFields.businesst_name}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="contactFormFields.businesst_name" class="form-control" id="businesst_name" placeholder="Business name*">
                                                            <label for="businesst_name" class="control-label">Business name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !contactFormFields.businesst_name"></i>
                                                            <i class="fa fa-check" v-if="contactFormFields.businesst_name"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12">
                                                <ValidationProvider ref="contactEmail" name="contact_email" rules="required|email" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !contactFormFields.contact_email || errors[0],'success':contactFormFields.contact_email && !errors[0]}">
                                                        <span class="float-label">
                                                            <input type="email" v-model="contactFormFields.contact_email" class="form-control" name="email" id="contact_email" placeholder="Email address*">
                                                            <label for="contact_email" class="control-label">Email address*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !contactFormFields.contact_email || errors[0] "></i>
                                                            <i class="fa fa-check" v-if="contactFormFields.contact_email && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group" :class="{'errors': mobileInputError == false,'success':contactFormFields.mobile_number && mobileInputError}">
                                                    <div class="float-label">
                                                        <label for="mobile_number" class="control-label">Mobile Number*</label>
                                                        <vue-tel-input
                                                            v-model="contactFormFields.mobile_number" 
                                                            v-bind="MobileNumberSetting"
                                                            @open="isMobileNumberDropdownOpen(true)"
                                                            @close="isMobileNumberDropdownOpen(false)"
                                                            @validate ="mobileInputValidate"
                                                            @keydown.native="restrictSpecialCharacter($event)"
                                                        >
                                                        <template v-slot:arrow-icon>
                                                            <span class="vti__dropdown-arrow">{{ mobileNumberDropdownOpen ? '▲' : '▼' }}</span>
                                                        </template>
                                                        </vue-tel-input>
                                                        <div class="line"></div>
                                                        <i class="fa fa-times" v-if="mobileInputError == false"></i>
                                                        <i class="fa fa-check" v-if="mobileInputError && contactFormFields.mobile_number"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group captch-form-group">
                                                    <invisible-recaptcha sitekey="6LdzP8EdAAAAAJwYLjDt7-_MBWVSEnVE6xNZ3UHC" :validate="prepare" :callback="callbackCaptcha" :disabled="loading" type="submit" name="submit" class="btn btn-green btn-gradiunt btn-block btn-lg">
                                                        SUBMIT REQUEST
                                                    </invisible-recaptcha>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Partner -->
            <section class="partner-section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <div class="title-section">
                                <h3 class="title">Our Partners</h3>
                            </div>
                            <div class="our-patner">
                                <ul class="d-flex flex-wrap">
                                    <li>
                                        <div class="slider vertical-slider">
                                            <VueSlickCarousel :speed='2000' v-bind="partnerSettings">
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-2.png" alt="partner" />
                                                </div>
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-1.png" alt="partner" />
                                                </div>
                                            </VueSlickCarousel>
                                        </div>
                                    </li>
                                    <li>
                                        <div id="vertical-1" class="slider vertical-slider-2">
                                            <VueSlickCarousel :speed='3000' v-bind="partnerSettings">
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-3.png" alt="partner" />
                                                </div>
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-4.png" alt="partner" />
                                                </div>
                                            </VueSlickCarousel>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="slider vertical-slider-3">
                                            <VueSlickCarousel :speed='1000' v-bind="partnerSettings">
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-5.png" alt="partner" />
                                                </div>
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-6.png" alt="partner" />
                                                </div>
                                            </VueSlickCarousel>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="slider vertical-slider-4">
                                            <VueSlickCarousel :speed='500' v-bind="partnerSettings">
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-7.png" alt="partner" />
                                                </div>
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-8.png" alt="partner" />
                                                </div>
                                            </VueSlickCarousel>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="slider vertical-slider-5">
                                            <VueSlickCarousel :speed='2000' v-bind="partnerSettings">
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-9.png" alt="partner" />
                                                </div>
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-10.png" alt="partner" />
                                                </div>
                                            </VueSlickCarousel>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="slider vertical-slider-6">
                                            <VueSlickCarousel :speed='1000' v-bind="partnerSettings">
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-11.png" alt="partner" />
                                                </div>
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-12.png" alt="partner" />
                                                </div>
                                            </VueSlickCarousel>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="slider vertical-slider-7">
                                            <VueSlickCarousel :speed='3000' v-bind="partnerSettings" :style="{height: '89px'}">
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-13.png" alt="partner" />
                                                </div>
                                                <div class="item">
                                                    <img src="@/assets/partner/partner-14.png" alt="partner" />
                                                </div>
                                            </VueSlickCarousel>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Cookies Modal -->
            <b-modal id="cookies-model" hide-footer hide-header ref="cookies" modal-class="cookies-model customer-home-modal" >
                <div class="modal-area">
                   <div class="cookies">
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <div class="cookies-content">
                                        <div class="cookies-img">
                                            <img src="@/assets/img/cookies.svg" alt="cookies">
                                        </div>
                                        <div class="cookies-text">
                                            <h3>Are you ok with optional cookies?</h3>
                                            <p>We’re not talking about the crunchy, tasty kind. These ones allow us to improve your experience online and improve our products. Some of these cookies are necessary to make the site work. We use other optional cookies to understand how the website is used. All our cookie data is anonymised but we won’t turn them on until you accept.Learn more in <a href="#"> our cookie</a> and <a href="#">privacy policies</a>.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex flex-row-reverse flex-wrap justify-content-center justify-content-md-start">
                                    <div class="cookies-btn btn btn-gradiunt" data-dismiss="modal" aria-label="Close" @click="$bvModal.hide('cookies-model')">
                                         ACCEPT ALL
                                    </div>
                                    <div class="cookies-btn btn btn-outline-primary" data-dismiss="modal" aria-label="Close" @click="$bvModal.hide('cookies-model')">
                                        ACCEPT SELECTED
                                    </div>
                                </div>

                                <div class="col-12 cookies-box">
                                    <div class="form-group mb-0">
                                        <div class="custome-checkbox gray-checkbox">
                                            <input type="checkbox" name="Necessary" id="Necessary" class="form-control" checked disabled>
                                            <label>Strictly Necessary </label>
                                        </div>
                                        <div class="custome-checkbox">
                                            <input type="checkbox" name="Functional" id="Functional" class="form-control" checked>
                                            <label>Functional </label>
                                        </div>
                                        <div class="custome-checkbox">
                                            <input type="checkbox" name="Performance" id="Performance" class="form-control" checked>
                                            <label>Performance</label>
                                        </div>
                                        <div class="custome-checkbox">
                                            <input type="checkbox" name="Marketing" id="Marketing" class="form-control" checked>
                                            <label>Marketing</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
const { CloudCarousel, CloudCarouselItem } = require('vue-cloud-carousel')
const { Carousel3d, Slide } = require('vue-carousel-3d')
const VueSlickCarousel = require('vue-slick-carousel')
const { Sketch } = require('vue-color-sketch')
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const InvisibleRecaptcha = require('vue-invisible-recaptcha').default
const { VueTelInput } = require('vue-tel-input')
const { VueTypedJs } = require('vue-typed-js')
import "@/plugins/vee-validate";
export default {
    name:'CustomerHomeComponent',
    components: { 
        CloudCarouselItem, 
        CloudCarousel,
        Carousel3d,Slide,
        VueSlickCarousel,
        'sketch-picker': Sketch,
        ValidationObserver,
        ValidationProvider,
        "invisible-recaptcha": InvisibleRecaptcha,
        VueTelInput,
        'vue-typed-js':VueTypedJs
    },
    data(){
        return{
            show_banner: false,
            primaryColorCode:'#009396',
            secondaryColorCode:'#6FB73F',
            banner_li: [
                '<li> <strong> FREE </strong> Mobile Money and Bank Deposits!</li><li> <strong> BEST </strong> Exchange Rates!</li><li> <strong> FREE </strong> Life Insurance cover for your recipients!</li>'
            ],
            corouselIndex: 5,
            whyChoosePayangelTitle: '',
            whyChoosePayangelDesc: '',
            options: {
                yOrigin: 130,
                xRadius: 150,
                yRadius: 150,
                farScale: 1,
                autoPlay: 0,
                bringToFront: true,
                frontItemClass: "active",
                transforms:false,
                smooth: true,
            },
            slides: 7,
            testimonialSettings:{
                "dots": false,
                "infinite": true,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "vertical": true,
                "arrows": false,
                "adaptiveHeight": true,
            },

            partnerSettings:{
                "infinite": true,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "vertical": true,
                "verticalSwiping": true,
                "autoplay": true,
                "arrows": false,
                "dots": false
            },
            couroselInterval: null,

            contactFormFields:{
                businesst_name:'',
                contact_email:'',
                mobile_number:''
            },

            MobileNumberSetting: {
                mode: "auto",
                autoFormat:false,
                defaultCountry:"GB",
                inputOptions:{tabindex: 0},
                dropdownOptions:{
                    showFlags: true,
                    showDialCodeInSelection:true
                }
            },
            mobileInputError:'',
            mobileNumberDropdownOpen:false,
            loading :false
        }
    },methods: {
        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },3000);
            this.couroselInterval = setInterval(function() {
                v.$refs.whyChoosePayangelCourosel.goTo(v.corouselIndex);
                v.onRotateCorousel();
                if(v.corouselIndex > 0) {
                    v.corouselIndex--;
                } else {
                    v.corouselIndex = 5;
                }
            },3000);
        },
        onRotateCorousel(){
            var item = document.getElementsByClassName("cloud-carousel-item active")[0];
            this.whyChoosePayangelTitle = item.getAttribute("data-title");
            this.whyChoosePayangelDesc = item.getAttribute("data-description");
        },

        onBeforeSlideChange(index) {
            this.$refs.customerCommentCourosel.goTo(index);
        },

        moveImgParallax: function(event) {
            event.target.style.transform = 'translate(' + event.pageY /20 + 'px,' + -event.pageX /50+ 'px)'
        },

        updatePrimaryColor(color) {
            this.primaryColorCode = color.hex;
            document.documentElement.style.setProperty("--primary-color", color.hex);
            document.documentElement.style.setProperty("--primary-color-5", color.hex+'0D');
            document.documentElement.style.setProperty("--primary-color-10", color.hex+'1A');
        },

        updateSecondaryColor(color) {
            this.secondaryColorCode = color.hex;
            document.documentElement.style.setProperty("--secondary-color", color.hex);
            document.documentElement.style.setProperty("--secondary-color-5", color.hex+'0D');
            document.documentElement.style.setProperty("--secondary-color-10", color.hex+'1A');
        },

        isMobileNumberDropdownOpen(mobileNumber){
            this.mobileNumberDropdownOpen = mobileNumber
        },

        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },

        callbackCaptcha(recaptchaToken){
            if(typeof(this.mobileInputError) == 'undefined'){
                this.mobileInputError = false
            }

            if(this.mobileInputError){
               console.log(recaptchaToken)
            }

            if(!this.contactFormFields.businesst_name) {
                this.$refs.businessName.applyResult({
                    errors: ["Please enter the value"],
                    valid: false,
                    failedRules: {}
                });
            }

            if(!this.contactFormFields.contact_email) {
                this.$refs.contactEmail.applyResult({
                    errors: ["Please enter the value"],
                    valid: false,
                    failedRules: {}
                });
            }
        },

        prepare(){
            this.loading = true
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        }
    },mounted () {
        this.onLoad();
        this.$refs['cookies'].show()
    },beforeDestroy () {
        clearInterval(this.couroselInterval);
    },watch: {
        mobileNumber(val) {
            this.contactFormFields.mobile_number = val.replace(/\W/g, "");
        },
    }
}
</script>