<template>
    <div class="auth-left d-lg-block d-none">
        <div class="d-flex align-items-center justify-content-center h-100">
            <div class="left-text">
                <h2 v-html="welcome_messages.heading"></h2>
                <h5>{{ welcome_messages.sub_heading }}</h5>
                <div class="banner-list-container">
                    <template v-if="show_banner">
                        <vue-typed-js :strings="welcome_messages.banner_li" :loop="true" :typeSpeed="10">
                            <ul class="list-unstyled banner-list typing"></ul>
                        </vue-typed-js>
                    </template>
                </div>
                <ul class="list-unstyled banner-list">
                    <li v-for="(list,index) in welcome_messages.banner_lists" :key="index">
                        <p class="mb-0 d-flex align-items-center"><img :src="list.image" alt=""><span v-html="list.innertext"></span></p>
                    </li>
                </ul>
            </div>
            <div class="left-globe-img">
                <img :src="welcome_messages.banner_image" class="banner-img" alt="">
            </div>
        </div>
    </div>
</template>
<script>
const { VueTypedJs } = require('vue-typed-js');
export default {
    name:'SLeftSideComponent',
    components:{'vue-typed-js':VueTypedJs},
    data(){
        return{
            show_banner: false,
            welcome_messages:{
                heading:'Welcome to <span class="text-uppercase">One World</span>',
                sub_heading:'Manage all your business receipts and payments in one place',
                banner_li: [
                    '<li><p class="mb-0 d-flex align-items-center"><span><strong>One</strong> Account</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span><strong>Global </strong> Capability</p></li> <li><p class="mb-0 d-flex align-items-center"> <span><strong>Superior </strong> Technology</span></p></li>'
                ],
                button_text:'Sign up for free in 3 minutes',
                banner_image: require(`@/assets/standapp/banner-standapp-v2.svg`)
            },
        }

    },
    methods: {
        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },3000)
        }
    },
    mounted () {
        this.onLoad();
    }
}
</script>