<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>Transaction Detail</h2>
                    </div>
                    <div class="body">
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <ul class="list-unstyled">
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Sender Name:</div>
                                            <div class="col-md-7"><span>{{transactionDetails.senderFirstName}} {{transactionDetails.senderMiddleName}} {{transactionDetails.senderLastName}}</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Sender Phone:</div>
                                            <div class="col-md-7"><span>{{transactionDetails.senderPhone}}</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Sender Currency:</div>
                                            <div class="col-md-7"><span>{{transactionDetails.sendingCurrency}}</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Transfer Type:</div>
                                            <div class="col-md-7"><span>{{transactionDetails.transferType}}</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Provider Name:</div>
                                            <div class="col-md-7"><span>{{transactionDetails.provider.name}}</span></div>
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                            <div class="col-md-6 col-12">
                                <ul class="list-unstyled">
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Benificiary Name:</div>
                                            <div class="col-md-7"><span>{{transactionDetails.beneficiaryFirstName}} {{transactionDetails.beneficiaryMiddleName}} {{transactionDetails.beneficiaryLastName}}</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Benificiary Currency:</div>
                                            <div class="col-md-7"><span>{{transactionDetails.receivingCurrency}}</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Destination Amount:</div>
                                            <div class="col-md-7"><span>{{transactionDetails.destinationAmount}}</span></div>
                                        </div>
                                    </li>
                                    <span v-if="transactionDetails.transferType == 'Mobile'">
                                        <li class="mb-2">
                                            <div class="row">
                                                <div class="col-md-5">Account Name:</div>
                                                <div class="col-md-7"><span>{{transactionDetails.mobileNetwork}}</span></div>
                                            </div>
                                        </li>
                                        <li class="mb-2">
                                            <div class="row">
                                                <div class="col-md-5">Account Number:</div>
                                                <div class="col-md-7"><span>{{transactionDetails.mobileNumber}}</span></div>
                                            </div>
                                        </li>
                                    </span>
                                    <span v-if="transactionDetails.transferType == 'Banks'">
                                        <li class="mb-2">
                                            <div class="row">
                                                <div class="col-md-5">Account Name:</div>
                                                <div class="col-md-7"><span>{{transactionDetails.bankName}}</span></div>
                                            </div>
                                        </li>
                                        <li class="mb-2">
                                            <div class="row">
                                                <div class="col-md-5">Account Number:</div>
                                                <div class="col-md-7"><span>{{transactionDetails.bankAccountNumber}}</span></div>
                                            </div>
                                        </li>
                                    </span>
                                </ul>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-md-row justify-content-center">
                            <b-button variant="secondary btn-mw" size="lg" @click="cancel()">Ok</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import shared from "@/shared.js";
export default {
    name: "TransactionDetailComponent",
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
    },data(){
        return { 
            transactionDetails : ''
        }
    }, methods: {
        cancel(){
            this.$router.push({ path: '/transaction/list' });
        },
        async transactionDetail(transactionId){
            try{
                let paramData = {
                    transactionid : transactionId
                }
                const response = await this.$http.get(this.$basepythonurl+"payment/transactions-details/",
                    {
                        params: paramData
                    }
                );
                if (response.data.code == "0130-000") {
                    this.transactionDetails = response.data.data[0];
                    // console.log(this.transactionDetails);
                }
            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        }
    },mounted() {
        if (sessionStorage.getItem("jwtToken")) {
            this.transactionDetail(sessionStorage.getItem('transactionID'));
        } else {
            shared.toastrError("Please login to continue");
            this.$router.push({ path: "/standapp-login" });
        }
    },
};
</script>