<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-10">
                        <div class="card">
                            <ValidationObserver v-slot="{ passes }">
                                <div class="header pb-1 text-center">
                                    <p class="lead">Recover my password</p>
                                </div>
                                <div class="body">
                                    <form class="form-auth-small" @submit.prevent="passes(forgotSubmit)" >
                                        <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !forgotformdata.email || errors[0],'success':forgotformdata.email && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="email" v-model="forgotformdata.email" class="form-control" name="email" id="forgot-email">
                                                    <label for="forgot-email" class="control-label">Email*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !forgotformdata.email || errors[0]"></i>
                                                    <i class="fa fa-check" v-if="forgotformdata.email && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <b-button type="submit" block variant="primary" size="lg">RESET PASSWORD</b-button>
                                        <div class="bottom">
                                            <span class="helper-text">Know your password? <router-link to="/authentication/page-login-v2">Login</router-link></span>
                                        </div>
                                    </form>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="body text-center pb-4">
                            <img :src="welcome_messages.standApp_image" class="banner-img-powered" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <img :src="welcome_messages.banner_image" class="banner-img" alt=""> -->
        </div>
    </section>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
    name:'ForgotPasswordComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        forgotSubmit() {
             this.$router.push({ path: '/authentication/page-otp-v2' })
        },
        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },1000)
        }
    },
    data(){
        return{
            show_banner: false,
            welcome_messages:{
                heading:'Welcome to ONE WORLD',
                sub_heading:'The one Account you need for',
                banner_li: [
                    '<li><p class="mb-0 d-flex align-items-center"><span>All your payments</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All your collections</p></li> <li><p class="mb-0 d-flex align-items-center"> <span>Your invoicing</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All the ways your customers pay</span></p></li>'
                ],
                button_text:'Sign up for free in 3 minutes',
                banner_image: require(`@/assets/home_image/banner.svg`),
                standApp_image:  require(`@/assets/home_image/Powered_by_StandApp.svg`),
            },
            
            forgotformdata: {
                email: "",
            }
        }
    },
    mounted () {
        this.onLoad();
    }
}
</script>
<style scoped>

</style>