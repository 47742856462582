<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb
                :showFilter="true"   
                :showDateFilter="true">
            </bread-crumb>
        </div>

        <!-- V3 -->
        <div class="row clearfix">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card top_report card-top-line">
                    <div class="body d-flex flex-column">
                        <div class="d-flex justify-content-between">
                            <i class="fa fa-2x fa fa-user-plus text-theme"></i>
                            <div class="d-flex flex-column justify-content-center text-right">
                                <h6>New Members(today)</h6>
                                <span class="font700">220</span>
                            </div>
                        </div>
                        <div class="progress progress-xs mb-0 mt-3">
                            <div role="progressbar" aria-valuenow="87" aria-valuemin="0" aria-valuemax="100" class="progress-bar bg-danger" style="width: 87%;"></div>
                        </div>
                        <small class="text-muted mt-1">1% compared to yesterday</small>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card top_report card-top-line">
                    <div class="body d-flex flex-column">
                        <div class="d-flex justify-content-between">
                            <i class="fa fa-2x fa fa-calendar text-theme"></i>
                            <div class="d-flex flex-column justify-content-center text-right">
                                <h6>New This Month</h6>
                                <span class="font700">100</span>
                            </div>
                        </div>
                        <div class="progress progress-xs mb-0 mt-3">
                            <div role="progressbar" aria-valuenow="28" aria-valuemin="0" aria-valuemax="100" class="progress-bar bg-success" style="width: 28%;"></div>
                        </div>
                        <small class="text-muted mt-1">19% compared to last month</small>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card top_report card-top-line">
                    <div class="body d-flex flex-column">
                        <div class="d-flex justify-content-between">
                            <i class="fa fa-2x fa-users text-theme"></i>
                            <div class="d-flex flex-column justify-content-center text-right">
                                <h6>Total Member</h6>
                                <span class="font700">3200</span>
                            </div>
                        </div>
                        <div class="progress progress-xs mb-0 mt-3">
                            <div role="progressbar" aria-valuenow="41" aria-valuemin="0" aria-valuemax="100" class="progress-bar bg-info" style="width: 41%;"></div>
                        </div>
                        <small class="text-muted mt-1">19% compared to last year</small>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card top_report card-top-line">
                    <div class="body d-flex flex-column">
                        <div class="d-flex justify-content-between">
                            <i class="fa fa-2x fa fa-map-marker text-theme"></i>
                            <div class="d-flex flex-column justify-content-center text-right">
                                <h6>Member Location</h6>
                                <span class="font700">3000</span>
                            </div>
                        </div>
                        <div class="progress progress-xs mb-0 mt-3">
                            <div role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" class="progress-bar bg-warning" style="width: 75%;"></div>
                        </div>
                        <small class="text-muted mt-1">19% compared to last year</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Members List</h2>
                    </div>
                    <!-- Members Listing-->
                    <div class="body pt-0">
                        <!-- Filters/Buttons -->
                        <div class="chart-top-action d-lg-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-lg-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="search-transactions">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-lg-0">
                                <b-button class="fliter-btn btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                    <div class="d-flex justify-content-between p-3">
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input One"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Two"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Three"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-primary h-100" @click="isVisibleFilter = false">Apply</button>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false">Cancel</button>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                            <!-- CopyLink/NewMember/UploadMember Buttons -->
                            <div class="d-md-flex justify-content-end mt-3 mt-lg-0">
                                <button class="btn btn-outline-primary btn-lg mr-1 mt-2 mt-sm-0"  @click="copyURL(newMemberUrl)">Copy Form Link <i class="fa fa-copy ml-1"></i></button>  
                                <a :href="newMemberUrl" target="_blank" class="btn btn-primary btn-lg mr-1 my-2 my-md-0 btn-mw">Add Member <i class="fa fa-plus-square ml-1"></i></a>
                                <!-- Upload Members Button -->
                                <button class="btn btn-outline-primary btn-lg " v-b-modal.upload_member>Upload Members <i class="fa fa-upload ml-1"></i></button>
                                <!-- Upload Members Model-->
                                <b-modal id="upload_member" title="Upload Member" ok-title="Upload" hide-footer>
                                    <div class="modal-area">
                                        <ValidationObserver v-slot="{ passes }">
                                            <form @submit.prevent="passes(uploadMemberDataSubmit)" id="uploadMember-form" ref="form">
                                                <div class="d-flex flex-column flex-md-row justify-content-start align-items-center">
                                                    <ValidationProvider name="csv_excel_file" rules="required" v-slot="{ errors}">
                                                        <div class="form-group" :class="{'errors': errors.length && !uploadMember.csvExcelFile,'success':uploadMember.csvExcelFile}">
                                                            <div class="custom-file">
                                                                <b-form-file
                                                                    v-model="uploadMember.csvExcelFile"
                                                                    placeholder="Select Excel/CSV*"
                                                                    drop-placeholder="Drop file here..."
                                                                    accept=".xls,.csv"
                                                                ></b-form-file>
                                                                <i class="fa fa-times" v-if="errors.length && !uploadMember.csvExcelFile"></i>
                                                                <i class="fa fa-check" v-if="uploadMember.csvExcelFile"></i>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="form-group">
                                                        <b-button variant="outline-primary ml-md-3 ml-0 btn-mw" size="lg">Download Template</b-button>
                                                    </div>
                                                </div>
                                                <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                    <b-button type="submit" variant="primary btn-mw" size="lg">Upload</b-button>
                                                    <b-button @click="$bvModal.hide('upload_member')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                </div>
                                            </form>
                                        </ValidationObserver>
                                    </div>
                                </b-modal>
                            </div>
                        </div>
                        <!-- All Members List -->
                        <b-table 
                            responsive
                            table-class="mb-0 table-custom border"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(details)="data">
                                <div class="media-object flag-img mr-2">
                                    <img :src="data.value.user_img" alt="" width="50px" class="rounded-circle">
                                    <span :class="data.value.flagClass"></span>
                                </div>
                                <span class="user-name">{{ data.value.name }}</span>
                            </template>
                            <template #cell(action)>
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item href="#">Edit</b-dropdown-item>
                                    <b-dropdown-item>Request Dues</b-dropdown-item>
                                    <b-dropdown-item>Re-activate</b-dropdown-item>
                                    <b-dropdown-item>Gone Away</b-dropdown-item>
                                    <b-dropdown-item>Terminally ill</b-dropdown-item>
                                    <b-dropdown-item>Deceased</b-dropdown-item>
                                    <b-dropdown-item>Current Executive</b-dropdown-item>
                                    <b-dropdown-item>Past Executive</b-dropdown-item>
                                    <b-dropdown-item  @click="showAlert">Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import '@/plugins/sweetalert2'
import "@/plugins/vee-validate";

export default {
    name:'MemberListV3Component',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
    },
    methods: {
        showAlert() {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }
            })
        },
        
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },

        copyURL(url){
            var m = document;
            url = m.createTextNode(url);
            var w = window;
            var b = m.body;
            var d = {};
            b.appendChild(url);
            if (b.createTextRange) {
                d = b.createTextRange();
                d.moveToElementText(url);
                d.select();
                m.execCommand('copy');
            } 
            else {
                d = m.createRange();
                var g = w.getSelection;
                d.selectNodeContents(url);
                g().removeAllRanges();
                g().addRange(d);
                m.execCommand('copy');
                g().removeAllRanges();
            }
            url.remove();
        },

        uploadMemberDataSubmit(){
            this.$bvModal.hide('upload_member');
        },
    },data(){
        return{
            items:[
                {
                    details: {name:'John Smith',user_img: require(`@/assets/xs/avatar4.jpg`),flagClass: "flag-icon flag-icon-ca fi-md fi-round"},
                    memberSince: '10-Feb-1966',
                    bdate:'12-Jan-1988',
                    profession:'Accountant',
                    mobileNumber:'16102347489',
                    email:'john@gmail.com',
                    contribution:'47.3',
                    action:''
                },
                {
                    details: {name:'Charlotte Jake',user_img: require(`@/assets/xs/avatar1.jpg`),flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                    memberSince: '1-March-1980',
                    bdate:'18-Jan-1985',
                    profession:'Investment Banker',
                    mobileNumber:'16102347557',
                    email:'charlotte@gmail.com',
                    contribution:'44.8',
                    action:''
                },
                {
                    details: {name:'Grayson Shams',user_img: require(`@/assets/xs/avatar3.jpg`),flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                    memberSince: '10-Feb-1966',
                    bdate:'22-Feb-1991',
                    profession:'Programmer',
                    mobileNumber:'14844493850',
                    email:'grayson@gmail.com',
                    contribution:'64.0',
                    action:''
                },
                {
                    details: {name:'Jacob Camly',user_img: require(`@/assets/xs/avatar4.jpg`),flagClass: "flag-icon flag-icon-eu fi-md fi-round"},
                    memberSince: '15-Jun-1987',
                    bdate:'22-Feb-1993',
                    profession:'Commercial Banker',
                    mobileNumber:'15852135931',
                    email:'jacob@gmail.com',
                    contribution:'78.5',
                    action:''
                },
                {
                    details: {name:'Amelia Hank',user_img: require(`@/assets/xs/avatar2.jpg`),flagClass: "flag-icon flag-icon-fr fi-md fi-round"},
                    memberSince: '8-Aug-1960',
                    bdate:'8-March-1987',
                    profession:'Market Research Analyst',
                    mobileNumber:'14844732134',
                    email:'amelia@gmail.com',
                    contribution:'62.0',
                    action:''
                },
                {
                    details: {name:'Michael Larry',user_img: require(`@/assets/xs/avatar1.jpg`),flagClass: "flag-icon flag-icon-ru fi-md fi-round"},
                    memberSince: '5-Apr-1950',
                    bdate:'8-March-1989',
                    profession:'Banker',
                    mobileNumber:'14842989674',
                    email:'michael@gmail.com',
                    contribution:'12.1',
                    action:''
                },
            ],
           
            fields: [
                { key: 'details', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'memberSince', label: 'Member Since',sortable: true,thClass:'th_sortfix'},
                { key: 'bdate', label: 'Birth Date',sortable: true,thClass:'th_sortfix'},
                { key: 'profession', label: 'Profession',sortable: true,thClass:'th_sortfix'},
                { key: 'mobileNumber', label: 'Mobile Number',sortable: true,thClass:'th_sortfix'},
                { key: 'email', label: 'Email',sortable: true,thClass:'th_sortfix'},
                { key: 'contribution', label: 'Total Contributions',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'Action',thClass:'th_sortfix'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            newMemberUrl: 'https://wrraptheme.com/demo/members-form/members-form.html',
            uploadMember:{
                csvExcelFile: null
            },

            isVisibleFilter: false,
        }
    },mounted() {
        this.totalRows = this.items.length
    }

}
</script>