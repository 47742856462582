<template>
    <b-modal id="base_timer" centered hide-footer hide-header>
        <div class="modal-area">
            <ul class="list-unstyled text-center mb-0">
                <li class="d-flex align-items-center flex-column mb-3">
                    <i class="fa fa-5x fa-warning text-warning mr-3 blink"></i>
                    <h5 class="mb-0">Your session will time out in 2 minutes</h5>
                </li>
                <li>
                    <p class="text-center">
                        For your security, your account will log out if there has been no activity for 5 minutes.
                        Need more time? Select 'Stay logged in' to continue managing your account online.
                    </p>
                </li>
                <li class="my-5">
                    <div class="base-timer">
                        <svg class="base-timer__svg" viewBox="0 0 100 100">
                            <g class="base-timer__circle">
                                <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                                <path :stroke-dasharray="circleDasharray" class="base-timer__path-remaining primary" 
                                d="
                                    M 50, 50
                                    m -45, 0
                                    a 45,45 0 1,0 90,0
                                    a 45,45 0 1,0 -90,0
                                "></path>
                            </g>
                        </svg>
                        <strong class="base-timer__label flex-column">{{ formattedTimeLeft }} <small>Sec</small></strong>
                    </div>
                </li>
                <li class="d-flex align-items-center flex-column">
                    <b-button class="btn-lg mb-2 w-50" size="lg" variant="primary" @click="$bvModal.hide('base_timer')">Stay logged in</b-button>
                    <router-link to="/home" class="btn btn-outline-danger mr-1 btn-lg w-50">Log out</router-link>
                    
                </li>
            </ul>
            
        </div>
    </b-modal>
</template>
<script>
const FULL_DASH_ARRAY = 283;
const TIME_LIMIT = 120;
export default {
    name:'TimerModal',
    data() {
        return {
            timePassed: 0,
            timerInterval: null
        };
    },methods: {
        onTimesUp() {
            clearInterval(this.timerInterval);
        },

        startTimer() {
            this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
        },
        logout(){
            this.$router.push({path:'/home'})
        }
    },computed: {
        circleDasharray() {
            return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
        },

        formattedTimeLeft() {
            const timeLeft = this.timeLeft;
            const minutes = Math.floor(timeLeft / 60);
            let seconds = timeLeft % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
            
            if( `${minutes}:${seconds}` == '0:00'){
               this.logout()
            }
            return `${minutes}:${seconds}`;
        },

        timeLeft() {
            return TIME_LIMIT - this.timePassed;
        },

        timeFraction() {
            const rawTimeFraction = this.timeLeft / TIME_LIMIT;
            return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
        }
    },watch: {
        timeLeft(newValue) {
            if (newValue === 0) {
                this.onTimesUp();
            }
        }
    },mounted() {
        this.startTimer();
        this.$bvModal.show('base_timer')
    }
}
</script>