<template>
    <section class="main-content">
        <div class="cms-content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-8 col-lg-9 col-md-10 col-sm-12">
                        <div class="card card-top-line">
                            <div class="card-body">
                                <h2 class="page-title">Company Overview</h2>
                                <p>PayAngel is focused on making sure that the Africa diaspora is able to make payments for goods and services in their home countries easily. Our products and services are designed to provide tailored payment solutions for individuals and businesses in the markets that we serve. PayAngel is fast, reliable, secure, and convenient, and allows customers to make payments online whenever and wherever to their home country provided they have internet access. To execute our vision, we will offer innovative payment solutions with increased convenience, better exchange rates and provide an exceptional overall service with no hidden fees and charges. We will continue to leverage technology and welcome ideas that enhance our product offering to ensure that our customers and clients receive greater savings and continuous exceptional service.</p>
                                <h5 class="content-title">Where We Operate</h5>
                                <p>PayAngel currently operates in the UK, The Netherlands, France, Italy, Germany, Spain and Canada.  We will soon start offering our services in the US. Residents of these countries can send money to any of our destination countries in Africa.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:'CompanyOverviewFrontComponent'
}
</script>
