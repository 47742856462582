<template>
    <div id="footer" class="footer">
        <div class="container-fluid">
            <div class="row clearfix">
                <div class="col-12">
                    <div class="card d-flex align-items-center justify-content-between flex-md-row" v-if="footerType === 'admin'">
                        <div class="d-flex align-items-center">
                            <img v-if="toggleSidebarTheme == ''" width="120" src="@/assets/home_image/Powered_by_StandApp.svg" alt="">
                            <img v-if="toggleSidebarTheme == 'full-dark'" width="120" src="@/assets/home_image/Powered_by_StandApp_W.svg" alt="">
                            <p class="mb-0 mt-2">{{ footer_content.copy_rights }}</p>
                        </div>
                        <p class="mb-0">{{ footer_content.version }}</p>
                    </div>
                    <div class="card d-flex align-items-center justify-content-between flex-md-row" v-if="footerType === 'client'">
                        <div class="d-flex align-items-center">
                            <img v-if="toggleSidebarTheme == ''" width="120" src="@/assets/home_image/Payangel_Business_main.svg" alt="">
                            <img v-if="toggleSidebarTheme == 'full-dark'" width="120" src="@/assets/home_image/Payangel_Business_main_W.svg" alt="">
                        </div>
                        <p class="mb-0">{{ clientfooter_content.copy_rights }}</p>
                        <p class="mb-0">{{ clientfooter_content.version }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/plugins/event-bus.js';
export default {
    name:'AuthFooterCompoent',
    props: {
        footerType : String,
    },data(){
        return{
            toggleSidebarTheme: '',
            footer_content:{
                copy_rights: '© 2021',
                version:'Version: 2.6.0'
            },
            clientfooter_content:{
                copy_rights: '© 2013 - 2021 PAYINC. All Right Reserved.',
                version:'Version: 1.0.01'
            }
        }
    },mounted() {
        EventBus.$on('theme', (theme) => {
            this.toggleSidebarTheme = theme
        })
    }
}
</script>