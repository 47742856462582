<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="card card-top-line no-shadow">
                    <div class="body">
                        <div class="exchange-rate-calc">
                            <h6><a href="javascript:void(0);">£1 = $1.3722</a></h6>
                        </div>
                        <div class="user-account-main exchange-rate-content">
                            <ul class="list-unstyled mb-0 user-account-details">
                                <b-form @submit.prevent="userDetails">
                                    <li class="mt-4">
                                        <div class="d-md-flex align-items-center">
                                            <label class="mb-0 mr-5">GBP</label>
                                            <div class="right ml-auto">
                                                <b-form-group class="mb-0" label-for="input-gbp">
                                                    <div class="d-flex">
                                                        <div class="form-group form-select multiselect-grp mb-0">
                                                            <span class="float-label">
                                                                <multiselect
                                                                    v-model="user.curancy"
                                                                    :show-labels="false"
                                                                    :options="curancys_options"
                                                                    :searchable="false"
                                                                    label="name" 
                                                                    track-by="name"
                                                                    :custom-label="nameWithAmount"
                                                                    :preselect-first="true">
                                                                    <template slot="option" slot-scope="props">
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ props.option.name }}: {{ props.option.symbol }}{{ props.option.amount }}</span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect>
                                                            </span>
                                                        </div>
                                                        <b-form-input id="input-gbp" placeholder="0"></b-form-input>
                                                    </div>
                                                </b-form-group>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="text-center exchange-icon">
                                        <a href="javascript:void(0);"><i class="fa fa-exchange"></i></a>
                                    </li>
                                    <li class="mb-4">
                                        <div class="d-md-flex align-items-center">
                                            <label class="mb-0 mr-5">USD</label>
                                            <div class="right ml-auto">
                                                <b-form-group class="mb-0" label-for="input-usd">
                                                    <div class="d-flex">
                                                        <div class="form-group form-select multiselect-grp mb-0">
                                                            <span class="float-label">
                                                                <multiselect
                                                                    v-model="user.curancy_exchange" 
                                                                    :show-labels="false" 
                                                                    :options="curancys_options_exchange" 
                                                                    :searchable="false"
                                                                    label="name" 
                                                                    track-by="name"
                                                                    :custom-label="nameWithAmountExchange"
                                                                    :preselect-first="true">
                                                                    <template slot="option" slot-scope="props">
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ props.option.name }}: {{ props.option.symbol }}{{ props.option.amount }}</span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect>
                                                            </span>
                                                        </div>
                                                        <b-form-input id="input-usd" placeholder="0"></b-form-input>
                                                    </div>
                                                </b-form-group>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex justify-content-end">
                                        <b-button class="mr-1 btn-mw" size="lg" @click="$router.go(-1)">Back</b-button>
                                        <b-button type="submit" variant="primary btn-mw" size="lg">Continue</b-button>
                                    </li>
                                </b-form>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const Multiselect = require('vue-multiselect').default

export default {
    name:'ExchangeComponent',
    components: {
        BreadCrumb,
        Multiselect,
    },data (){
        return{
            curancys_options: [
                { name: 'Main', symbol:'₵', amount:'70,548.00'},
                { name: 'UK Office', symbol:'£', amount:'50,630.50'},
                { name: 'US Office' , symbol:'$', amount:'32,540.30'},
                { name: 'European Office', symbol:'€', amount:'42,540.30'},
            ],

            curancys_options_exchange: [
                { name: 'Main', symbol:'$', amount:'800.43'},
                { name: 'UK Office', symbol:'£', amount:'50,630.50'},
                { name: 'US Office' , symbol:'$', amount:'32,540.30'},
                { name: 'European Office', symbol:'€', amount:'42,540.30'},
            ],

            user:{
                curancy: null,
                curancy_exchange:null
            }
        }
    },methods: {
        userDetails(){
            this.$router.push({ path: '/pay-transfer/add-money' })
        },

        nameWithAmount ({ name, symbol,amount }) {
            return `${name}: ${symbol}${amount} `
        },

        nameWithAmountExchange ({ name, symbol,amount }) {
            return `${name}: ${symbol}${amount} `
        },
    }
}

</script>
