<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="align-items-center body d-flex flex-column flex-md-row justify-content-between">
                        <p class="font-18 mb-0 mb-5 mb-md-0 w-50">
                            <strong class="d-block font-24">You Don't have any Data Found</strong>
                            Please try later
                        </p>
                        <img src="@/assets/not-data.svg" width="500" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
export default {
    name:'NotFoundComponent',
    components: {
        BreadCrumb
    }
}
</script>
