// initial state
const state = () => ({
    contactDetail: {
        userEmail: '',
        userMobile: ''
    }
})

// getters
const getters = {}

// actions
const actions = {
    addContact ({ commit }, userContactDetails) {
        commit('setContact', userContactDetails)
    }
}

// mutations
const mutations = {
  setContact (state, contactDetail) {
    state.contactDetail.userEmail = contactDetail.email,
    state.contactDetail.userMobile = contactDetail.phone
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}