<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-10">
                        <div class="card">
                            <ValidationObserver v-slot="{ passes }">
                                <div class="header pb-1 text-center">
                                    <p class="lead">Login to your account</p>
                                </div>
                                <div class="body">
                                    <form class="form-auth-small" @submit.prevent="passes(loginSubmit)" >
                                        <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !loginformdata.email || errors[0],'success':loginformdata.email && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="email" v-model="loginformdata.email" class="form-control" name="email" id="signin-email" placeholder="Email*">
                                                    <label for="signin-email" class="control-label">Email*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !loginformdata.email || errors[0] "></i>
                                                    <i class="fa fa-check" v-if="loginformdata.email && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="password" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !loginformdata.passwords,'success':loginformdata.passwords}">
                                                <span class="float-label">
                                                    <input type="password" v-model="loginformdata.passwords" class="form-control" id="signin-password" placeholder="Password*">
                                                    <label for="signin-password" class="control-label">Password*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !loginformdata.passwords"></i>
                                                    <i class="fa fa-check" v-if="loginformdata.passwords"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <div class="form-group clearfix">
                                            <label class="fancy-checkbox element-left">
                                                <input type="checkbox">
                                                <span>Remember me</span>
                                            </label>								
                                        </div>
                                        <b-button type="submit" block variant="primary" size="lg">LOGIN</b-button>
                                        <div class="bottom">
                                            <span class="helper-text m-b-10"><i class="fa fa-lock"></i> <router-link to="/authentication/page-forgot-password-v2">Forgot password?</router-link></span>
                                            <span>Don't have an account? <router-link to="/authentication/page-register-v2">Register</router-link></span>
                                        </div>
                                    </form>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="body text-center pb-4">
                            <img :src="welcome_messages.standApp_image" class="banner-img-powered" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
    name:'LoginV2Component',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        loginSubmit: function() {
           this.$router.push({ path: '/account'})
        },

        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },1000)
        }
    },
    data(){
        return{
            show_banner: false,
            welcome_messages:{
                standApp_image:  require(`@/assets/home_image/Powered_by_StandApp.svg`),
            },
            
            loginformdata: {
                email: "",
                passwords:""
            }
        }
    },
    mounted () {
        this.onLoad();
    }
}
</script>
<style scoped>

</style>