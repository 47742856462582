<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
         <div class="row clearfix row-deck">
            <div class="col-lg-4 col-sm-6">
                <div class="card">
                    <div class="body d-flex justify-content-center align-items-center flex-column h-100">
                        <h6 class="text-center mb-3">Passing a parameter, you can execute something else for "Cancel"</h6>
                        <button class="btn btn-primary" @click="showAlert('parameter')">Click here!</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="card">
                    <div class="body d-flex justify-content-center align-items-center flex-column h-100">
                        <h6 class="text-center mb-3">Custom position</h6>
                        <button class="btn btn-primary" @click="showAlert('custom-position')">Click here!</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="card">
                    <div class="body d-flex justify-content-center align-items-center flex-column h-100">
                        <h6 class="text-center mb-3">Mixin example</h6>
                        <button class="btn btn-primary" @click="showAlert('mixin-example')">Click here!</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="card">
                    <div class="body d-flex justify-content-center align-items-center flex-column h-100">
                        <h6 class="text-center mb-3">A message with auto close timer</h6>
                        <button class="btn btn-primary" @click="showAlert('timer')">Click here!</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
import '@/plugins/sweetalert2'

export default {
    name:'SweetAlertComponent',
    components: {
        BreadCrumb,
    },methods: {
        // delete
        showAlert(type) {
            if(type == 'parameter'){
                const swalWithBootstrapButtons = this.$swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger mr-1'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, cancel!',
                    reverseButtons: true
                    }).then((result) => {
                    if (result.isConfirmed) {
                        swalWithBootstrapButtons.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                        )
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === this.$swal.DismissReason.cancel
                    ) {
                        swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'Your imaginary file is safe :)',
                        'error'
                        )
                    }
                })
            }else if(type == 'custom-position'){
                this.$swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
            }else if(type == 'mixin-example'){
                const Toast =  this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter',  this.$swal.stopTimer)
                        toast.addEventListener('mouseleave',  this.$swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Signed in successfully'
                })
            }else if(type == 'timer'){
                let timerInterval
                 this.$swal.fire({
                    title: 'Auto close alert!',
                    html: 'I will close in <b></b> milliseconds.',
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                         this.$swal.showLoading()
                        const b =  this.$swal.getHtmlContainer().querySelector('b')
                        timerInterval = setInterval(() => {
                        b.textContent =  this.$swal.getTimerLeft()
                        }, 100)
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss ===  this.$swal.DismissReason.timer) {
                        // console.log('I was closed by the timer')
                    }
                })
            }
        },
    }
}
</script>