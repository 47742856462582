<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="body">
                        <form @submit.prevent="reportBug" id="report-bug">
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp">
                                            <span class="float-label">
                                            <label for="category">Select Bug Category</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="reportBugs.category"
                                                    :show-labels="false" 
                                                    :options="categoryOptions" 
                                                    :searchable="false" >
                                                </multiselect> 
                                            </span>
                                        </div>
                                    </div>
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp">
                                            <span class="float-label">
                                            <label for="subtype">Bug Sub Type</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="reportBugs.sub_type"
                                                    :show-labels="false" 
                                                    :options="subTypeOptions" 
                                                    :searchable="false" >
                                                </multiselect> 
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <span class="float-label">
                                            <input type="text" v-model="reportBugs.page_url" class="form-control" id="page_url" placeholder="Page URL">
                                            <label for="page_url" class="control-label">Page URL</label>
                                            <div class="line"></div>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <div class="head">
                                            <label>Description</label>
                                        </div>
                                        <span class="float-label">
                                            <textarea type="text" v-model="reportBugs.description" class="form-control" id="description" placeholder="Description"/>
                                            <div class="line"></div>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12">
                                   <div class="page-card-group h-100">
                                        <div class="upload-box  h-100">
                                            <div class="your-id">
                                                <input type="file" multiple name="fields[upload_image_or_video][]" id="upload_image_or_video" class="opacity-0"  accept="image/*,video/*" @change="previewImageOrVideo"/>
                                                <label for="upload_image_or_video" class="d-flex flex-column align-items-center justify-content-center" >
                                                    <i class="fa fa-upload fa-2x text-primary"></i>
                                                    <p class="mt-3 mb-0">
                                                        <strong>Click to upload images or video</strong>
                                                        <small class="d-block mt-1">File Formate: Image or Video only</small>
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="iv-list">
                                <div class="img-list mb-3" v-if="reportBugs.image_list.length">
                                    <h6>Images</h6>
                                    <div class="row">
                                        <div class="col-lg-3 col-md-6 col-sm-12" v-for="(img,img_index) in reportBugs.image_list" :key="img_index">
                                            <div class="align-items-center border d-flex flex-column h-100 justify-content-between p-1 rounded">
                                                <img :src="img" class="img-fluid my-2"/>
                                                <small class="btn btn-outline-danger w-100" @click="removeUploadImage(img_index)">Delete</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="video-list mb-3" v-if="reportBugs.viedo_list.length && !reportBugs.isLoadingVideos">
                                    <h6>Videos</h6>
                                    <div class="row">
                                        <div class="col-lg-3 col-md-6 col-sm-12" v-for="(videodata,video_index) in reportBugs.viedo_list" :key="video_index" >
                                            <div class="align-items-center border d-flex flex-column h-100 justify-content-between p-1 rounded">
                                                <video controls class="mb-1">
                                                    <source :src="videodata" type="video/mp4">
                                                </video>
                                                <small class="btn btn-outline-danger w-100" @click="removeUploadVideo(video_index)">Delete</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-md-row justify-content-center">
                                <b-button variant="secondary btn-mw" size="lg">Cancel</b-button>
                                <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Submit</b-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const Multiselect = require('vue-multiselect').default;
export default {
    name:'ReportBugComponent',
    components: {BreadCrumb,Multiselect},
    data (){
        return{
            reportBugs:{
                category:'',
                sub_type:'',
                page_url:'',
                description:'',
                image_list: [],
                viedo_list:[],
                isLoadingVideos: false
            },

            categoryOptions:['Category 1','Category 2','Category 3','Category 4'],
            subTypeOptions:['Type 1','Type 2','Type 3', 'Type 4']
        }
    },methods: {
        reportBug(){
            console.log("submit");
        },

        previewImageOrVideo(event) {
            var input = event.target;
            var count = input.files.length;
            var validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
            if (input.files.length > 0) {
                for(var index = 0; index < count; index++) {
                    var reader = new FileReader();
                    var file = input.files[index];
                    if (validImageTypes.includes(file.type)) {
                        reader.onload = (e) => {
                            this.reportBugs.image_list.push(e.target.result);
                        }
                        reader.readAsDataURL(input.files[index]);
                    } else {
                        reader.onload = (e) => {
                            this.reportBugs.viedo_list.push(e.target.result);
                        }
                        reader.readAsDataURL(input.files[index]);
                    }
                }
            }
        },

        removeUploadImage(img_index){
            this.reportBugs.image_list.splice(img_index, 1)        
        },

        removeUploadVideo(video_index){
            this.reportBugs.isLoadingVideos = true;
            this.reportBugs.viedo_list.splice(video_index, 1);
            setTimeout(() =>{
                this.reportBugs.isLoadingVideos = false;
            }, 500)  
        },
    }
}

</script>