// initial state
const state = () => ({
    businessDetail: {
        country: '',
        businessName: '',
        email: '',
        mobile: '',
    }
})

// getters
const getters = {}

// actions
const actions = {
    addBusiness ({ commit }, businessDetails) {
        commit('setBusiness', businessDetails)
    }
}

// mutations
const mutations = {
  setBusiness (state, businessDetails) {
    state.businessDetail.country = businessDetails.country,
    state.businessDetail.businessName = businessDetails.name,
    state.businessDetail.email = businessDetails.email,
    state.businessDetail.mobile = businessDetails.mobile
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}