import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//layouts file
Vue.component('auth-layout', require('@/components/admin/layouts/main_layout/AuthLayout.vue').default);
Vue.component('default-layout', require('@/components/admin/layouts/main_layout/DefaultLayout.vue').default);
Vue.component('standapp-layout', require('@/components/admin/layouts/main_layout/Standapp.vue').default);
Vue.component('auth-v2-layout', require('@/components/admin/layouts/main_layout/AuthV2Layout.vue').default);
Vue.component('standapp-v2-layout', require('@/components/admin/layouts/main_layout/StandappV2.vue').default);
Vue.component('customer-layout', require('@/components/admin/layouts/main_layout/CustomerHomeLayout.vue').default);

//BootstrapVue
import { BootstrapVue} from 'bootstrap-vue'
Vue.use(BootstrapVue)

//axios
import axios from 'axios';
Vue.prototype.$http = axios;
import store from './store';

// Declare base url
Vue.prototype.$baseurl = process.env.VUE_APP_URL; //'https://standapp.com/api/v1/';
Vue.prototype.$basepythonurl = process.env.VUE_APP_PYTHON_URL;
// Vue.prototype.$baseurl = 'https://standapp.net/api/v1/';

const routes= [
    {
        path: '',
        redirect: '/standapp-home',
    },
    {
        path: '/home',
        name: 'home',
        component: require('@/components/admin/home/HomeComponent.vue').default,
        meta: {title: "Home Page", layout:'auth-v2'},
    },
    {
        path: '/customer-home',
        component: require('@/components/admin/customer_layout/customer_home/CustomerHomeComponent.vue').default,
        meta: {title: "Customer Home", layout:'customer'},
    },
    {
        path: '/customer',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        redirect: '/customer/login',
        children: [
            { path: 'login',component: require('@/components/admin/customer_layout/auth_pages/LoginRegister.vue').default,name:'customer_login', meta: {title: "Customer Login", layout:'customer'}},
            { path: 'mobile-number-varify',component: require('@/components/admin/customer_layout/auth_pages/MobileNumberVarify.vue').default,name:'mobile_number_varify', meta: {title: "Mobile Number Varification", layout:'customer'}},
            { path: 'personal-details',component: require('@/components/admin/customer_layout/auth_pages/PersonalDetails.vue').default,name:'personal_details', meta: {title: "Personal Details", layout:'customer'}},
            { path: 'set-password',component: require('@/components/admin/customer_layout/auth_pages/SetPassword.vue').default,name:'set_password', meta: {title: "Set Password", layout:'customer'}},
            { path: 'email-varification',component: require('@/components/admin/customer_layout/auth_pages/EmailVarification.vue').default,name:'email_varification', meta: {title: "Email Varification", layout:'customer'}},
        ]
    },
    {
        path: '/terms-and-conditions',
        component: require('@/components/admin/cms_frontend/TermsConditionsFrontComponent.vue').default,
        meta: {title: "Terms & Conditions", layout:'auth-v2'},
    },
    {
        path: '/company-overview',
        component: require('@/components/admin/cms_frontend/CompanyOverviewFrontComponent.vue').default,
        meta: {title: "Company Overview", layout:'auth-v2'},
    },
    {
        path: '/dashboard-v1',
        component: require('@/components/admin/dashboard/DashboardV1Component.vue').default,
        meta: {breadCrumb: "Dashboard V1", title: "Dashboard V1"},
    },
    {
        path: '/dashboard-v2',
        component: require('@/components/admin/dashboard/DashboardV2Component.vue').default,
        meta: {breadCrumb: "Dashboard V2", title: "Dashboard V2"}
    },
    {
        path: '/profile',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Edit Profile", title: "EditProfile"},
        children: [
            { path: 'edit-profile',component: require('@/components/admin/dashboard/dashboard_v2_widgets/profile/EditProfile.vue').default, meta: {breadCrumb: "Dashboard V2", title: "Edit Profile"}},
        ]
    },
    {
        path: '/client-dashboard',
        name: 'cdashboard',
        component: require('@/components/admin/dashboard/ClientDComponent.vue').default,
        meta: {breadCrumb: "Client Dashboard", title: "Client Dashboard"},
    },
    {
        path: '/members',
        component: require('@/components/admin/members/MemberListComponent.vue').default,
        meta: {breadCrumb: "Members List", title: "Members List"},
    },
    {
        path: '/members-v2',
        component: require('@/components/admin/members/MemberListV2Component.vue').default,
        meta: {breadCrumb: "Members V2", title: "Members List"},
    },
    {
        path: '/members-v3',
        component: require('@/components/admin/members/MemberListV3Component.vue').default,
        meta: {breadCrumb: "Members V3", title: "Members List"},
    },
    {
        path: '/account',
        name: 'account',
        component: require('@/components/admin/account/AccountComponent.vue').default,
        meta: {breadCrumb: "Accounts", title: "Accounts"},
    },
    // {
    //     path: '/transactions',
    //     name: 'transactions',
    //     component: require('@/components/admin/transactions/TransactionsComponent.vue').default,
    //     meta: {breadCrumb: "Transactions", title: "Transactions"},
    // },
    // {
    //     path: '/transactions-v2',
    //     component: require('@/components/admin/transactions/TransactionsV2Component.vue').default,
    //     meta: {breadCrumb: "Transactions V2", title: "Transactions V2"},
    // },
    {
        path: '/transaction',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Transaction", title: "Transaction"},
        children: [
            { path: 'list',component: require('@/components/admin/transaction/TransactionListComponent.vue').default, meta: { breadCrumb: "Transaction List", title: "Transaction List"}},
            { path: 'details', component: require('@/components/admin/transaction/TransactionDetailComponent.vue').default, meta: { breadCrumb: "Transaction Details", title: "Transaction Details" } },
        ]
    },
    {
        path: '/service',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {
            breadCrumb: 'Service',
        },
        children: [
            { path: 'bank-deposits', component: require('@/components/admin/service/BankDepositComponent.vue').default, meta: { breadCrumb: "Bank Deposits", title: "Bank Deposits"}},
            { path: 'mobile-money', component: require('@/components/admin/service/MobileMoneyComponent.vue').default, meta: { breadCrumb: "Mobile Money", title: "Mobile Money"}},
            { path: 'airtime', component: require('@/components/admin/service/AirtimeComponent.vue').default, meta: { breadCrumb: "Airtime", title: "Airtime"}},
            { path: 'sms', component: require('@/components/admin/service/SmsComponent.vue').default, meta: { breadCrumb: "SMS", title: "SMS"}},
            { path: 'short-code', component: require('@/components/admin/service/ShortCodeComponent.vue').default, meta: { breadCrumb: "USSD Short Code", title: "USSD Short Code"}},
        ]
    },
    {
        path: '/pay-transfer',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Pay Or Transfer", title: "Pay Or Transfer"},
        children: [
            { path: 'beneficiaries',component: require('@/components/admin/pay_to_transfer/BeneficiariesComponent.vue').default, meta: { breadCrumb: "Beneficiaries", title: "Beneficiaries"}},
            { path: 'pay-transfer-details',component: require('@/components/admin/pay_to_transfer/TransferComponent.vue').default, meta: { breadCrumb: "Payments Details", title: "Payments"}},
            { path: 'account-details', component: require('@/components/admin/pay_to_transfer/AccountDetailsComponent.vue').default, meta: { breadCrumb: "Account Details", title: "Account Details"}},
            { path: 'user-account-details', component: require('@/components/admin/pay_to_transfer/UserAccountDComponent.vue').default, meta: { breadCrumb: "User Account Details", title: "User Account Details"}},
            { path: 'exchange', component: require('@/components/admin/pay_to_transfer/ExchangeComponent.vue').default, meta: { breadCrumb: "Exchange", title: "Exchange"}},
            { path: 'add-money', component: require('@/components/admin/pay_to_transfer/AddMoneyComponent.vue').default, meta: { breadCrumb: "Add Money", title: "Add Money"}},
        ]
    },
    {
        path: '/reconciliations',
        component: require('@/components/admin/reconciliations/ReconciliationsComponent.vue').default,
        meta: {breadCrumb: "Reconciliations", title: "Reconciliations"},
    },
    {
        path: '/fx-rates',
        component: require('@/components/admin/rates/RatesComponent.vue').default,
        meta: {breadCrumb: "Fx Rates",title: "Fx Rates"},
    },
    {
        path: '/business',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Business", title: "Business"},
        children: [
            { path: 'list',component: require('@/components/admin/business/BusinessComponent.vue').default, meta: { breadCrumb: "Business List", title: "Business List"}},
            { path: 'details',component: require('@/components/admin/business/BusinesDetailComponent.vue').default, meta: { breadCrumb: "Business Details", title: "Business Details"}},
            { path: 'subsidiaries-detail',component: require('@/components/admin/business/SubsidiariesDetailComponent.vue').default, meta: { breadCrumb: "Subsidiaries Details", title: "Subsidiaries Details"}},
        ]
    },
    {
        path: '/risk',
        component: require('@/components/admin/risk/RiskComponent.vue').default,
        meta: {breadCrumb: "Risk",title: "Risk"},
    },
    {
        path: '/pricing',
        component: require('@/components/admin/pricing/PricingComponent.vue').default,
        meta: {breadCrumb: "Pricing",title: "Pricing"},
    },
    {
        path: '/invoice',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Invoice", title: "Invoice"},
        children: [
            { path: 'list',component: require('@/components/admin/invoice/InvoiceListComponent.vue').default, meta: { breadCrumb: "Invoice List", title: "Invoice List"}},
            { path: 'detail',component: require('@/components/admin/invoice/InvoicePreviewComponent.vue').default, meta: { breadCrumb: "Invoice Details", title: "Invoice Details"}},
            { path: 'customer-list',component: require('@/components/admin/invoice/AllCustomerComponent.vue').default, meta: { breadCrumb: "All Customer", title: "All Customer"}},
            { path: 'add-customer',component: require('@/components/admin/invoice/AddCustomerComponent.vue').default, meta: { breadCrumb: "Add Customer", title: "Add Customer"}},
            { path: 'add-group',component: require('@/components/admin/invoice/AddGroupComponent.vue').default, meta: { breadCrumb: "Add Group", title: "Add Group"}},
            { path: 'add-invoice-details',component: require('@/components/admin/invoice/AddInvoiceInfoComponent.vue').default, meta: { breadCrumb: "Invoice Details", title: "Invoice Details"}},
            { path: 'add-invoice-item',component: require('@/components/admin/invoice/AddInvoiceItemComponent.vue').default, meta: { breadCrumb: "Add Invoice Items", title: "Add Invoice Items"}},
        ]
    },
    {
        path: '/crowdfunding',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Crowdfunding", title: "Crowdfunding"},
        children: [
            { path: 'list',component: require('@/components/admin/crowdfunding/CampaingListComponent.vue').default, meta: { breadCrumb: "Campaign List", title: "Campaign List"}},
            { path: 'dashboard',component: require('@/components/admin/crowdfunding/CamDashboardComponent.vue').default, meta: { breadCrumb: "Dashboard", title: "Dashboard"}},
            { path: 'campaign',component: require('@/components/admin/crowdfunding/CampaignComponent.vue').default, meta: { breadCrumb: "Campaign", title: "Campaign"}},
            { path: 'campaign-basic1',component: require('@/components/admin/crowdfunding/AddCampaignComponent.vue').default, meta: { breadCrumb: "Add Campaign", title: "Add Campaign"}},
            { path: 'campaign-basic2',component: require('@/components/admin/crowdfunding/AddCampaignForm2.vue').default, meta: { breadCrumb: "Add Campaign", title: "Add Campaign"}},
            { path: 'campaign-basic3',component: require('@/components/admin/crowdfunding/AddCampaignForm3.vue').default, meta: { breadCrumb: "Add Campaign", title: "Add Campaign"}},
            { path: 'campaign-basic4',component: require('@/components/admin/crowdfunding/AddCampaignForm4.vue').default, meta: { breadCrumb: "Add Campaign", title: "Add Campaign"}},
            { path: 'campaign-faq',component: require('@/components/admin/crowdfunding/AddCampaignForm5.vue').default, meta: { breadCrumb: "Add Campaign", title: "Add Campaign"}},
        ]
    },
    {
        path: '/event',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {breadCrumb: "Event", title: "Event"},
        children: [
            { path: 'list',component: require('@/components/admin/event/EventListComponent.vue').default, meta: { breadCrumb: "Event List", title: "Event List"}},
            { path: 'dashboard',component: require('@/components/admin/event/EventDashboardComponent.vue').default, meta: { breadCrumb: "Dashboard", title: "Dashboard"}},
            { path: 'event-details',component: require('@/components/admin/event/EventForm1Component.vue').default, meta: { breadCrumb: "Add Event", title: "Add Event"}},
            { path: 'event-location',component: require('@/components/admin/event/EventForm2Component.vue').default, meta: { breadCrumb: "Add Event", title: "Add Event"}},
            { path: 'event-product',component: require('@/components/admin/event/EventForm3Component.vue').default, meta: { breadCrumb: "Add Event", title: "Add Event"}},
            { path: 'event-metadata',component: require('@/components/admin/event/EventForm4Component.vue').default, meta: { breadCrumb: "Add Event", title: "Add Event"}},
            { path: 'event-faq',component: require('@/components/admin/event/EventForm5Component.vue').default, meta: { breadCrumb: "Add Event", title: "Add Event"}},
        ]
    },
    {
        path: '/setting',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {
            breadCrumb: 'Setting',
        },
        children: [
            { path: 'user-list', component: require('@/components/admin/setting/UserListComponent.vue').default, meta: { breadCrumb: "User List", title: "User List"}},
            { path: 'user-add', component: require('@/components/admin/setting/AddUserComponent.vue').default, meta: { breadCrumb: "Add User", title: "Add User"}},
            { path: 'channels', component: require('@/components/admin/setting/ChannelsComponent.vue').default, meta: { breadCrumb: "Channels", title: "Channels"}},
            { path: 'blank-channels', component: require('@/components/admin/setting/BlankChannelsComponent.vue').default, meta: { breadCrumb: "Channels", title: "Channels"}},
            { path: 'preferences', component: require('@/components/admin/setting/PreferencesComponent.vue').default, meta: { breadCrumb: "Preferences", title: "Preferences"}},
            { path: 'limits', component: require('@/components/admin/setting/LimitsComponent.vue').default, meta: { breadCrumb: "Limits", title: "Limits"}},
            { path: 'payouts', component: require('@/components/admin/setting/PayoutComponent.vue').default, meta: { breadCrumb: "Payouts", title: "Payouts"}},
            { path: 'Profile', component: require('@/components/admin/setting/MyProfileComponent.vue').default, meta: { breadCrumb: "My Profile", title: "Profile"}},
            { path: 'setup-profile', component: require('@/components/admin/setting/SetupProfileComponent.vue').default, meta: { breadCrumb: "Set Up Your Profile", title: "Set Up Your Profile"}},
            { path: 'compliance', component: require('@/components/admin/setting/ComplianceComponent.vue').default, meta: { breadCrumb: "Compliance", title: "Compliance"}},
            { path: 'compliance-requirements', component: require('@/components/admin/setting/ComRequirementsComponent.vue').default, meta: { breadCrumb: "Registered Sole Proprietorship", title: "Compliance"}},
        ]
    },
    {
        path: '/cms',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: {
            breadCrumb: 'CMS',
        },
        children: [
            { path: 'list', component: require('@/components/admin/cms_backend/CmsListComponent.vue').default, meta: { breadCrumb: "CMS List", title: "CMS List"}},
            { path: 'add-page', component: require('@/components/admin/cms_backend/AddPageComponent.vue').default, meta: { breadCrumb: "Add Page", title: "Add Page"}},
        ]
    },
    {
        path: '/report-bug',
        component: require('@/components/admin/report_bug/ReportBugComponent.vue').default,
        meta: {breadCrumb: "Report Bug",title: "Report Bug"},
    },
    {
        path: '/page-404',
        component: require('@/components/admin/notfound/NotFoundComponent.vue').default,
        meta: {title: "No Data Found", breadCrumb: "No Data Found"},
    },
    {
        path: '/payangel-reward',
        component: require('@/components/admin/rewards/RewardsComponent.vue').default,
        meta: {breadCrumb: "Payangel Reward", title: "Payangel Reward"},
    },
    //Auth routes
    {
        path: '/authentication',
        redirect: '/page-*',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        children: [
            { path: 'page-login', component: require('@/components/admin/authentication/LoginComponent.vue').default,name: 'login', meta: { title: "Login", layout:'auth' }},
            { path: 'page-register', component: require('@/components/admin/authentication/RegisterComponent.vue').default,name: 'register', meta: { title: "Register", layout:'auth' }},
            { path: 'page-personaldetail', component: require('@/components/admin/authentication/PersonaldetailComponent.vue').default,name: 'pdetail', meta: { title: "Personal Detail", layout:'auth' }},
            { path: 'page-password', component: require('@/components/admin/authentication/PasswordComponent.vue').default,name: 'pwd', meta: { title: "Password", layout:'auth' }},
            { path: 'page-reset-password', component: require('@/components/admin/authentication/ResetPasswordComponent.vue').default,name: 'resetpwd', meta: { title: "Reset Password", layout:'auth' }},
            { path: 'page-otp', component: require('@/components/admin/authentication/OtpComponent.vue').default,name: 'otp', meta: { title: "OTP", layout:'auth' }},
            { path: 'page-passcode', component: require('@/components/admin/authentication/PasscodeOtpComponent.vue').default,name: 'passcode', meta: { title: "Passcode OTP", layout:'auth' }},
            { path: 'page-forgot-password', component: require('@/components/admin/authentication/ForgotPasswordComponent.vue').default,name: 'fpwd', meta: { title: "Forgot Password", layout:'auth' }},
            
            { path: 'page-login-v2', component: require('@/components/admin/authentication/LoginV2Component.vue').default,name: 'login_v2', meta: { title: "Login", layout:'auth-v2' }},
            { path: 'page-register-v2', component:  require('@/components/admin/authentication/RegisterV2Component.vue').default,name: 'register_v2', meta: { title: "Register", layout:'auth-v2' }},
            { path: 'page-personaldetail-v2', component: require('@/components/admin/authentication/PersonaldetailV2Component.vue').default,name: 'pdetail_v2', meta: { title: "Personal Detail", layout:'auth-v2' }},
            { path: 'page-password-v2', component: require('@/components/admin/authentication/PasswordV2Component.vue').default,name: 'pwd_v2', meta: { title: "Password", layout:'auth-v2' }},
            { path: 'page-reset-password-v2', component: require('@/components/admin/authentication/ResetPasswordV2Component.vue').default,name: 'resetpwd_v2', meta: { title: "Reset Password", layout:'auth-v2' }},
            { path: 'page-otp-v2', component: require('@/components/admin/authentication/OtpV2Component.vue').default,name: 'otp_v2', meta: { title: "OTP", layout:'auth-v2' }},
            { path: 'page-passcode-v2', component: require('@/components/admin/authentication/PasscodeOtpV2Component.vue').default,name: 'passcode_v2', meta: { title: "Passcode OTP", layout:'auth-v2' }},
            { path: 'page-forgot-password-v2', component: require('@/components/admin/authentication/ForgotPasswordV2Component.vue').default,name: 'fpwd_v2', meta: { title: "Forgot Password", layout:'auth-v2' }},
        ]
    },

    //Stand-app Authantication
    {
        path: '/standapp-home',
        name: 'shome',
        component: require('@/components/admin/standapp/StandappHomeComponent.vue').default,
        meta: {title: "Standapp Home", layout:'standapp-v2'},
    },
    { path: '/standapp-login', name: 'slogin', component: require('@/components/admin/standapp/StandappLoginComponent.vue').default, meta: {title: "Standapp Login", layout:'standapp'}},
    { path: '/standapp-register', name: 'sregister', component: require('@/components/admin/standapp/StandappRegisterComponent.vue').default, meta: {title: "Standapp Register", layout:'standapp'}},
    { path: '/standapp-personaldetail', name: 'spdetail', component: require('@/components/admin/standapp/StandappPersonalDetailComponent.vue').default, meta: {title: "Standapp Personal Detail", layout:'standapp'}},
    { path: '/standapp-password', name: 'spassword', component: require('@/components/admin/standapp/StandappPasswordComponent.vue').default, meta: {title: "Standapp Password", layout:'standapp'}},
    { path: '/standapp-otp', name: 'sotp', component: require('@/components/admin/standapp/StandappOtpComponent.vue').default, meta: {title: "Standapp OTP", layout:'standapp'}},
    { path: '/standapp-passcode', name: 'spasscode', component: require('@/components/admin/standapp/StandappPasscodeOtpComponent.vue').default, meta: { title: "standapp Passcode OTP", layout:'standapp' }},
    {path: '/standapp-forgot-password', name: 'sforgotpassword', component: require('@/components/admin/standapp/StandappForgotPasswordComponent.vue').default, meta: {title: "Standapp Forgot Password", layout:'standapp'}},
    { path: '/standapp-reset-password', name: 'sresetpassword', component: require('@/components/admin/standapp/StandappResetPasswordComponent.vue').default, meta: {title: "Standapp Reset Password", layout:'standapp'}},
    { path: '/standapp-recover-password', name: 'srecoverpassword', component: require('@/components/admin/standapp/StandappRecoverPasswordComponent.vue').default, meta: {title: "Standapp Recover Password", layout:'standapp'}},
    { path: '/standapp-linked-business', name: 'slinkbusiness', component: require('@/components/admin/standapp/StandappLinkBusinessComponent.vue').default, meta: {title: "Standapp Linked Business", layout:'standapp'}},
    { path: '/standapp-set-password', name: 'ssetpassword', component: require('@/components/admin/standapp/StandappSetPasswordComponent.vue').default, meta: {title: "Standapp Set Password", layout:'standapp'}},

    
    {
        path: '/standapp-login-v2',
        name: 'slogin-v2',
        component: require('@/components/admin/standapp/StandappLoginV2Component.vue').default,
        meta: {title: "Standapp Login V2", layout:'standapp-v2'},
    },

    {
        path: '/payout-option',
        name: 'payout-option',
        component: require('@/components/admin/payout_option/PayoutOptionComponent.vue').default,
        meta: {breadCrumb: "Payout Option", title: "Payout Option"},
    },
    {
        path: '/payment-option',
        name: 'payment-option',
        component: require('@/components/admin/payment_option/PaymentOptionComponent.vue').default,
        meta: {breadCrumb: "Payment Option", title: "Payment Option"},
    },
    {
        path: '/fund',
        name: 'fund',
        component: require('@/components/admin/fund/FundComponent.vue').default,
        meta: {breadCrumb: "Fund", title: "Fund"},
    },
    {
        path: '/country',
        name: 'country',
        component: require('@/components/admin/country/CountryComponent.vue').default,
        meta: {breadCrumb: "Country",title: "Country"},
    },
    {
        path: '/country/details',
        name: 'country details',
        component: require('@/components/admin/country/CountryDetailComponent.vue').default,
        meta: {breadCrumb: "Country Details",title: "Country Details"},
    },

    {
        path: '/partner',
        name: 'partner',
        component: require('@/components/admin/partner/PartnerComponent.vue').default,
        meta: {breadCrumb: "Partner", title: "Partner"},
    },
    
    {
        path: '/sms',
        name: 'sms',
        component: require('@/components/admin/sms/SmsListComponent.vue').default,
        meta: {breadCrumb: "SMS", title: "SMS"},
    },


    //ui-kit routes
    {
        path: '/uikit',
        component: require('@/components/admin/uikit/RouterView.vue').default,
        meta: { breadCrumb: 'Ui Kit'},
        children: [
            { path: 'index', component: require('@/components/admin/uikit/IndexComponent.vue').default, meta: { breadCrumb: "Index", title: "Dashboard"}},
            { path: 'iot', component: require('@/components/admin/uikit/IotComponent.vue').default,meta: { breadCrumb: "Iot Dashboard", title: "Iot Dashboard"}},
            { path: "app-inbox",component: {render(c) {return c("router-view");}},
                meta: { breadCrumb: "Inbox"},
                children: [{ path: "",component:  require('@/components/admin/uikit/InboxComponent.vue').default, meta:{ title: "Inbox", },},
                        {path: "compose",component:  require('@/components/admin/uikit/ComposeComponent.vue').default,meta: { breadCrumb: "Compose", title: "Compose"}},
                ],
            },
            { path: 'app-chat', component: require('@/components/admin/uikit/ChatComponent.vue').default, meta: { breadCrumb: "Chat", title: "Chat", },},
            { path: 'app-calendar', component: require('@/components/admin/uikit/CalendarComponent.vue').default, meta: { breadCrumb: "Calendar", title: "Calendar"}},
            { path: 'app-contact-grid', component: require('@/components/admin/uikit/ContactGrideComponent.vue').default, meta: { breadCrumb: "Contact Card", title: "Contact Card"}},
            { path: 'file-documents', component: require('@/components/admin/uikit/DocumentsComponent.vue').default,name:'fdocuments', meta: { breadCrumb: "File Documents", title: "File Documents" },},
            { path: 'file-media', component: require('@/components/admin/uikit/FileMediaComponent.vue').default,name:'fmedia', meta: { breadCrumb: "File Media", title: "File Media" },},
            { path: 'sweet-alert', component: require('@/components/admin/uikit/SweetAlertComponent.vue').default,name:'sweetalert', meta: { breadCrumb: "Sweet Alert", title: "Sweet Alert" },},
            { path: 'file-images', component: require('@/components/admin/uikit/FileImageComponent.vue').default,name:'fimages', meta: { breadCrumb: "File Images", title: "File Images" },},
            { path: 'blog-post', component: require('@/components/admin/uikit/PostComponent.vue').default,name:'blog_post', meta: { breadCrumb: "Blog Post", title: "Blog Post" }},
            { path: 'blog-list', component: require('@/components/admin/uikit/ListComponent.vue').default,name:'blog_list', meta: { breadCrumb: "Blog List", title: "Blog List" }},
            { path: 'page-lockscreen', component: require('@/components/admin/uikit/LockscreenComponent.vue').default,name: 'lockscreen', meta: { title: "Lockscreen", layout:'auth' }},
            { path: 'page-404', component: require('@/components/admin/uikit/FourZeroFourComponent.vue').default,name: '404', meta: { title: "404 Page", layout:'auth' }},
            { path: 'page-403', component: require('@/components/admin/uikit/FourZeroThreeComponent.vue').default,name: '403', meta: { title: "403 Page", layout:'auth' }},
            { path: 'page-500', component: require('@/components/admin/uikit/FiveZeroZeroComponent.vue').default,name: '500', meta: { title: "500 Page", layout:'auth' }},
            { path: 'page-503', component: require('@/components/admin/uikit/FiveZeroThreeComponent.vue').default,name: '503', meta: { title: "503 Page", layout:'auth' }},
            { path: 'blog-details', component: require('@/components/admin/uikit/DetailsComponent.vue').default,name:'blog_details', meta: { breadCrumb: "Blog Details", title: "Blog Details" },},
            { path: 'ui-typography', component: require('@/components/admin/uikit/TypographyComponent.vue').default,name:'typography', meta: { breadCrumb: "Typography", title: "Typography" },},
            { path: 'ui-tabs', component: require('@/components/admin/uikit/TabsComponent.vue').default, meta: { breadCrumb: "Tabs", title: "Tabs" },},
            { path: 'ui-buttons', component: require('@/components/admin/uikit/ButtonsComponent.vue').default, meta: { breadCrumb: "Buttons", title: "Buttons" },},
            { path: 'ui-bootstrap', component: require('@/components/admin/uikit/BootstrapComponent.vue').default, meta: { breadCrumb: "Bootstrap UI", title: "Bootstrap UI" },},
            { path: 'ui-icons', component: require('@/components/admin/uikit/IconComponent.vue').default, meta: { breadCrumb: "Icons", title: "Icons" },},
            { path: 'ui-notifications', component: require('@/components/admin/uikit/NotificationComponent.vue').default, meta: { breadCrumb: "Notifications", title: "Notifications" },},
            { path: 'ui-colors', component: require('@/components/admin/uikit/ColorComponent.vue').default, meta: { breadCrumb: "Colors", title: "Colors" },},
            { path: 'ui-listgroup', component: require('@/components/admin/uikit/ListGroupComponent.vue').default, meta: { breadCrumb: "List Group", title: "List Group" },},
            { path: 'ui-media', component: require('@/components/admin/uikit/MediaComponent.vue').default, meta: { breadCrumb: "Media Object", title: "Media Object" },},
            { path: 'ui-modals', component:require('@/components/admin/uikit/ModalComponent.vue').default, meta: { breadCrumb: "Modals", title: "Modals" },},
            { path: 'ui-progressbars', component: require('@/components/admin/uikit/ProgressbarComponent.vue').default, meta: { breadCrumb: "Progressbar", title: "Progressbar" },},
            { path: 'widgets-data', component: require('@/components/admin/uikit/DataComponent.vue').default, meta: { breadCrumb: "Widgets Data",title: "Widgets Data" },},
            { path: 'widgets-weather', component: require('@/components/admin/uikit/WeatherComponent.vue').default, meta: { breadCrumb: "Widgets Weather",title: "Widgets Weather" },},
            { path: 'widgets-blog', component: require('@/components/admin/uikit/BlogComponent.vue').default, meta: { breadCrumb: "Widgets Blog",title: "Widgets Blog" },},
            { path: 'widgets-ecommerce', component: require('@/components/admin/uikit/EcommerceComponent.vue').default, meta: { breadCrumb: "Widgets eCommerce",title: "Widgets eCommerce" },},
            { path: 'page-blank', component: require('@/components/admin/uikit/BlankComponent.vue').default, meta: { breadCrumb: "Page Blank",title: "Page Blank" },},
            { path: 'page-profile', component: require('@/components/admin/uikit/ProfileComponent.vue').default, meta: { breadCrumb: "User Profile",title: "User Profile" },},
            { path: 'page-profile2', component: require('@/components/admin/uikit/ProfiletwoComponent.vue').default, meta: { breadCrumb: "User Profile",title: "User Profile v2" },},
            { path: 'page-gallery', component: require('@/components/admin/uikit/GalleryComponent.vue').default,name:'pgallery', meta: { breadCrumb: "Gallery",title: "Gallery" },},
            { path: 'page-timeline', component: require('@/components/admin/uikit/TimelineComponent.vue').default,name:'ptimeline', meta: { breadCrumb: "Timeline",title: "Timeline" },},
            //{ path: 'page-pricing', component: PricingComponent, meta: { breadCrumb: "Pricing",title: "Pricing" },},
            { path: 'page-invoices', component: require('@/components/admin/uikit/InvoicesComponent.vue').default, meta: { breadCrumb: "Invoices",title: "Invoices" },},
            { path: 'page-search-results', component: require('@/components/admin/uikit/SearchComponent.vue').default, meta: { breadCrumb: "Search Results",title: "Search Results" },},
            { path: 'page-helper-class', component: require('@/components/admin/uikit/HelperClassComponent.vue').default, meta: { breadCrumb: "Helper Class",title: "Helper Class" },},
            { path: 'page-teams-board', component: require('@/components/admin/uikit/TeamsBoardComponent.vue').default, meta: { breadCrumb: "Teams Board",title: "Teams Board" },},
            { path: 'page-projects-list', component: require('@/components/admin/uikit/ProjectsListComponent.vue').default, meta: { breadCrumb: "Projects List",title: "Projects List" },},
            { path: 'page-maintanance', component: require('@/components/admin/uikit/MaintananceComponent.vue').default,name: 'maintanance', meta: {title: "Maintanance" },},
            { path: 'page-testimonials', component: require('@/components/admin/uikit/TestimonialsComponent.vue').default, meta: { breadCrumb: "Testimonials",title: "Testimonials" },},
            { path: 'page-faq', component: require('@/components/admin/uikit/FaqComponent.vue').default, meta: { breadCrumb: "FAQs",title: "FAQs" },},
            { path: 'forms-validation', component: require('@/components/admin/uikit/ValidationComponent.vue').default, meta: { breadCrumb: "Form Validation",title: "Form Validation"  },},
            { path: 'forms-basic', component: require('@/components/admin/uikit/BasicFormComponent.vue').default, meta: { breadCrumb: "Basic Form Elements",title: "Basic Form Elements"  },},
            { path: 'table-normal', component: require('@/components/admin/uikit/NormalComponent.vue').default, meta: { breadCrumb: "Table Normal",title: "Table Normal" },},
            { path: 'chart-echarts', component: require('@/components/admin/uikit/EChartsComponent.vue').default, meta: { breadCrumb: "E-Charts",title: "E-Charts" },},
            { path: 'map-leaflet', component: require('@/components/admin/uikit/LeafletComponent.vue').default, meta: { breadCrumb: "Leaflet Map",title: "Leaflet Map" },},
        ]
    },

    {
        path: '*',
        redirect: '/standapp-home'
    }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  base: ''
})

router.afterEach((to) => {
    Vue.nextTick( () => {
        document.title = ':: PayAngel :: '+ to.meta.title;
    });
})

Vue.config.productionTip = false
new Vue({
    router: router,
    store,
    render: h => h(App),
}).$mount('#app')