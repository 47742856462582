<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
export default {
    name:'AirtimeComponent',
    components: {
        BreadCrumb,
    },data (){
        return{
        
        }
    },methods: {
       
    },mounted: function(){
        
    },created: function (){
        
    }
}

</script>