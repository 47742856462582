<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card card-top-line">
                    <div class="body">
                        <form id="invoice_type_form" @submit.prevent="invoiceSubmit">
                            <div class="row clearfix">
                                <div class="col-md-6 col-sm-12">
                                    <address>
                                        <strong>ThemeMakker Inc.</strong><br>
                                        795 Folsom Ave, Suite 546<br>
                                        San Francisco, CA 54656<br>
                                        United States<br>
                                        <strong title="Phone mt-1">Phone:</strong> (123) 456-34636<br>
                                        <strong title="Email">Email:</strong> jones@payangel.com
                                    </address>
                                </div>
                                <div class="col-md-5 col-sm-9 text-right">
                                    <p class="m-b-0"><strong>Order Date: </strong> Jun 15, 2018</p>
                                    <p class="m-b-0"><strong>Order Status: </strong> <span class="badge badge-warning m-b-0">Pending</span></p>
                                    <p><strong>Order ID: </strong> #123456</p>
                                </div>
                                <div class="col-md-1 col-sm-3 text-right">
                                    <qrcode-vue :value="value" :size="size" level="H" />
                                </div>
                            </div>
                            <b-table-simple responsive class="mb-0 mt-4" table-class="border">
                                <b-thead class="thead-light">
                                    <b-tr>
                                        <b-th>#</b-th>
                                        <b-th>Name</b-th>
                                        <b-th>Description</b-th>
                                        <b-th class="text-center">Quantity</b-th>
                                        <b-th class="text-center">Price</b-th>
                                        <b-th class="text-center">Total</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="(invoice,index) in invoicesRows" :key="index">
                                        <b-td><i class="fa fa-times text-danger" @click="removeRow(index)"></i></b-td>
                                        <b-td><input type="text" class="form-control name" v-model="invoice.name"></b-td>
                                        <b-td><input type="text" class="form-control details" v-model="invoice.description"></b-td>
                                        <b-td align="right"><input type="text" class="form-control qty" v-model="invoice.qty"></b-td>
                                        <b-td align="right"><input type="text" class="form-control rates" v-model="invoice.price"></b-td>
                                        <b-td align="right"><input type="text" class="form-control total" v-model.number="subtotalRow[index]" readonly></b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- invoice-calc -->
                            <div class="invoice-calc">
                                <div class="d-flex justify-content-between flex-column flex-md-row">
                                    <div class="add-row-btn">
                                        <b-button variant="primary btn-mw" size="lg" @click="addNewRow">Add new row</b-button>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row clearfix">
                                <div class="col-md-6">
                                    <h5>Note</h5>
                                    <p>Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles, weebly ning heekya handango imeem plugg dopplr jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.</p>
                                </div>
                                <div class="offset-md-3 col-md-3">
                                    <ul class="list-unstyled mb-0">
                                            <li class="d-flex justify-content-between align-items-center mb-2">
                                                <label class="font-weight-bold mb-0">Sub-total:</label>
                                                <p class="mb-0">{{ total }}</p>
                                            </li>
                                            <li class="d-flex justify-content-between align-items-center mb-2">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 font-weight-bold mb-0">Tax:</label>
                                                    <span class="font-weight-bold">10%</span>
                                                </div>
                                                <div>
                                                    <p class="mb-0">
                                                        {{ grandTotal.taxAmount }}
                                                    </p>
                                                </div>
                                            </li>
                                            <li class="d-flex justify-content-between align-items-center mb-2">
                                                <label class="font-weight-bold mb-0">VAT:</label>
                                                <p class="mb-0">{{ invoice.vat }}</p>
                                            </li>
                                            <li class="d-flex justify-content-between align-items-center mb-2">
                                                <label class="font-weight-bold mb-0">Discount:</label>
                                                <p class="mb-0">{{ invoice.discount }}</p>
                                            </li>
                                            <li class="d-flex justify-content-between align-items-center mb-2">
                                                <label class="font-weight-bold mb-0">Grand Total:</label>
                                                <p class="mb-0">{{ grandTotal.grandtotal }}</p>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                            <div class="hidden-print d-flex flex-column flex-md-row justify-content-end mt-3">
                                <router-link to="/invoice/customer-list" class="btn btn-secondary btn-lg btn-mw mr-md-1 my-2 my-md-0">Cancel</router-link>                                    
                                <b-button type="submit" variant="primary btn-mw" size="lg">Preview</b-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
import QrcodeVue from 'qrcode.vue'
export default {
    name:'AddInvoiceItemComponent',
    components: {
        BreadCrumb,
        QrcodeVue
    },data() {
        return {
            invoicesRows: [
                {name:"", description: "",qty: null ,price: null,total: null},
            ],

            invoice:{
               taxSelected: 10,      
               vat: 0,
               discount:0                                           
            },
            
            invoiceInformations:[
                {key:'Business Name',value:'ThemeMakker Inc.'},
                {key:'Email',value:'thememakker@gmail.com'},
                {key:'Phone',value:'289-335-6503'},
                {key:'country',value:'United Kingdom'},
                {key:'First Name',value:'Michael'},
                {key:'Last Name',value:'Truong'},
                {key:'Street',value:'High Street'},
                {key:'Town/City',value:'Bristol'},
                {key:'Note',value:'An invoice is a time-stamped commercial document that itemizes and records a transaction between a buyer and a seller.'},
            ],

            value: 'https://example.com',
            size: 60,
        }
    },methods:{
        addNewRow: function(){
            this.invoicesRows.push({name:"", description:"",qty:"",price:"",total:""});
        },

        removeRow: function(index){
            this.invoicesRows.splice(index, 1);
        },

        invoiceSubmit(){
            this.$router.push({path:'/invoice/detail', query:{ type: this.$route.query.type }});
        }
    },computed: {
        subtotalRow() {
          return this.invoicesRows.map((item) => {
            return Number(item.qty * item.price)
          });
        },

        total() {
            return this.invoicesRows.reduce((total, item) => {
                return total + item.qty * item.price;
            }, 0);
        },

        grandTotal() {
            var total = this.total;
            var selectedTax = this.invoice.taxSelected;
            var taxAmount = total/100 * selectedTax;
            var grandtotal = taxAmount + this.total;
            return{
                taxAmount,
                grandtotal
            }
        }
    }
}
</script>