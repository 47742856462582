<template>
    <section class="main-content">
        <div class="banner-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-10">
                        <div class="card register-form-card">
                            <ValidationObserver v-slot="{ passes }">
                                <div class="header pb-1 text-center">
                                    <p class="lead px-3">Hello, my name is PayAngel. What's yours</p>
                                </div>
                                <div class="body">
                                    <p class="text-center mb-4">Enter your name as it appears on your official ID</p>
                                    <form class="form-auth-small" @submit.prevent="passes(registerSubmit)" id="registration" ref="registration">
                                        <div class="row">
                                            <!-- Country -->
                                            <div class="col-12">
                                                <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                                    <div class="country-dropdown">
                                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':registerformdata.country && registerformdata.country.length != 0  && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="Country">Select Country*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="registerformdata.country" 
                                                                    :show-labels="false" 
                                                                    :options="countrys_options" 
                                                                    :searchable="false" 
                                                                    label="name" 
                                                                    track-by="name">
                                                                    <template slot="option" slot-scope="props">
                                                                        <span :class="props.option.flagClass"></span>
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ props.option.name }}</span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect> 
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="registerformdata.country && registerformdata.country.length != 0 && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <!-- Business Name -->
                                            <div class="col-12">
                                                <ValidationProvider name="Business Name" rules="required" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !registerformdata.businessname,'success':registerformdata.businessname}">
                                                        <span class="float-label">
                                                            <input type="text" id="business_name" v-model="registerformdata.businessname" class="form-control" placeholder="Business Name*">
                                                            <label for="business_name" class="control-label">Business Name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !registerformdata.businessname"></i>
                                                            <i class="fa fa-check" v-if="registerformdata.businessname"></i>
                                                        </span>
                                                        <span class="text-danger error-msg" v-if="errors[0]">Please provide unique business name</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <!-- Country Code and Mobile Number -->
                                            <div class="col-12">
                                                <div class="d-flex code-mobile">
                                                    <div class="mr-2 left">
                                                        <ValidationProvider name="countryCode" rules="required" v-slot="{ errors }">
                                                            <div class="form-group" :class="{'errors': errors[0],'success':registerformdata.countryCode && !errors[0]}">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="countryCode" v-model="registerformdata.countryCode" placeholder="Country Code*">
                                                                    <label for="countryCode" class="control-label">Country Code*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                    <i class="fa fa-check" v-if="registerformdata.countryCode && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="ml-2 right">
                                                        <ValidationProvider name="mobile_number" rules="required" v-slot="{ errors }">
                                                            <div class="form-group" :class="{'errors': errors[0],'success':registerformdata.mobile_number && !errors[0]}">
                                                                <span class="float-label">
                                                                    <input type="text" class="form-control" id="mobile_number" v-model="registerformdata.mobile_number" placeholder="Contact Mobile Number*">
                                                                    <label for="mobile_number" class="control-label">Contact Mobile Number*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                    <i class="fa fa-check" v-if="registerformdata.mobile_number && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Email -->
                                            <div class="col-12">
                                                <ValidationProvider name="contact_email" rules="required|email" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !registerformdata.contact_email || errors[0],'success':registerformdata.contact_email && !errors[0]}">
                                                        <span class="float-label">
                                                            <input type="email" v-model="registerformdata.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Contact Email Address*" @blur="sweetAlertForEmail">
                                                            <label for="contact_email" class="control-label">Contact Email Address*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !registerformdata.contact_email || errors[0] "></i>
                                                            <i class="fa fa-check" v-if="registerformdata.contact_email && !errors[0]"></i>
                                                        </span>
                                                        <span v-if="registerformdata.displayConfirmPopup = registerformdata.contact_email && !errors[0]"/>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <!-- Email Yes/No -->
                                            <div class="col-12" v-if="registerformdata.displaylinkedEmail">
                                                <ValidationProvider name="profile_password" :rules="registerformdata.displaylinkedEmail ? 'required' : ''" v-slot="{ errors}" v-if="registerformdata.displaylinkedEmail">
                                                    <div class="head">
                                                        <label><strong>Enter the password for the existing profile</strong></label>
                                                    </div>
                                                    <div class="form-group set-password" :class="{'errors': errors.length && !registerformdata.profile_password,'success':registerformdata.profile_password}">
                                                        <span class="float-label">
                                                            <input :type="registerformdata.password_type" v-model="registerformdata.profile_password" class="form-control" name="profile_password" id="profile_password" placeholder="Password*">
                                                            <label for="profile_password" class="control-label">Password*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !registerformdata.profile_password"></i>
                                                            <i class="fa fa-check" v-if="registerformdata.profile_password"></i>
                                                            <i class="fa fa-eye" :class="registerformdata.password_type == 'password' ? 'grey' : 'success'" aria-hidden="true" @click="showPassword()"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            
                                            <!-- Referral Code -->
                                            <div class="col-12">
                                                <div class="d-flex justify-content-between align-items-center m-b-20">
                                                    <p class="mb-0">Do you have a referral code?</p>
                                                    <div class="custom-switch success-error-bg">
                                                        <input type="checkbox" class="custom-switch-input" id="customSwitch1" v-model="registerformdata.is_referral">
                                                        <label class="custom-switch-label" for="customSwitch1">
                                                            <span class="custom-switch-text1">Yes</span>
                                                            <span class="custom-switch-text2">No</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ValidationProvider name="referral_value" rules="required" v-slot="{ errors}" v-if="registerformdata.is_referral">
                                                    <div class="form-group" :class="{'errors': errors.length && !registerformdata.is_referral_value,'success':registerformdata.is_referral_value}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="registerformdata.is_referral_value" class="form-control" id="referral_code" placeholder="Enter Referral Code">
                                                            <label for="referral_code" class="control-label">Code</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !registerformdata.is_referral_value"></i>
                                                            <i class="fa fa-check" v-if="registerformdata.is_referral_value"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>

                                            <div class="col-12">
                                                <div class="form-group clearfix">
                                                    <label class="fancy-checkbox new-fancy">
                                                        <input type="checkbox" @click="checkIsChecked($event)">
                                                        <span>Check here to indicate that you have read and agree to the <strong>Terms and Conditions</strong></span>
                                                    </label>
                                                    <span class="text-danger">{{  registerformdata.errorMsg }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <b-button type="submit" block variant="primary" size="lg">CONTINUE</b-button>
                                        <div class="bottom">
                                            <span>Have an account? <router-link to="/authentication/page-login">Login</router-link></span>
                                        </div>
                                    </form>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="body text-center pb-4">
                            <img :src="welcome_messages.standApp_image" class="banner-img-powered" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <img :src="welcome_messages.banner_image" class="banner-img" alt=""> -->
        </div>
    </section>
</template>

<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
import Multiselect from 'vue-multiselect'
export default {
    name:'RegisterComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect
    },data(){
        return{
            isAllChecked:false,
            show_banner: false,
            welcome_messages:{
                heading:'Welcome to ONE WORLD',
                sub_heading:'The one Account you need for',
                banner_li: [
                    '<li><p class="mb-0 d-flex align-items-center"><span>All your payments</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All your collections</p></li> <li><p class="mb-0 d-flex align-items-center"> <span>Your invoicing</span></p></li> <li><p class="mb-0 d-flex align-items-center"> <span>All the ways your customers pay</span></p></li>'
                ],
                button_text:'Sign up for free in 3 minutes',
                banner_image: require(`@/assets/home_image/banner.svg`),
                standApp_image:  require(`@/assets/home_image/Powered_by_StandApp.svg`),
            },
            
            registerformdata: {
                is_referral_value:'',
                is_referral:false,
                linkedEmail:false,
                displayConfirmPopup: false,
                displaylinkedEmail:false,
                profile_password:'',
                country: '',
                contact_email:'',
                countryCode:'',
                mobile_number:'',
                businessname: '',
                robot: false,
                password_type: 'password',
                IsChecked: false,
                errorMsg:''
            },

            countrys_options: [
                { id:0,name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round",isChecked:false},
                { id:1,name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",isChecked:false},
                { id:2,name: 'france' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round",isChecked:false},
                { id:3,name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round",isChecked:false},
            ],
        }
    },methods: {
        registerSubmit() {
            if(this.registerformdata.profile_password){
                if(this.registerformdata.IsChecked){
                    this.$router.push({ path: '/authentication/page-passcode-v2' })
                }else{
                    return this.registerformdata.errorMsg = 'Please select checkbox to continue.'
                }
            }else{
                if(this.registerformdata.IsChecked){
                    this.$router.push({ path: '/authentication/page-personaldetail-v2' })
                }else{
                    return this.registerformdata.errorMsg = 'Please select checkbox to continue.'
                }
            }
        },

        onChange: function(event) { 
           this.selected_country_code = event.target[event.target.selectedIndex].getAttribute('data-value')
        },
        
        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },1000)
        },

        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.registerformdata.country[0].name
            }else if(count == this.countrys_options.length){
                this.isAllChecked = true;
                return count + ' Countrys Selected.'
            }else {
                this.isAllChecked = false;
                return  count + ' Countrys Selected.'
            }
        },

        selectAllChanged() {
            if (this.isAllSelected) {
                this.isAllChecked = false;
                this.registerformdata.country = [];
                this.countrys_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.isAllChecked = true;
                this.registerformdata.country = this.countrys_options.slice();
                this.countrys_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        isCheckedInput (actionName) {
            this.countrys_options[actionName.id].isChecked = !this.countrys_options[actionName.id].isChecked
        },

        //visible password text
        showPassword() {
            this.registerformdata.password_type = this.registerformdata.password_type === 'password' ? 'text' : 'password';
        },

        sweetAlertForEmail(){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success mr-1 btn-lg',
                    cancelButton: 'btn btn-danger btn-lg'
                },
                buttonsStyling: false
            })
            if(this.registerformdata.displayConfirmPopup){
                swalWithBootstrapButtons.fire({
                    text: "Do you want to continue the registration for this business with the same email address ?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.registerformdata.displaylinkedEmail = true
                        }else{
                            this.registerformdata.displaylinkedEmail = false
                        } 
                })
            }
        },

        //check Arrow Certification checkbox cheked
        checkIsChecked(event){
            this.registerformdata.IsChecked = event.target.checked
            if(this.registerformdata.IsChecked){
                return this.registerformdata.errorMsg = ''
            }
        }
    },mounted () {
        this.onLoad();
    },computed: {
        isAllSelected: function() {
            return  (this.registerformdata.country.length === this.countrys_options.length)
        }
    }
}
</script>
<style>

</style>