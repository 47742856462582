<template>
  <div class="container-fluid">
    <!-- Bread-crumb-->
    <div class="block-header">
      <bread-crumb></bread-crumb>
    </div>
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card card-top-line">
          <!-- Header -->
          <div class="header">
            <h2>Transaction List</h2>
          </div>
          <!-- Transaction Listing-->
          <div class="body pt-0">
            <!-- Filters/Buttons -->
            <div class="chart-top-action d-lg-flex justify-content-end py-3">
              <!-- SearchBox Filters -->
              <div class="mr-0 mr-lg-3 action-top-search">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search transactions" aria-label="Search transactions" aria-describedby="search-transactions" v-model="searchText" v-on:keyup.enter="refreshTransactionTable">
                  <div class="input-group-append" @click="refreshTransactionTable()" >
                    <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                  </div>
                </div>
              </div>
              <div class="fliter-main mr-auto mt-3 mt-lg-0">
                <!-- <b-button class="fliter-btn btn-lg btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                    <div class="d-flex justify-content-between p-3">
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input One"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Two"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Three"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-primary h-100" @click="isVisibleFilter = false">Apply</button>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false">Cancel</button>
                                        </div>
                                    </div>
                                </b-collapse> -->
              </div>
              <div class="date-range-main mt-3 mt-lg-0">
                <template>
                  <v-md-date-range-picker
                    :opens="opens"
                    :presets="presets"
                    :autoApply="autoApplyDateRange"
                  ></v-md-date-range-picker>
                </template>
              </div>
              <div class="payment-dropdown d-flex flex-lg-row-reverse flex-column ml-lg-3 ml-0 mt-3 mt-lg-0">
                <div class="country-dropdown curancy-dropdown mb-0">
                      <div class="form-group form-select multiselect-grp mb-0">
                        <span class="float-label">
                          <label for="roles">Select Tradename</label>
                          <multiselect
                              placeholder="Select Tradename"
                              v-model="tradename" 
                              :show-labels="false" 
                              :options="tradename_options" 
                              :searchable="false"
                              label="name"
                              @select="setprovider">
                          </multiselect>  
                        </span>
                      </div>
                  </div>
              </div>
            </div>
            <!-- All Transaction List -->
            <b-table
              ref="transactionTable"
              responsive
              table-class="mb-0 table-custom border"
              head-variant="light"
              :items="getTransactionList"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template #head()="{ label, field: { key } }">
                {{ label }}
                <!-- Custom icons -->
                <template>
                  <i v-if="sortBy !== key" class=""></i>
                  <i
                    v-else-if="sortDesc"
                    class="fa fa-long-arrow-down ml-1"
                  ></i>
                  <i v-else class="fa fa-long-arrow-up ml-1"></i>
                </template>
              </template>
              <template #cell(srccountry)="data">
                <div class="align-items-center d-flex">
                  <span :class="data.value.flagClass" class="mr-2"></span>
                </div>
              </template>
              <template #cell(sender)="data">
                <span :title="data.value.name"
                  >{{ data.value.name.substring(0, 19)
                  }}<span v-if="data.value.name.length > 19">...</span></span
                >
                <!-- <strong>
                                    {{ data.value.name.substring(0, 19) }}<span v-if="data.value.name.length > 19">...</span>
                                </strong> -->
              </template>
              <template #cell(recver)="data">
                <span :title="data.value.name"
                  >{{ data.value.name.substring(0, 19)
                  }}<span v-if="data.value.name.length > 19">...</span></span
                >
              </template>
              <template #cell(refno)="data">
                <span :title="data.value.ref" :data-original-title="data.value.ref" @click.prevent="copyTooltip($event)">
                  <!-- <i :class="data.value.class" class="mr-1"></i> -->
                  {{ data.value.ref.substring(0, 19) }}<span v-if="data.value.ref.length > 19">...</span>
                </span>
              </template>
              <template #cell(datetime)="data">
                <strong>{{ data.value.date }}</strong>
                <small class="text-grey d-block">{{ data.value.time }}</small>
              </template>
              <template #cell(accno)="data">
                <div class="align-items-center d-flex">
                  <span
                    ><i :class="data.value.type" class="mr-1"></i>
                    <strong :title="data.value.acc"
                      >{{ data.value.acc.substring(0, 15)
                      }}<span v-if="data.value.acc.length > 15"
                        >...</span
                      ></strong
                    ><br /><span class="text-grey d-block">{{
                      data.value.no
                    }}</span></span
                  >
                </div>
              </template>
              <template #cell(destamount)="data">
                <div class="d-flex align-items-start justify-content-end amount-arrow" >
                  <p class="mb-0 text-right">
                    <span>{{ data.value.amount }}</span>
                  </p>
                </div>
              </template>
              <!-- <template #cell(status)="data">
                                <p class="function-badge badge-group-table mb-0 text-center">
                                    <span class="badge ml-0 d-block" :class="data.value.class">{{ data.value.name }}</span>
                                </p>
                            </template> -->
              <template #cell(action)="data">
                <a
                  href="javascript:void(0);"
                  class="btn btn-outline-secondary mr-1" @click="showdetails(data)"
                  ><i class="icon-eye"></i
                ></a>
              </template>
            </b-table>
            <!-- Table Pagination -->
            <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
              <div class="per-page">
                  <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                      <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                  </b-form-group>
              </div>
              <div class="number-page">
                  <b-pagination 
                      v-model="currentPage" 
                      :total-rows="totalRows" 
                      :per-page="perPage"
                      hide-goto-end-buttons 
                      hide-ellipsis 
                      prev-text="Previous page" 
                      next-text="Next page" 
                      align="right">
                  </b-pagination>
              </div>
              <div class="go-page">
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">Go To Page</span>
                      </div>
                      <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main-loader ref="loader"></main-loader>
  </div>
</template>
<script>
import "@/plugins/sweetalert2";
import "@/plugins/vee-validate";
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
import Multiselect from 'vue-multiselect';
const moment = require('moment')
const { VMdDateRangePicker } = require("v-md-date-range-picker")
import shared from "@/shared.js";
export default {
  name: "TransactionListComponent",
  components: {
    BreadCrumb: () => import("@/components/BreadCrumb.vue"),
    "main-loader": LoaderComponent,
    'v-md-date-range-picker':VMdDateRangePicker,
    // Datepicker: () => import('vuejs-datepicker'),
    Multiselect
  },
  methods: {
    onEnter(e) {
      var paginationMenu = Math.ceil(this.totalRows / this.perPage);
      if (e.target.value > 0) {
        if (paginationMenu >= e.target.value) {
          this.currentPage = e.target.value;
        }
      }
    },

    copyTooltip: function(e){
        var tooltipText = e.currentTarget.getAttribute('data-original-title');
        const inputCopyText = document.createElement('input')
        inputCopyText.value = tooltipText
        document.body.appendChild(inputCopyText)
        inputCopyText.select()
        document.execCommand('copy')
        document.body.removeChild(inputCopyText)
    },

    // showEdit(data){
    //     console.log(data);
    //     this.$router.push({ path: '/customer/edit' })
    // }

    async getTransactionList(ctx) {
      // this.getTradename();
      // console.log(moment('2022-06-16 07:57:29').format('DD-MM-YYYY'));
      try {
        // if(this.tradenameid){
          this.$refs.loader.show();
          let page = "";
          let size = "";
          let sortby = "";
          let sort = false;
          if (ctx.currentPage != undefined) {
            page = ctx.currentPage;
          } else {
            page = 1;
          }
          if (ctx.perPage != undefined) {
            size = ctx.perPage;
          } else {
            size = 10;
          }
          if (ctx.sortBy == "") {
            sortby = "createdAt";
          } else {
            sortby = ctx.sortBy;
          }
          if (ctx.sortDesc == false) {
            sort = "DESC";
          } else {
            sort = "ASC";
          }

          this.currentPageJump = page;
          this.items = [];
          let paramData = {
            sorting: sort,
            sortby: sortby,
            pagesize: size,
            pagenumber: page,
            // search: this.searchText,
            // tradenameid: this.tradenameid ? this.tradenameid : ''
          };
          if(this.searchText){
            paramData.search = this.searchText
          }
          if(this.tradenameid){
            paramData.tradenameid = this.tradenameid
          }
          // console.log(paramData);
          const response = await this.$http.get(
            this.$basepythonurl+"payment/transactions-list/",
            {
              params: paramData,
              // headers: {
              //     'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
              // }
            }
          );
          // JSON responses are automatically parsed.
          if (response.data.code == "0130-000") {
            response.data.data.map((item) => {
              let accType = "";
              let accname = "";
              let accNumber = "";
              if (item.transferType == "Mobile") {
                accType = "fa fa-mobile fa-lg";
                accname = item.mobileNetwork;
                accNumber = item.mobileNumber;
              } else {
                accType = "fa fa-bank fa-lg";
                accname = item.bankName;
                accNumber = item.bankAccountNumber;
              }

              let transactionLists = {
                transactionid: item.transactionid,
                srccountry: { flagClass: "flag-icon flag-icon-" + item.countryFrom.toLowerCase() + " fi-md fi-round", },
                datetime: { date: moment(item.transactionDate).format('DD-MM-YYYY'), time: moment(item.transactionDate).format('h:mm A') },
                sender: { name: item.senderFirstName + " " + item.senderLastName, },
                recver: { name: item.beneficiaryFirstName + " " + item.beneficiaryLastName, },
                accno: { type: accType, acc: accname, no: accNumber },
                refno: { ref: item.transactionid }, //, class:"fa fa-globe"
                destamount: { amount: item.receivingCurrency + "" + item.destinationAmount, },
                provider: item.provider.name,
                action: "",
              };
              this.items.push(transactionLists);
            });
            this.totalRows = response.data.query.total;
            this.$refs.loader.hide();
            return this.items;
          }
        // }
      } catch (error) {
        console.log(error);
        this.$refs.loader.hide();
      }
    },

    refreshTransactionTable() {
      this.$refs.transactionTable.refresh();
    },

    async getTradename(){
      // console.log($pythonurl);
      try{
        this.tradename_options = [];
        let paramData = {
          pagesize: 200,
          pagenumber: 1
        };
        // basepythonurl
        const response = await this.$http.get(
          this.$basepythonurl+"payment/trade-name-list/",
          {
            params: paramData
          }
        );
        if (response.data.code == "0130-000") {
          response.data.data.map((item) => {
            let tradeLists = {
              id : item.tradenameid,
              name : item.channel_name
            };
            this.tradename_options.push(tradeLists);
          });
          // this.tradename = {id:this.tradename_options[0].id, name: this.tradename_options[0].name};
          // this.tradenameid = this.tradename_options[0].id;
          // this.refreshTransactionTable();
        }
      } catch (error) {
        console.log(error);
        this.$refs.loader.hide();
      }
    },
      
    setprovider(data){
      // console.log(data);
      this.tradenameid = data.id;
      this.refreshTransactionTable();
    },

    showdetails(data){
      // console.log(data.item);
      sessionStorage.setItem('transactionID',data.item.transactionid)
      this.$router.push({ path: '/transaction/details' })
    }
  },
  data() {
    return {
      items: [],

      fields: [
        { key: "srccountry", label: "S.C.", sortable: true, thClass: "th_sortfix", },
        { key: "datetime", label: "Date", sortable: true, thClass: "th_sortfix", },
        { key: "sender", label: "Sender", sortable: true, thClass: "th_sortfix", },
        { key: "recver", label: "Receiver", sortable: true, thClass: "th_sortfix", },
        { key: "accno", label: "Account No.", sortable: true, thClass: "th_sortfix", },
        { key: "refno", label: "TransID", sortable: true, thClass: "th_sortfix", },
        { key: "destamount", label: "Dest Amount", sortable: true, thClass: "th_sortfix text-right", },
        // { key: 'destamount', label: 'Dest Amount',sortable: true,thClass:'th_sortfix text-right'},
        // { key: 'status', label: 'Trans. Status',sortable: true,thClass:'th_sortfix'},
        { key: 'provider', label: 'Provider',sortable: true,thClass:'th_sortfix'},
        { key: "action", label: "Action", thClass: "th_sortfix" },
        { sortable: true, sortByFormatted: true, filterByFormatted: true },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 150, 200, { value: 1000, text: 1000 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      searchText: '',
      // newMemberUrl: window.location.origin+'/members-form/members-form.html',
      uploadMember: {
        csvExcelFile: null,
      },

      isVisibleFilter: false,

      search: {
        from: "",
        to: "",
        tstatus: "",
        type: "",
        companyname: "",
        keywords: "",
      },

      transactionStatus: [
        "Transaction Incomplete",
        "Awaiting Funds",
        "Pending Authorisation",
        "Created",
        "Sent for Payment",
        "Beneficiary Paid",
        "Deleted or Cancelled",
        "Aborted",
        "Card Declined",
      ],
      typeoption: ["Customer", "Partner"],

      tradename: {},
      tradename_options: [],
      tradenameid:'',

      autoApplyDateRange: false,
      opens: 'right',
      presets: [
          {
              label: 'Today',
              range: [
                  moment().startOf('day'),
                  moment().endOf('day')
              ]
          },
          {
              label: 'Yesterday',
              range: [
                  moment().subtract(1, 'day').startOf('day'),
                  moment().subtract(1, 'day').endOf('day')
              ]
          },
          {
              label: 'Last 7 Days',
              range: [
                  moment().subtract(7, 'day'),
                  moment()
              ]
          },
          {
              label: 'This Week',
              range: [
                  moment().startOf('week'),
                  moment().endOf('week')
              ]
          },
          {
              label: 'Last Week',
              range: [
                  moment().subtract(1, 'week').startOf('week'),
                  moment().subtract(1, 'week').endOf('week'),
              ]
          },
          {
              label: 'Last 30 Days',
              range: [
                  moment().subtract(30, 'day'),
                  moment()
              ]
          },
          {
              label: 'This Month',
              range: [
                  moment().startOf('month'),
                  moment().endOf('month')
              ]
          },
          {
              label: 'Last Month',
              range: [
                  moment().subtract(1,'months').startOf('month'),
                  moment().subtract(1,'months').endOf('month')
              ]
          },
          {
              label: 'This Year',
              range: [
                  moment().startOf('year'),
                  moment().endOf('year')
              ]
          },
          {
              label: 'Last Year',
              range: [
                  moment().subtract(1,'year').startOf('year'),
                  moment().subtract(1,'year').endOf('year'),
              ]
          }
      ],
    };
  },
  mounted() {
    if (sessionStorage.getItem("jwtToken")) {
      this.getTradename();
      this.totalRows = this.items.length;
    } else {
      shared.toastrError("Please login to continue");
      this.$router.push({ path: "/standapp-login" });
    }
  },
};
</script>
<style>
text-grey {
  color: #8a8a8a !important;
}
</style>
