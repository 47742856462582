<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb :breadCrumbIteams="breadCrumbIteams"></bread-crumb>
        </div>

        <!-- Business List-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card p-3 card-top-line">
                    <!-- Header section-->
                    <div class="header p-0 d-flex align-items-center justify-content-between">
                        <h2>All CMS</h2>
                        <div class="btn-grp d-flex">
                            <router-link to="/cms/add-page" class="btn btn-primary btn-lg btn-mw">Add Page <i class="fa fa-plus-square ml-1"></i></router-link>
                        </div>
                    </div>

                    <div class="body px-0">
                        <div class="tree-view">
                            <vue-tree-list :model="data">
                                <template v-slot:leafNameDisplay="slotProps">
                                    <span v-if="slotProps.model.id != 1">
                                        {{ slotProps.model.name }} <span class="cms-edit action"><i class="fa fa-pencil-square-o"></i></span> <span class="cms-delete action" @click="showAlert"><i class="fa fa-trash"></i></span>
                                    </span>

                                    <span v-if="slotProps.model.id == 1">{{ slotProps.model.name }} </span>
                                </template>
                            </vue-tree-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { VueTreeList ,Tree } = require('vue-tree-list')
import '@/plugins/sweetalert2'
export default {
    name:'BusinessComponent',
    components: {
        BreadCrumb,
        VueTreeList
    },data (){
        return{
            //All Acounts list table pagination options
            breadCrumbIteams:[
                {
                    title:'CMS List',
                    links:''
                }
            ],
    
            data: new Tree( [
                {
                    name: 'CMS',
                    id: 1,
                    pid: 0,
                    addTreeNodeDisabled: true,
                    addLeafNodeDisabled: true,
                    editNodeDisabled: true,
                    delNodeDisabled: true,
                    children: [
                        {
                            name: 'Terms & Conditions',
                            id: 2,
                            isLeaf: true,
                            pid: 1,
                            editNodeDisabled: true,
                            delNodeDisabled: true,
                        },
                        {
                            name: 'Company Overview',
                            id: 3,
                            isLeaf: true,
                            pid: 2,
                            editNodeDisabled: true,
                            delNodeDisabled: true,
                            children: [
                                {
                                    name: 'Terms & Conditions',
                                    id: 4,
                                    isLeaf: true,
                                    pid: 3,
                                    editNodeDisabled: true,
                                    delNodeDisabled: true,
                                },
                                {
                                    name: 'Company Overview',
                                    id: 5,
                                    isLeaf: true,
                                    pid: 4,
                                    editNodeDisabled: true,
                                    delNodeDisabled: true,
                                }
                            ]
                        }
                    ]
                }
            ])
        }
    },methods: {
        showAlert() {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }
            })
        },
    },mounted() {
        
    },computed: {
        
    }
}

</script>

