<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="card card-top-line no-shadow">
                    <div class="body">
                        <div class="appliances-grp ng-star-inserted">
                            <div class="d-flex align-items-center transfers-list">
                                <div class="right">
                                    <strong class="font-16">{{ user_details.name }}</strong>
                                    <p>{{ user_details.curancy }} - {{ user_details.price }} - {{ user_details.date }}</p>
                                </div>
                                <div class="left ml-auto">
                                    <i aria-hidden="true" class="fa mr-0">{{ user_details.name | short_hand }}</i>
                                </div>
                            </div>
                        </div>
                        <div class="user-account-main">
                            <div class="user-account-payment">
                                <ul class="list-unstyled mb-0 user-account-details">
                                    <b-form @submit.prevent="userDetails">
                                        <li class="my-3 mt-4">
                                            <div class="d-md-flex align-items-center">
                                                <label class="mb-0 mr-5">GBP</label>
                                                <div class="right ml-auto">
                                                    <b-form-group class="mb-0" label-for="input-sm">
                                                        <div class="d-flex">
                                                            <div class="form-group form-select multiselect-grp mb-0">
                                                                <span class="float-label">
                                                                    <multiselect
                                                                        v-model="user.curancy" 
                                                                        :show-labels="false" 
                                                                        :options="curancys_options" 
                                                                        :searchable="false"
                                                                        label="name" 
                                                                        track-by="name"
                                                                        :custom-label="nameWithAmount"
                                                                        :preselect-first="true">
                                                                        <template slot="option" slot-scope="props">
                                                                            <div class="option__desc">
                                                                                <span class="option__title">{{ props.option.name }}: {{ props.option.symbol }}{{ props.option.amount }}</span>
                                                                            </div>
                                                                        </template>
                                                                    </multiselect>
                                                                </span>
                                                            </div>
                                                            <b-form-input id="input-sm" placeholder="0"></b-form-input>
                                                        </div>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-group">
                                                <span class="float-label">
                                                    <input type="text" id="Reference" class="form-control" placeholder="Reference">
                                                    <label for="Reference" class="control-label">Reference</label>
                                                    <div class="line"></div>
                                                </span>
                                            </div>
                                        </li>
                                        <li class="d-flex justify-content-end">
                                            <datepicker class="datepicker-main" ref="programaticOpen"></datepicker>
                                            <b-button variant="primary mr-1 btn-mw" size="lg" @click="openPicker"><i class="fa fa-calendar" aria-hidden="true"></i></b-button>
                                            <b-button class="mr-1 btn-mw" size="lg" @click="$router.go(-1)">Back</b-button>
                                            <b-button type="submit" variant="primary btn-mw" size="lg">Continue</b-button>
                                        </li>
                                    </b-form>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const Multiselect = require('vue-multiselect').default
const Datepicker = require('vuejs-datepicker').default
export default {
    name:'UserAccountDComponent',
    components: {
        BreadCrumb,
        Multiselect,
        Datepicker,
    },data (){
        return{
            user_details:{
               name:'Jones Amegbor',
               curancy:'GBP',
               price:'70083577',
               date:'20-23-60'
            },

            user:{
                curancy: null,
            },

            curancys_options: [
                { name: 'Main', symbol:'₵', amount:'70,548.00'},
                { name: 'UK', symbol:'£', amount:'50,630.50'},
                { name: 'US' , symbol:'$', amount:'32,540.30'},
                { name: 'European', symbol:'€', amount:'42,540.30'},
            ],
        }
    },methods: {
        nameWithAmount ({ name, symbol,amount }) {
            return `${name}: ${symbol}${amount} `
        },

        userDetails() {
            this.$router.push({ path: '/pay-transfer/exchange' })
        },
        openPicker() {
            this.$refs.programaticOpen.showCalendar();
        },
    },filters: {
        short_hand (company_name) {
            // You can put your logic here.
            let words = company_name.split(" ")
            let short_hand = words[0][0] + words[words.length-1][0]
            return short_hand // <-- The return value as Per logic
        }
    }
}

</script>