<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb :breadCrumbIteams="breadCrumbIteams"></bread-crumb>
        </div>
        <div class="row row-deck">
            <div class="col-lg-8 col-md-12">
                <div class="card card-top-line">
                    <div class="body">
                        <ValidationObserver v-slot="{ passes }">
                            <form class="personal-info" @submit.prevent="passes(personalInformation)">
                                <b-tabs class="profile-tab" nav-class="nav-tabs-new2" :no-nav-style="true" v-model="currentActiveTabIndex">
                                    <!-- Personal Info Tab -->
                                    <b-tab title="Personal Info" active>
                                        <div class="d-flex personal-info-detail row">
                                            <div class="user-img">
                                                <input type="file" ref="file" accept="image/*" style="display: none" @change="campaignImageChange">
                                                <img class="img-fluid" :src="personalInfo.userImage" alt="User" @click="$refs.file.click()">
                                            </div>
                                            <div class="personal-detail-form">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-12">
                                                        <ValidationProvider name="title" :rules="{'required':currentActiveTabIndex == 0}" v-slot="{ errors}">
                                                            <div class="country-dropdown">
                                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':personalInfo.title && !errors[0]}">
                                                                    <span class="float-label">
                                                                    <label for="Country">Title*</label>
                                                                        <multiselect
                                                                            placeholder=""
                                                                            v-model="personalInfo.title" 
                                                                            :show-labels="false" 
                                                                            :options="title_options" 
                                                                            :searchable="false" >
                                                                        </multiselect> 
                                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                        <i class="fa fa-check" v-if="personalInfo.title && !errors[0]"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                        <ValidationProvider name="first_name" :rules="{'required':currentActiveTabIndex == 0}" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !personalInfo.first_name,'success':personalInfo.first_name}">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="personalInfo.first_name" class="form-control" id="first_name" placeholder="First Name*">
                                                                    <label for="first_name" class="control-label">First Name*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !personalInfo.first_name"></i>
                                                                    <i class="fa fa-check" v-if="personalInfo.first_name"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <div class="form-group">
                                                            <span class="float-label">
                                                                <input type="text" v-model="personalInfo.middle_name" class="form-control" id="middle_name" placeholder="Middle Name">
                                                                <label for="middle_name" class="control-label">Middle Name</label>
                                                                <div class="line"></div>
                                                            </span>
                                                        </div>
                                                        <ValidationProvider name="last_name" :rules="{'required':currentActiveTabIndex == 0}" v-slot="{ errors}">
                                                            <div class="form-group" :class="{'errors': errors.length && !personalInfo.last_name,'success':personalInfo.last_name}">
                                                                <span class="float-label">
                                                                    <input type="text" v-model="personalInfo.last_name" class="form-control" id="last_name" placeholder="Last Name*">
                                                                    <label for="last_name" class="control-label">Last Name*</label>
                                                                    <div class="line"></div>
                                                                    <i class="fa fa-times" v-if="errors.length && !personalInfo.last_name"></i>
                                                                    <i class="fa fa-check" v-if="personalInfo.last_name"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-lg-6 col-md-12">
                                                        <ValidationProvider name="dob" :rules="{'required':currentActiveTabIndex == 0}" v-slot="{ errors }">
                                                            <div class="form-group datepicker" :class="{'errors': errors[0],'success':personalInfo.date_of_birth && !errors[0]}">
                                                                <span class="float-label">
                                                                    <b-input-group>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text">Date of Birth*</span>
                                                                            <span class="input-group-text" @click="$refs.dob.showCalendar()"><i class="icon-calendar"></i></span>
                                                                        </div>
                                                                        <datepicker ref="dob"  class="form-control" :bootstrap-styling="false" v-model="personalInfo.date_of_birth" :typeable="true"></datepicker>
                                                                    </b-input-group>
                                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                    <i class="fa fa-check" v-if="personalInfo.date_of_birth && !errors[0]"></i>
                                                                </span>
                                                            </div>
                                                        </ValidationProvider>
                                                        <div class="gender-grp">
                                                            <div class="form-group mh-48">
                                                                <label class="mb-0 d-block">Gender *</label>
                                                                <label class="fancy-radio mb-0">
                                                                    <input type="radio" name="hardware_yes" value="male" v-model="personalInfo.gender">
                                                                    <span><i></i>Male</span>
                                                                </label>
                                                                <label class="fancy-radio mb-0">
                                                                    <input type="radio" name="hardware_no" value="female" v-model="personalInfo.gender">
                                                                    <span><i></i>Female</span>
                                                                </label>
                                                                <p id="error-radio"></p>
                                                            </div>
                                                        </div>
                                                        <ValidationProvider name="cob" :rules="{'required':currentActiveTabIndex == 0}" v-slot="{ errors}">
                                                            <div class="phone-with-country">
                                                                <div class="country-dropdown">
                                                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':personalInfo.countryofBirth && !errors[0]}">
                                                                        <span class="float-label">
                                                                        <label for="Country">Country of Birth*</label>
                                                                            <multiselect
                                                                                placeholder=""
                                                                                v-model="personalInfo.countryofBirth"
                                                                                :show-labels="false" 
                                                                                :options="countryofBirth_options" 
                                                                                :searchable="false"
                                                                                label="name" 
                                                                                track-by="name"
                                                                                :custom-label="currencyFlag">
                                                                                <template slot="singleLabel" slot-scope="props">
                                                                                    <span class="mr-2" :class="props.option.flagClass"></span>
                                                                                    <span class="option-title">
                                                                                        <span>{{ props.option.name }}</span>
                                                                                    </span>
                                                                                </template>
                                                                                <template slot="option" slot-scope="props">
                                                                                    <div class="option-title">
                                                                                        <span class="mr-2" :class="props.option.flagClass"></span>
                                                                                        <span>{{ props.option.name }}</span>
                                                                                    </div>
                                                                                </template>
                                                                            </multiselect>  
                                                                            <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                            <i class="fa fa-check" v-if="personalInfo.countryofBirth && !errors[0]"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>

                                    <!-- contact details Tab -->
                                    <b-tab title="Contact details">
                                        <div class="row">
                                            <div class="col-sm-6 col-12">
                                                <ValidationProvider name="cor" :rules="{'required':currentActiveTabIndex == 1}" v-slot="{ errors}">
                                                    <div class="country-dropdown">
                                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':contactDetails.countryofResidence && !errors[0]}">
                                                            <span class="float-label">
                                                            <label for="Country">Country of Residence*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="contactDetails.countryofResidence"
                                                                    :show-labels="false" 
                                                                    :options="countryofBirth_options" 
                                                                    :searchable="false"
                                                                    label="name" 
                                                                    track-by="name"
                                                                    :custom-label="currencyFlag"
                                                                    @select="setPrimaryNumberCountryCode">
                                                                    <template slot="singleLabel" slot-scope="props">
                                                                        <span class="mr-2" :class="props.option.flagClass"></span>
                                                                        <span class="option-title">
                                                                            <span>{{ props.option.name }}</span>
                                                                        </span>
                                                                    </template>
                                                                    <template slot="option" slot-scope="props">
                                                                        <div class="option-title">
                                                                            <span class="mr-2" :class="props.option.flagClass"></span>
                                                                            <span>{{ props.option.name }}</span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect>  
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="contactDetails.countryofResidence && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="email" :rules="currentActiveTabIndex == 1 ? 'required|email' : ''" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !contactDetails.email || errors[0],'success':contactDetails.email && !errors[0]}">
                                                        <span class="float-label">
                                                            <input type="email" v-model="contactDetails.email" class="form-control" name="email1" id="email" placeholder="Email*">
                                                            <label for="email" class="control-label">Email*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !contactDetails.email || errors[0] "></i>
                                                            <i class="fa fa-check" v-if="contactDetails.email && !errors[0]"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                                <div class="form-group" :class="{'errors': contactDetails.mobileInputError == false,'success':contactDetails.primaryNumber && contactDetails.mobileInputError}">
                                                    <div class="float-label">
                                                        <label for="primary_number" class="control-label">Mobile Number*</label>
                                                        <vue-tel-input
                                                            v-model="contactDetails.primaryNumber"
                                                            v-bind="primaryMobileNumber"
                                                            @validate ="mobileInputValidate"
                                                            ref="primaryNumber"
                                                            @keydown.native="restrictSpecialCharacter($event)"
                                                        >
                                                        <template v-slot:arrow-icon>
                                                            <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                        </template>
                                                        </vue-tel-input>
                                                        <div class="line"></div>
                                                        <i class="fa fa-times" v-if="contactDetails.mobileInputError == false"></i>
                                                        <i class="fa fa-check" v-if="contactDetails.mobileInputError && contactDetails.primaryNumber"></i>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="float-label">
                                                        <label for="secondary_number" class="control-label">Secondary Phone Number</label>
                                                        <vue-tel-input
                                                            v-model="contactDetails.secondaryNumber" 
                                                            v-bind="secondaryMobileNumber"
                                                            ref="secondaryNumber"
                                                            @keydown.native="restrictSpecialCharacter($event)"
                                                        >
                                                        <template v-slot:arrow-icon>
                                                            <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                        </template>
                                                        </vue-tel-input>
                                                        <div class="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-12">
                                                <ValidationProvider name="address1" :rules="{'required':currentActiveTabIndex == 1}" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !contactDetails.address1,'success':contactDetails.address1}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="contactDetails.address1" class="form-control" id="address1" placeholder="Address Line 1*">
                                                            <label for="address1" class="control-label">Address Line 1*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !contactDetails.address1"></i>
                                                            <i class="fa fa-check" v-if="contactDetails.address1"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                                <div class="form-group">
                                                    <span class="float-label">
                                                        <input type="text" v-model="contactDetails.address2" class="form-control" id="address2" placeholder="Address Line 2">
                                                        <label for="address2" class="control-label">Address Line 2</label>
                                                        <div class="line"></div>
                                                    </span>
                                                </div>
                                                <ValidationProvider name="city" :rules="{'required':currentActiveTabIndex == 1}" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !contactDetails.city,'success':contactDetails.city}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="contactDetails.city" class="form-control" id="city" placeholder="City*">
                                                            <label for="city" class="control-label">City*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !contactDetails.city"></i>
                                                            <i class="fa fa-check" v-if="contactDetails.city"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="state" :rules="{'required':currentActiveTabIndex == 1}" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !contactDetails.scpr,'success':contactDetails.scpr}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="contactDetails.scpr" class="form-control" id="state" placeholder="State/Province/Region/County*"/>
                                                            <label for="state" class="control-label">State/County/Province/Region*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !contactDetails.scpr"></i>
                                                            <i class="fa fa-check" v-if="contactDetails.scpr"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                                <ValidationProvider name="zipCode" :rules="{'required':currentActiveTabIndex == 1}" v-slot="{ errors}">
                                                    <div class="form-group" :class="{'errors': errors.length && !contactDetails.postCode,'success':contactDetails.postCode}">
                                                        <span class="float-label">
                                                            <input type="text" v-model="contactDetails.postCode" class="form-control" id="zipCode" placeholder="Post/Zip Code*"/>
                                                            <label for="zipCode" class="control-label">Post/Zip Code*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors.length && !contactDetails.postCode"></i>
                                                            <i class="fa fa-check" v-if="contactDetails.postCode"></i>
                                                        </span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                    <router-link class="btn btn-secondary btn-mw btn-lg" to="/dashboard-v2">Cancel</router-link>
                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Update</b-button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="card card-top-line">
                    <div class="header"><h2>Current Profile Limit</h2></div>
                    <div class="body">
                        <ul class="list-unstyled mb-0 profile-timeline-list">
                            <li v-for="(timelineList,i) in timelineLists" :key="i" class="timeline-list" :class="timelineList.classList">
                                <p>
                                    <span>{{ timelineList.name }}</span>
                                    <strong>{{ timelineList.amount }} <small>{{ timelineList.limit }}</small></strong>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
const Multiselect = require('vue-multiselect').default
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const Datepicker = require('vuejs-datepicker').default
const { VueTelInput } = require('vue-tel-input')
import "@/plugins/vee-validate";
export default {
    name:'EditProfile',
    components: {
        BreadCrumb,
        Multiselect, 
        ValidationObserver,
        ValidationProvider,
        Datepicker,
        VueTelInput
    },data(){
        return{
            breadCrumbIteams:[
                {
                    title:'Dashboard V2',
                    links:'/dashboard-v2'
                },
                {
                    title:'Edit Profile',
                    links:''
                }
            ],
            currentActiveTabIndex: 0,
            personalInfo:{
                userImage: require(`@/assets/user.png`),
                title:null,
                firstName:'',
                middleName:'',
                lastName:'',
                dateofBirth:'',
                gender:'male',
                countryofBirth:null
            },

            title_options:['Mr.', 'Mrs.','Miss','Dr.','Ms.','Prof.','Rev.','Lady','Sir','Capt.','Major','Lt.-Col.','Col.','Lady','Lt.-Cmdr.','Hon.','Cmdr.','Flt. Lt.','Brgdr.','Judge','Lord'],
            countryofBirth_options: [
                { name: 'Ghana', flagClass:"flag-icon flag-icon-gh fi-md fi-round",primaryNumberCode:'GH'},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-md fi-round",primaryNumberCode:'GB'},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-md fi-round",primaryNumberCode:'FR'},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-md fi-round",primaryNumberCode:'US'},
            ],

            contactDetails:{
                countryofResidence:null,
                email:'',
                mobileInputError:'',
                primaryNumber:'',
                secondaryNumber:'',
                address1:'',
                address2:'',
                city:'',
                scpr:'',
                postCode:''
            },

            primaryMobileNumber:{
                mode: "auto",
                autoFormat:false,
                defaultCountry:"GB",
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                }
            },

            secondaryMobileNumber: {
                mode: "auto",
                autoFormat:false,
                defaultCountry: "GB",
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                }
            },

            timelineLists:[
                {
                    name:'Basic',
                    amount:'£430.00',
                    limit:'Daily Limit',
                    classList:'filled'
                },
                {
                    name:'Bronze',
                    amount:'£2,300.00',
                    limit:'Daily Limit',
                    classList:'active'
                },
                {
                    name:'Silver',
                    amount:'£4,400.00',
                    limit:'Daily Limit',
                    classList:'inactive'
                },
                {
                    name:'Silver+',
                    amount:'£30,000.00',
                    limit:'Daily Limit',
                    classList:'inactive'
                }
            ]
        }
    },methods:{
        personalInformation(){
            if(typeof(this.contactDetails.mobileInputError) == 'undefined'){
                this.contactDetails.mobileInputError = false
            }

            if(this.contactDetails.mobileInputError && this.currentActiveTabIndex == 1){
               console.log("contact details submit form");
            }else if(this.currentActiveTabIndex == 0){
                console.log("personal info submit form");
            }
        },

        currencyFlag ({ name, flagClass }) {
            return `${flagClass} - ${name}`
        },

        mobileInputValidate(phoneObject){
            this.contactDetails.mobileInputError = phoneObject.valid
        },

        setPrimaryNumberCountryCode(defaultCountry) {
            this.$refs.primaryNumber.choose(defaultCountry.primaryNumberCode);
            this.$refs.secondaryNumber.choose(defaultCountry.primaryNumberCode);
        },

        campaignImageChange(e) {
            if(e.target.files.length > 0){
                let file = e.target.files[0];
                this.personalInfo.userImage = URL.createObjectURL(file);
            }
        },

        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        }
    },watch: {
        mobileNumber(val) {
            this.contactDetails.primaryNumber = val.replace(/\W/g, "");
            this.contactDetails.secondaryNumber = val.replace(/\W/g, "")
        },
    }
}
</script>