<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="timeline-form">
            <div class="timeline-form-left">
                <div class="card">
                    <div class="body">
                        <events-timeline :eventStep="2"></events-timeline>
                    </div>
                </div>
            </div>
            <div class="timeline-form-right w-100">
                <div class="card card-top-line">
                    <div class="body">
                        <ul class="list-unstyled mb-0 payment-single-transfer">
                            <li class="mb-4">
                                <h5>Basics</h5>
                                <p>Make a good first impression: introduce your event objectives and entice people to learn more. This basic information will represent your event on your event page, on your event card, and in searches.</p>
                            </li>
                            <form @submit.prevent="productDataSubmit" id="event-form" ref="form">
                                <li>
                                    <label class="mb-1">Product Stage <span class="badge badge-secondary">NOT VARIFIED</span></label>
                                    <p class="text-muted">
                                        If you're funding the creation of a hardware product (e.g. has a power supply), please select
                                        your current stage of product development below to help backers understand how far along your
                                        product is in the development cycle.
                                    </p>
                                </li>
                                <li>
                                    <p class="text-muted">
                                        Note: if your product does not contain a hardware component (backpack, fashion, etc.), you will
                                        still be required to select a product stage, however, our team will make the final decision on
                                        whether or not a product stage is applicable to your product. <a href="javascript:void(0)"> Learn more</a>
                                    </p>
                                </li>
                                <li>
                                    <label>Select Current Stage <span class="text-danger">*</span></label>
                                    <b-tabs nav-class="nav-tabs-new2" :no-nav-style="true">

                                        <!-- Concept -->
                                        <b-tab active>
                                            <template #title>
                                                <i class="fa fa-lightbulb-o mr-1"></i>
                                                Concept
                                            </template>
                                            <p>Projects on Concept Stage are just a concept and don’t have a prototype. No additional information is required, however your project must meet angel community <a href="#!">Terms of Use</a>.</p>
                                            <hr>
                                            <p>Angel community reviews the material provided on the event page and the appropriate badge appears upon approval. Your event can run with a pending product stage until approved.</p>
                                        </b-tab>

                                        <!-- Prototype -->
                                        <b-tab>
                                            <template #title>
                                                <i class="fa fa-wrench mr-1"></i>
                                                Prototype
                                            </template>
                                            <p>Projects on Prototype Stage <strong>must be a working version of the physical product that successfully performs core functions and features as described</strong>. For examples, check out our Help Center <a href="#!">article</a>.</p>
                                            <p>event Story Requirements:</p>
                                            <p><i class="fa fa-check text-success mr-1"></i> 1 Video and 3 or more photos that clearly demonstrates you have a working prototype</p>
                                            <p><i class="fa fa-check text-success mr-1"></i>The story information must describe the key features and functionality of the physical prototype</p>
                                            <p><i class="fa fa-times text-danger mr-1"></i> 3D renderings and digital mockups are not acceptable</p>
                                            <hr>
                                            <p>Angel community reviews the material provided on the event page and the appropriate badge appears upon approval. Your event can run with a pending product stage until approved.</p>
                                        </b-tab>
                                        
                                        <!-- Production -->
                                        <b-tab>
                                            <template #title>
                                                <i class="fa fa-building mr-1"></i>
                                                Production
                                            </template>
                                            <p>Projects on Production Stage <strong>must show clear evidence that they have begun the process of turning the prototype into a mass-produced product</strong>. For examples, check out our Help Center article.</p>
                                            <p>event Story Requirements:</p>
                                            <p><i class="fa fa-check text-success mr-1"></i>Non-rendered photographic evidence of production runs, tooling, molds, components, or final inventory in the factory</p>
                                            <p><i class="fa fa-times text-danger mr-1"></i>3D renderings and digital mockups are not acceptable</p>
                                            <hr>
                                            <p>angel community reviews the material provided on the event page and the appropriate badge appears upon approval. Your event can run with a pending product stage until approved.</p>
                                        </b-tab>

                                        <!-- Shipping -->
                                        <b-tab>
                                            <template #title>
                                                <i class="fa fa-truck mr-1"></i>
                                                Shipping
                                            </template>
                                            <p>events on Shipping Stage <strong>must show evidence that they have the inventory to ship products to backers, and have begun shipping</strong>. For examples, check out our Help Center <a href="#!">article</a>.</p>
                                            <p>event Story Requirements:</p>
                                            <p><i class="fa fa-check text-success mr-1"></i> Non-rendered photography that shows preparation for shipping such as completedcomponents and packaging</p>
                                            <p><i class="fa fa-check text-success mr-1"></i> Non-rendered photography that shows available inventory</p>
                                            <hr>
                                            <p>Angel community reviews the material provided on the event page and the appropriate badge appears upon approval. Your event can run with a pending product stage until approved.</p>
                                        </b-tab>

                                    </b-tabs>
                                </li>
                                <li>
                                    <label>Join the Arrow Certification Program </label>
                                    <p>The Arrow Certification Program is complimentary for entrepreneurs with an electronics product, and offers 1:1 design and engineering support, opportunities to boost your event, and a chance at Flash Funding. <a href="javascript:void(0);">Learn more</a></p>
                                    <div class="form-group clearfix">
                                        <label class="fancy-checkbox new-fancy">
                                            <input type="checkbox" @click="checkIsChecked($event)">
                                            <span>I would like to join the Arrow Certification Program. I certify that I have read and understand the terms and conditions of the <a href="javascript:void(0);">Arrow Certification</a> Program and the <a href="javascript:void(0);">Arrow Flashfunding Program</a>.</span>
                                        </label>
                                        <span class="text-danger">{{  event.errorMsg }}</span>
                                    </div>
                                </li>
                                <div class="d-flex flex-column flex-md-row justify-content-md-end justify-content-center">
                                    <b-button size="lg" @click="$router.go(-1)" class="btn-mw">BACK</b-button>
                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">SAVE &amp; CONTINUE</b-button>
                                </div>
                            </form>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const EventTimeline = require('@/components/core/EventTimeline').default

export default {
    name:'EventForm3Component',
    components: {
        BreadCrumb,
        'events-timeline':EventTimeline
    },data(){
        return{
            event:{
                IsChecked: false,
                errorMsg:''
            },
        }
    },methods: {
        //submit form data
        productDataSubmit(){
            if(this.event.IsChecked){
                this.$router.push({ path: '/event/event-metadata' })
            }else{
                return this.event.errorMsg = 'Please select checkbox to continue.'
            }
        },

        //check Arrow Certification checkbox cheked
        checkIsChecked(event){
            this.event.IsChecked = event.target.checked
            if(this.event.IsChecked){
                return this.event.errorMsg = ''
            }
        }
    }
}
</script>
