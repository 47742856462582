<template>
    <div class="card card-top-line">
        <money-header-widgets
            :img = "headerDetais.image"
            :title = "headerDetais.title"
            :subTitle = "headerDetais.subTitle"
            :isActions = false
        ></money-header-widgets>
        <div class="body">
            <div class="d-flex justify-content-between flex-wrap">
                <p v-for="(rewardCode,i) in rewardCodes" :key="i" class="d-flex flex-column font-12 mb-2" :class="rewardCode.classList">
                    {{ rewardCode.name }}
                    <strong class="font-16" :class="{'text-warning' : i == 0}">{{ rewardCode.code }}</strong>
                </p>
            </div>
            <div class="d-flex justify-content-around reward-count">
                <p class="d-flex flex-column text-center mb-0 font-12" v-for="(rewardCount,i) in rewardCounts" :key="i">
                    {{ rewardCount.name }}
                    <strong class="font-16">{{ rewardCount.totalReward }}</strong>
                </p>
            </div>
            <div class="action-btn">
                <b-button type="submit" variant="primary" size="lg" v-b-modal.payangel_rewards>Earn More NOW</b-button>
            </div>
            <b-modal id="payangel_rewards"  hide-footer hide-header centered>
                <div class="modal-area">
                    <ul class="list-unstyled mb-0 text-center px-2">
                        <li>
                            <h5>Copy Referral link</h5>
                            <p>{{ referralLink }}</p>
                        </li>
                        <li>
                            <div class="d-flex flex-column justify-content-between align-items-center w-100 mt-4">
                                <b-button variant="secondary mr-1 btn-mw" size="lg" @click="copyLink()">Copy referral link</b-button>
                                <router-link class="btn btn-danger mt-3 btn-mw btn-lg" to="/payangel-reward">Link to PayAngel Rewards</router-link>
                            </div>
                        </li>
                    </ul>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
const MoneyTransferHeader = require('@/components/admin/dashboard/dashboard_v2_widgets/MoneyTransferHeader').default
export default {
    name:'PayangelRewards',
    components:{
        'money-header-widgets':MoneyTransferHeader
    },data(){
        return{
            headerDetais:{
                image: require(`@/assets/img/icon-payangel-rewards.svg`),
                title:'PAYANGEL REWARDS',
                subTitle:'Invite your friends and earn money when they transfer money with us.'
            },

            rewardCodes:[
                {
                    name:'Your PayAngel Reward code:',
                    code:'AM101721',
                    classList:'text-left pr-2'
                },
                {
                    name:'Total Earnings:',
                    code:'£800',
                    classList:'text-right pl-2'
                }
            ],

            rewardCounts:[
                {
                    name:'Referral Counts',
                    totalReward:0
                },
                {
                    name:'Conversions',
                    totalReward:0
                }
            ],
            referralLink: 'https://dev.payangel.com/signup/AM101721'
        }
    },methods:{
        copyLink(){
            var inputofKey = document.createElement("input");
            inputofKey.value = this.referralLink
            document.body.appendChild(inputofKey);
            inputofKey.select();
            inputofKey.focus(); //SET FOCUS on the TEXTFIELD
            document.execCommand("Copy");
            inputofKey.remove();
        },
    }
}
</script>