<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb :displayViewAllCampaigns="true" ></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="body crowdfunding-main d-flex justify-content-between flex-column flex-md-row">
                        <div class="left-crowdfunding border rounded w-50 m-2">
                            <ul class="list-unstyled mb-0 text-center">
                                <li><img src="@/assets/crowdfunding.svg" class="mw-100" alt=""></li>
                                <li><h4 class="mt-3 mb-1">Welcome to Angel Communities</h4></li>
                                <li><p>What are you CrowdFunding for?</p></li>
                            </ul>
                        </div>
                        <div class="right-crowdfunding d-flex flex-column justify-content-between w-50 m-2">
                            <div class="right-top border rounded" @click="projectRedirect()">
                                <ul class="list-unstyled mb-0 text-center">
                                    <li><h4>A project</h4></li>
                                    <li><p>Angel Communities is a CrowdFunding platform helping entrepreneurial ideas come to life.</p></li>
                                    <li><p class="mb-0">5% platform fee <i class="fa fa-question-circle" aria-hidden="true"></i></p></li>
                                </ul>
                            </div>
                            <div class="right-bot border rounded">
                                <ul class="list-unstyled mb-0 text-center">
                                    <li><h4>A Cause</h4></li>
                                    <li><p>GoFundMe is the world's largest and most trusted free social fundraising platform.</p></li>
                                    <li><p class="mb-0">0% platform fee <i class="fa fa-question-circle" aria-hidden="true"></i></p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
export default {
    name:'CamDashboardComponent',
    components: {
        BreadCrumb
    },methods:{
        projectRedirect(){
            this.$router.push({ path: '/crowdfunding/campaign' })
        }
    }
}
</script>
