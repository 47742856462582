<template>
    <div class="right-content mx-auto my-auto">
        <ValidationObserver v-slot="{ passes }">
            <div class="header pb-3 text-center">
                <p class="lead">Recover my password</p>
            </div>
            <div class="body">
                <form class="form-auth-small" @submit.prevent="passes(forgotSubmit)" >
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                        <div class="form-group" :class="{'errors': errors.length && !forgotformdata.email || errors[0],'success':forgotformdata.email && !errors[0]}">
                            <span class="float-label">
                                <input type="email" v-model="forgotformdata.email" class="form-control" name="email" id="forgot-email">
                                <label for="forgot-email" class="control-label">Email*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors.length && !forgotformdata.email || errors[0]"></i>
                                <i class="fa fa-check" v-if="forgotformdata.email && !errors[0]"></i>
                            </span>
                        </div>
                    </ValidationProvider>
                    <b-button type="submit" block variant="primary" size="lg">RESET PASSWORD</b-button>
                    <div class="bottom">
                        <span class="helper-text">Know your password? <router-link to="/authentication/page-login">Login</router-link></span>
                    </div>
                </form>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
    name:'ForgotPasswordComponent',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        forgotSubmit() {
            let forgotPasswordData = {
                email: this.forgotformdata.email
            }
            this.$http.post('https://standapp.com/api/v1/business/auth/forgotPassword', forgotPasswordData)
            .then((res) => {
                //Perform Success Action
                // console.log(res);
                if(res.data.code == '0006-000') {
                    let resMessage = res.data.message; //'Please provide reset passcode which has receive by mail'
                    this.$swal('Success!', resMessage, 'success', 'OK');
                    this.$router.push({ path: '/authentication/page-reset-password' })
                } else {
                    this.$swal('Error!', res.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                console.log('forgotPassword',error.response.data.code);
            });
        },
    },
    data(){
        return{
            forgotformdata: {
                email: "",
            }
        }
    },
    mounted () {
       
    }
}
</script>
<style scoped>

</style>