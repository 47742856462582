<template>
    <div class="timeline-add">
        <h5>Your Progress</h5>
        <p>Ready, Set, Go!</p>
        <div class="timeline-area mt-4">
            <div v-for="(timeline,index) in timelines" :key="index" class="timeline-item" :class="[timeline.timelinesClass,timeline.isFilled ? 'filled' : '']">
                <span>{{ timeline.heading }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'EventTimeline',
    props: {
        eventStep : Number,
    },data(){
        return{
            timelines:[
                {
                    timelinesClass:'green',
                    isFilled:false,
                    heading:'Event Details'
                },
                {
                    timelinesClass:'blue',
                    isFilled:false,
                    heading:'Location & Categories'
                },
                {
                    timelinesClass:'warning',
                    isFilled:false,
                    heading:'Product Stage'
                },
                {
                    timelinesClass:'red',
                    isFilled:false,
                    heading:'Metadata'
                },
                {
                    timelinesClass:'dark',
                    isFilled:false,
                    heading:'FAQ'
                }
            ]
        }
    },mounted(){
        this.timelines.map((data,i)=>{
            if(i+1 <= this.eventStep){
                data.isFilled = true;
            }
        });
    },
}
</script>
