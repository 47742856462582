<template>
  <div class="container-fluid">
    <div class="block-header">
      <bread-crumb></bread-crumb>
    </div>
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card card-top-line">
          <div class="header">
            <h2>Payment Option</h2>
          </div>
          <div class="body pt-0">
            <div
              class="d-flex align-items-center flex-column flex-md-row-reverse justify-content-center justify-content-md-start mb-3 payment-dropdown"
            >
              <b-button
                size="lg"
                variant="primary btn-mw"
                @click="createPaymentOption()"
                >Add Payment Option <i class="fa fa-plus-square"></i
              ></b-button>

              <!-- Add payment option -->
              <b-modal id="add_payment_option" :title="modalTitle" hide-footer no-stacking no-close-on-backdrop @shown="resetForm">
                  <ul class="list-unstyled mb-0 payment-single-transfer">
                      <ValidationObserver v-slot="{ passes }">
                          <form @submit.prevent="passes(addPaymentOptionForm)" id="single-form">
                              <li>
                                  <ValidationProvider name="name" rules="required" v-slot="{ errors}">
                                      <div class="form-group" :class="{'errors': errors.length && !addPayment.name || errorArr[1] && errorArr[1].fieldName!='','success':addPayment.name && errorArr[1] && errorArr[1].fieldName==''}">
                                          <span class="float-label">
                                              <input type="text" v-model="addPayment.name" class="form-control" id="name" placeholder="Add Payment Option Name*" @input="checkNameFormat($event, 'name')">
                                              <label for="owner" class="control-label">Payment Option Name</label>
                                              <div class="line"></div>
                                              <i class="fa fa-times" v-if="errors.length && !addPayment.name || errorArr[1] && errorArr[1].fieldName!=''"></i>
                                              <i class="fa fa-check" v-if="addPayment.name && errorArr[1] && errorArr[1].fieldName==''"></i>
                                          </span>
                                          <span class="text-danger error-msg" v-if="fieldName == 'name'">{{fieldErrorMsg}}</span>
                                      </div>
                                  </ValidationProvider>
                                  <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0100-202'">{{apiErrorMsg}}</span> 
                              </li>
                              <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                  <b-button type="submit" v-if="paymentOptionId==''" variant="primary btn-mw" size="lg">Add Payment Option</b-button>
                                   <b-button v-if="paymentOptionId!=''" type="submit" variant="primary btn-mw" size="lg">Update Payment Option </b-button>
                                  <b-button @click="$bvModal.hide('add_payment_option')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                              </div>
                          </form>
                      </ValidationObserver>
                  </ul>
              </b-modal>
            </div>
            <b-table
              responsive
              hover
              table-class="js-basic-example table-custom mb-0 border"
              head-variant="light"
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template #head()="{label,  field: { key }}">
                {{ label }}
                <!-- Custom icons -->
                <template>
                  <i v-if="sortBy !== key" class=""></i>
                  <i
                    v-else-if="sortDesc"
                    class="fa fa-long-arrow-down ml-1"
                  ></i>
                  <i v-else class="fa fa-long-arrow-up ml-1"></i>
                </template>
              </template>
              <template #cell(action)="row">
                <b-dropdown
                  id="dropdown-1"
                  text="Actions"
                  right
                  variant="outline-primary"
                >
                  <b-dropdown-item @click="showEdit(row)">Edit</b-dropdown-item>
                  <b-dropdown-item @click="deleteData(row.item.id)">Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <main-loader ref="loader"></main-loader>
  </div>
</template>

<script>
const BreadCrumb = require("@/components/BreadCrumb.vue").default;
import { ValidationObserver, ValidationProvider } from "vee-validate";
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
import shared from "@/shared.js";

export default {
  name: "PaymentOptionComponent",
  components: {
    BreadCrumb,
    "main-loader": LoaderComponent,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      addPayment:{
        name:''
      },
      paymentOptionId:'',
      modalTitle: '',

      items: [
        // {name:'Bank Deposit',action:''},
        // {name:'Debit Card',action:''},
        // {name:'Credit Card',action:''}
      ],

      fields: [
        { key: "name", label: "Name", sortable: true, thClass: "th_sortfix" },
        { key: "action", label: "Action" },
        { sortable: true, sortByFormatted: true, filterByFormatted: true },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",

      errorArr : [],

      fieldName : '',
      fieldErrorMsg : '',
      apiErrorMsg : '',
      elementName : '',
      apiErrorCode: '',
    };
  },
  methods: {
    createPaymentOption() {
      this.paymentOptionId = '';
      this.$bvModal.show("add_payment_option");
    },

    resetForm(){
      if(this.paymentOptionId== '') {
        this.addPayment.name = '';
        this.fieldErrorMsg = '';
        this.apiErrorMsg = '';
        this.apiErrorCode = '';
        this.fieldName = '';
        this.modalTitle = 'Add Payment Option';
      } else {
        this.fieldErrorMsg = '';
        this.apiErrorMsg = '';
        this.apiErrorCode = '';
        this.fieldName = '';
        this.modalTitle = 'Edit Payment Option';

      }
    },

    showEdit(element) {
      console.log(element);
        this.addPayment.name = element.item.name;
        this.paymentOptionId = element.item.id;
        this.$bvModal.show('add_payment_option');
    },

    //add fund submit form data
    addPaymentOptionForm(){
      if(this.paymentOptionId!='') {
        this.updatePaymentOption();   
      } else {
        let paymentData = {
          name: this.addPayment.name
        };
        
        this.$http.post(this.$baseurl+"payment/options/name", paymentData,{
          headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
          },
        }).then((res) => {
          if(res.data.code == '0100-200') {
            shared.toastrSuccess(res.data.message);
            this.getPaymentOptionData();
            this.$bvModal.hide('add_payment_option');
            this.apiErrorMsg = '';
            this.apiErrorCode = '';
          }
        }).catch((error) => {
          this.apiErrorCode = error.response.data.code;
          this.apiErrorMsg = error.response.data.message;
        });
      }
    },

    updatePaymentOption(){
      let paymentData = {
        name: this.addPayment.name,
        isactive: true
      };
      this.$http.put(this.$baseurl+"payment/options/name/"+this.paymentOptionId, paymentData,{
        headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
        },
      }).then((res) => {
        if(res.data.code == '0100-200') {
          shared.toastrSuccess(res.data.message);
          this.getPaymentOptionData();
          this.$bvModal.hide('add_payment_option');
        }
      }).catch((error) => {
        this.apiErrorCode = error.response.data.code;
        this.apiErrorMsg = error.response.data.message;
      });  
    },

    async getPaymentOptionData() {
      try {
        const response = await this.$http.get(this.$baseurl + "payment/options/name",{
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
          },
        });
        // JSON responses are automatically parsed.
        // console.log(response);
        this.items = [];
        if (response.data.code == "0100-100") {
          response.data.details.map((item) => {
            let lists = {
              id: item.id,
              name: item.name,
              action: "",
            };
            this.items.push(lists);
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    checkNameFormat(e, fieldName) {
      let char = e.target.value; //String.fromCharCode(e.keyCode); // Get the character
      let charLength = e.target.value.length;
      var splitStr = e.target.value.split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        if(splitStr[0]!='')
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      if(splitStr[0]=='') {
        this.fieldErrorMsg = '';
        this.fieldName = '';
      }

      this.addPayment[fieldName] = splitStr.join(' ');
      if(/^[A-Za-z'-\s]+$/.test(char)) {
        if(charLength >= 2 && charLength <= 35 && splitStr[0]!='') {
          this.fieldErrorMsg = '';
          this.fieldName = '';
          return true; // Match with regex 
        } else {
          this.fieldName = fieldName;
          this.fieldErrorMsg = 'Please provide minimum two characters without spaces at start.';
        }
      } else {
        this.fieldName = fieldName;
        this.fieldErrorMsg = 'Please provide valid value for payment option name';
        e.preventDefault(); // If not match, don't add to input text
      }
    },

    deleteData(paymentId){
      shared.deleteConfirm().then((result) => {
        if (result.isConfirmed) {
          this.$refs.loader.show();
          this.$http.delete(this.$baseurl+"payment/options/name/"+paymentId,{
            headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
            },
          }).then((res) => {
            if(res.data.code == '0100-200') {
              shared.toastrSuccess(res.data.message);
              this.getPaymentOptionData();
              this.$refs.loader.hide();
            } else {
              this.$refs.loader.hide();
              shared.toastrError(res.data.message);
            }
          }).catch((error) => {
            this.apiErrorCode = error.response.data.code;
            if(this.apiErrorCode == "E0065-005") {
              this.$refs.loader.hide();
              shared.toastrError(error.response.data.message);
            } else {
              this.apiErrorMessage = error.response.data.message;
            }
          });
        }
      })
    }
  },
  mounted() {
    if (sessionStorage.getItem("jwtToken")) {
      // this.totalRows = this.items.length;
      this.getPaymentOptionData();
    } else {
      shared.toastrError("Please login to continue");
      this.$router.push({ path: "/standapp-login" });
    }
  },
};
</script>
