<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <ValidationObserver v-slot="{ passes }">
                        <div class="header">
                            <h2>Add Page</h2>
                        </div>
                        <div class="body">
                            <form @submit.prevent="passes(CMSSubmit)" id="basic-form">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <ValidationProvider name="cms_name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !addCMS.cms_name,'success':addCMS.cms_name}">
                                                <span class="float-label">
                                                    <input type="text" v-model="addCMS.cms_name" class="form-control" id="cms_name" placeholder="CMS Name*">
                                                    <label for="cms_name" class="control-label">CMS Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !addCMS.cms_name"></i>
                                                    <i class="fa fa-check" v-if="addCMS.cms_name"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <input type="text" v-model="addCMS.meta_keywords" class="form-control" id="meta_keywords" placeholder="Meta Keywords">
                                                <label for="meta_keywords" class="control-label">Meta Keywords</label>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                        <div class="head">
                                            <label><strong>Meta Description </strong></label>
                                        </div>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <textarea type="text" v-model="addCMS.meta_description" class="form-control" id="meta_description" placeholder="Meta Description*"/>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-6 col-12">
                                        <ValidationProvider name="meta_title" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !addCMS.meta_title,'success':addCMS.meta_title}">
                                                <span class="float-label">
                                                    <input type="text" v-model="addCMS.meta_title" class="form-control" id="meta_title" placeholder="Meta Title*">
                                                    <label for="meta_title" class="control-label">Meta Title*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !addCMS.meta_title"></i>
                                                    <i class="fa fa-check" v-if="addCMS.meta_title"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                            <div class="form-group form-select single-multiselect select-option-custom">
                                            <span class="float-label">
                                            <label for="functions">Parent CMS</label>
                                                <multiselect 
                                                    placeholder=""
                                                    :searchable="false"
                                                    v-model="addCMS.parentCMS" 
                                                    :options="parentCMS_options" 
                                                    :show-labels="false"
                                                    label="category" 
                                                    track-by="category">
                                                    <template slot="option" slot-scope="props">
                                                        <div>
                                                            <span :class="props.option.isType"> 
                                                                <span v-if="props.option.isType == 'child'">-</span>
                                                                {{props.option.category}} 
                                                            </span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <div class="head">
                                            <label><strong>Page Body </strong></label>
                                        </div>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <vue-editor v-model="addCMS.page_body"></vue-editor>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                    <router-link to="/cms/list" class="btn btn-secondary btn-lg btn-mw">Cancel</router-link>
                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Save</b-button>
                                </div>
                            </form>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { VueEditor } = require('vue2-editor')
export default {
    name: "AddPageComponent",
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect,
        BreadCrumb,
        VueEditor
    },
    data: () => ({
        parentCMS_options:[
            {
                id: 1,
                parentId: "",
                isType: "parent",
                category:'Parent CMS A',
            },
            {
                id: 2,
                parentId: 1,
                isType: "child",
                category:'Child CMS A-a',
            },
            {
                id: 3,
                parentId: 1,
                isType: "child",
                category:'Child CMS A-b',
            },
            {
                id: 4,
                parentId: "",
                isType: "parent",
                category:'Parent CMS B',
            },
            {
                id: 5,
                parentId: 4,
                isType: "child",
                category:'Child CMS B-a',
            },
            {
                id: 6,
                parentId: 4,
                isType: "child",
                category:'Child CMS B-b',
            }
        ],

        addCMS: {
            cms_name:'',
            meta_title:'',
            meta_keywords:'',
            parentCMS: null,
            page_body:''
        },
    }),
    methods: {
        CMSSubmit() {
            this.$router.push({ path: '/cms/list' })
        },
    },computed: {
        
    }
};
</script>
<style >

</style>
