<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- All Acounts-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card p-3 no-shadow">
                    <b-tabs class="account-tab" nav-class="nav-tabs-new2" :no-nav-style="true">
                        <!-- Account Tab -->
                        <b-tab title="Accounts" active>
                            <div class="header p-0 d-flex align-items-center justify-content-between">
                                <h2>All Acounts</h2>
                                <!-- Select Currencies/Pay or transfer/Add Account list/grid Buttons -->
                                <div class="btn-grp d-flex">
                                    <div class="country-dropdown curancy-dropdown mb-0">
                                        <div class="form-group form-select multiselect-grp mb-0">
                                            <span class="float-label">
                                                <label for="roles">Select Currencies</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="curancy" 
                                                    :show-labels="false" 
                                                    :options="currency_options" 
                                                    :searchable="false"
                                                    :multiple="true" 
                                                    :close-on-select="true"
                                                    :clear-on-select="false" 
                                                    label="name" 
                                                    track-by="name"  
                                                    :limit="0"
                                                    :limit-text="displayText"
                                                    @select="isCheckedInput"
                                                    @remove="isCheckedInput">
                                                    <template slot="beforeList">
                                                        <span class="multiselect__option" @click="selectAllChanged">
                                                            <div class="option__desc">
                                                                <span class="option__title">All</span>
                                                            </div>
                                                            <div class="multiselect__element multiselect-all multi-drop">
                                                                <div class="control-inline fancy-checkbox" for="isChecked">
                                                                    <input type="checkbox" id="checkbox2" name="checkbox2" :checked="isAllChecked">
                                                                    <span for="checkbox2"></span>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </template>
                                                    <template slot="option" slot-scope="props">
                                                        <span :class="props.option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ props.option.name }}</span>
                                                        </div>
                                                        <div class="multiselect__element multiselect-all multi-drop">
                                                            <div class="control-inline fancy-checkbox" for="isChecked">
                                                                <input type="checkbox" id="isChecked" name="isChecked" :checked="props.option.isChecked == true">
                                                                <span for="isChecked"></span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </multiselect>   
                                            </span>
                                        </div>
                                    </div>
                                    <!-- Pay or Transfer Button Component-->
                                    <pay-or-transfer-model-button></pay-or-transfer-model-button>
                                    <!-- Add Account Model Button-->
                                    <b-button size="lg" variant="outline-primary ml-3" v-b-modal.add_account><i class="fa fa-plus"></i></b-button>
                                    <b-modal id="add_account" title="Add Account" hide-footer>
                                        <div class="modal-area">
                                            <ul class="list-unstyled mb-0 payment-single-transfer">
                                                <ValidationObserver v-slot="{ passes }">
                                                    <form  id="add_accounts" @submit.prevent="passes(addAccount)">
                                                        <li>
                                                            <ValidationProvider name="currency" rules="required" v-slot="{ errors}">
                                                                <div class="form-group form-select single-multiselect" :class="{'errors': errors.length && !account.currency,'success':account.currency}">
                                                                    <span class="float-label">
                                                                        <label for="functions">Select Currency*</label>
                                                                        <multiselect
                                                                            placeholder=""
                                                                            v-model="account.currency"  
                                                                            :show-labels="false" 
                                                                            :options="currencyOptions" 
                                                                            :searchable="false"
                                                                            label="name" 
                                                                            track-by="name" >
                                                                            <template slot="option" slot-scope="props">
                                                                                <div class="option-title">
                                                                                    <span class="mr-2" :class="props.option.flagClass"></span>
                                                                                    <span>{{ props.option.name }}</span>
                                                                                </div>
                                                                            </template>
                                                                        </multiselect> 
                                                                        <i class="fa fa-times" v-if="errors.length && !account.currency"></i>
                                                                        <i class="fa fa-check" v-if="account.currency"></i>
                                                                    </span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </li>
                                                        <li>   
                                                            <ValidationProvider name="accountName" rules="required" v-slot="{ errors}">
                                                                <div class="form-group" :class="{'errors': errors.length && !account.accountName || errors[0],'success':account.accountName && !errors[0]}">
                                                                    <span class="float-label">
                                                                        <input type="text" v-model="account.accountName" class="form-control" name="accountName" id="accountName" placeholder="Account Name*">
                                                                        <label for="accountName" class="control-label">Account Name*</label>
                                                                        <div class="line"></div>
                                                                        <i class="fa fa-times" v-if="errors.length && !account.accountName || errors[0] "></i>
                                                                        <i class="fa fa-check" v-if="account.accountName && !errors[0]"></i>
                                                                    </span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </li>
                                                        <li>
                                                            <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                                <b-button type="submit" variant="primary btn-mw" size="lg">Add Account</b-button>
                                                                <b-button @click="$bvModal.hide('add_account')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                            </div>
                                                        </li>
                                                    </form>
                                                </ValidationObserver>
                                            </ul>
                                        </div>
                                    </b-modal>
                                    <!--  list/grid Buttons -->
                                    <b-button class="ml-2" size="lg" :variant="activeTab == 'grid' ? 'primary' : 'outline-primary'" @click="isActiveTab('grid')"><i class="fa fa-th-large"></i></b-button>
                                    <b-button class="ml-2" size="lg" :variant="activeTab == 'list' ? 'primary' : 'outline-primary'"  @click="isActiveTab('list')"><i class="fa fa-bars"></i></b-button>
                                </div>
                            </div>
                            
                            <div class="body px-0">

                                <!-- Grid View -->
                                <div class="row grid-view" :class="{'active show': activeTab == 'grid'}">
                                    <div class="col-lg-6" v-for="(account_card,index) in account_cards" :key="index">
                                        <b-card class="card-top-line">
                                            <div class="d-flex justify-content-between align-items-stretch">
                                                <div class="content">
                                                    <div class="text"><h6> {{ account_card.name }}<small class="d-block text-muted">{{ account_card.currency }}</small></h6></div>
                                                    <h4 class="number pt-4 mb-0">
                                                        {{ account_card.currency_symbol }} {{ account_card.number }}
                                                    </h4>
                                                </div>
                                                <div class="img d-flex flex-column align-items-center">
                                                    <span :class="account_card.img_class"></span>
                                                    <b-button  :variant="account_card.isEnableColor" size="sm">{{ account_card.isEnableText }}</b-button>
                                                </div>
                                            </div>                        
                                        </b-card>
                                    </div>
                                </div>

                                <!-- List View / Table -->
                                <div class="row list-view" :class="{'active show': activeTab == 'list'}">
                                    <div class="col-12">
                                        <b-table 
                                            responsive
                                            hover 
                                            outlined
                                            table-class="js-basic-example table-custom mb-0"
                                            head-variant="light"
                                            :items="items" 
                                            :fields="fields"
                                            :current-page="currentPage"
                                            :per-page="perPage"
                                            :sort-by.sync="sortBy"
                                            :sort-desc.sync="sortDesc"
                                            :sort-direction="sortDirection">
                                            <!-- Custom icons -->
                                            <template #head()="{label,  field: { key }}">
                                                {{ label }}
                                                <template>
                                                    <i v-if="sortBy !== key" class=""></i>
                                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                                </template>  
                                            </template>
                                            <template #cell(currency)="data">
                                                <div class="d-flex align-items-center">
                                                    <span :class="data.value.flagClass" class="mr-2"></span>
                                                    <span>{{ data.value.cname }}</span>
                                                </div>
                                            </template>
                                            <template #cell(uuid)="">
                                                <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="Math.floor(Math.random()*90000) + 10000">
                                                    <i class="fa fa-2x fa-barcode"></i>
                                                </b-button>
                                            </template>
                                            <template #cell(action)>
                                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                                    <b-dropdown-item>Edit</b-dropdown-item>
                                                    <b-dropdown-item>Delete</b-dropdown-item>
                                                </b-dropdown>
                                            </template>
                                            <template #cell(status)="data">
                                                <select v-model="data.value.selected" size="sm" class="btn btn-sm btn-filter" :class="data.value.class" @change="onChange($event,data.index+1)">
                                                    <option v-for="(option,index) in options" :key="index" :value="option.value">{{ option.text }}</option>
                                                </select>
                                            </template>
                                            <template #cell(show_details)="row">
                                                <a class="btn" :class="[row.detailsShowing  ? 'btn-primary text-white' : 'btn-outline-primary']" href="javascript:void(0);" @click="row.toggleDetails">
                                                    <i class="fa fa-angle-down" v-if="!row.detailsShowing"></i>
                                                    <i class="fa fa-angle-up" v-if="row.detailsShowing"></i>
                                                </a>
                                            </template>
                                            <template #row-details>
                                                <b-card>
                                                    <b-table 
                                                        responsive
                                                        hover 
                                                        outlined
                                                        table-class="js-basic-example table-custom mb-0"
                                                        head-variant="light"
                                                        :items="subsidiariesItems" 
                                                        :fields="subsidiariesFields"
                                                        :sort-by.sync="subSortBy"
                                                        :sort-desc.sync="subSortDesc"
                                                        :sort-direction="sortDirection">
                                                        <template #head()="{label,  field: { key }}">
                                                            {{ label }}
                                                            <!-- Custom icons -->
                                                            <template>
                                                                <i v-if="subSortBy !== key" class=""></i>
                                                                <i v-else-if="subSortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                                                <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                                            </template>  
                                                        </template>
                                                        <template #cell(currency)="row">
                                                            <div class="d-flex align-items-center">
                                                                <span :class="row.value.flagClass" class="mr-2"></span>
                                                                <span>{{ row.value.cname }}</span>
                                                            </div>
                                                        </template>
                                                        <template #cell(uuid)="">
                                                            <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="Math.floor(Math.random()*90000) + 10000">
                                                                <i class="fa fa-2x fa-barcode"></i>
                                                            </b-button>
                                                        </template>
                                                        <template #cell(status)="row">
                                                            <select v-model="row.value.selected" size="sm" class="btn btn-sm btn-filter" :class="row.value.class" @change="subsidiariesStatus($event,row.index+1)">
                                                                <option v-for="(option,index) in subsidiariesOptions" :key="index" :value="option.value">{{ option.text }}</option>
                                                            </select>
                                                        </template>
                                                        <template #cell(action)>
                                                            <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                                                <b-dropdown-item>Edit</b-dropdown-item>
                                                                <b-dropdown-item>Delete</b-dropdown-item>
                                                            </b-dropdown>
                                                        </template>
                                                    </b-table>
                                                </b-card>
                                            </template>
                                        </b-table>

                                        <!-- Table Pagination -->
                                        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                                            <div class="per-page">
                                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                                </b-form-group>
                                            </div>
                                            <div class="number-page">
                                                <b-pagination 
                                                    v-model="currentPage" 
                                                    :total-rows="totalRows" 
                                                    :per-page="perPage"
                                                    hide-goto-end-buttons 
                                                    hide-ellipsis 
                                                    prev-text="Previous page" 
                                                    next-text="Next page" 
                                                    align="right">
                                                </b-pagination>
                                            </div>
                                            <div class="go-page">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Go To Page</span>
                                                    </div>
                                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </b-tab>

                        <!-- Summary Tab -->
                        <b-tab title="Summary">
                            <p>Summary</p>
                        </b-tab>

                        <!-- Transaction Tab -->
                        <b-tab title="Transaction">
                            <p>Transaction</p>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const PayorTransferModel = require('@/components/core/PayorTransferModel.vue').default
const Multiselect = require('vue-multiselect').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
export default {
    name:'AccountComponent',
    components: {
        BreadCrumb,
        Multiselect,
        'pay-or-transfer-model-button': PayorTransferModel,
        ValidationObserver,
        ValidationProvider,
    },data (){
        return{
            activeTab: 'grid', //current active tab is grid

            //All Acounts grid cards json
            account_cards:[
                {
                    img_class : "flag-icon flag-icon-gh fi-xl fi-round",
                    name : 'Default Account',
                    isEnableText: 'Enabled',
                    isEnableColor : 'secondary mt-auto',
                    currency : 'Ghanaian Cedi',
                    currency_symbol : '₵',
                    number: '70,548.00'
                },
                {
                    img_class : "flag-icon flag-icon-gb fi-xl fi-round",
                    name : 'UK Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary  mt-auto',
                    currency : 'British Pound',
                    currency_symbol : '£',
                    number: '50,630.50'
                },
                {
                    img_class : "flag-icon flag-icon-us fi-xl fi-round",
                    name : 'US Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary  mt-auto',
                    currency : 'US Dollars',
                    currency_symbol : '$',
                    number: '32,540.30'
                },
                {
                    img_class : "flag-icon flag-icon-eu fi-xl fi-round",
                    name : 'European Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary mt-auto',
                    currency : 'Euro',
                    currency_symbol : '€',
                    number: '42,540.30'
                },
                {
                    img_class : "flag-icon flag-icon-gh fi-xl fi-round",
                    name : 'Default Account',
                    isEnableText: 'Enabled',
                    isEnableColor : 'secondary mt-auto',
                    currency : 'Ghanaian Cedi',
                    currency_symbol : '₵',
                    number: '70,548.00'
                },
                {
                    img_class : "flag-icon flag-icon-gb fi-xl fi-round",
                    name : 'UK Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary  mt-auto',
                    currency : 'British Pound',
                    currency_symbol : '£',
                    number: '50,630.50'
                },
                {
                    img_class : "flag-icon flag-icon-us fi-xl fi-round",
                    name : 'US Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary  mt-auto',
                    currency : 'US Dollars',
                    currency_symbol : '$',
                    number: '32,540.30'
                },
                {
                    img_class : "flag-icon flag-icon-eu fi-xl fi-round",
                    name : 'European Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary mt-auto',
                    currency : 'Euro',
                    currency_symbol : '€',
                    number: '42,540.30'
                },
                {
                    img_class : "flag-icon flag-icon-gh fi-xl fi-round",
                    name : 'Default Account',
                    isEnableText: 'Enabled',
                    isEnableColor : 'secondary mt-auto',
                    currency : 'Ghanaian Cedi',
                    currency_symbol : '₵',
                    number: '70,548.00'
                },
                {
                    img_class : "flag-icon flag-icon-gb fi-xl fi-round",
                    name : 'UK Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary  mt-auto',
                    currency : 'British Pound',
                    currency_symbol : '£',
                    number: '50,630.50'
                },
                {
                    img_class : "flag-icon flag-icon-us fi-xl fi-round",
                    name : 'US Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary  mt-auto',
                    currency : 'US Dollars',
                    currency_symbol : '$',
                    number: '32,540.30'
                },
                {
                    img_class : "flag-icon flag-icon-eu fi-xl fi-round",
                    name : 'European Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary mt-auto',
                    currency : 'Euro',
                    currency_symbol : '€',
                    number: '42,540.30'
                },
                {
                    img_class : "flag-icon flag-icon-gh fi-xl fi-round",
                    name : 'Default Account',
                    isEnableText: 'Enabled',
                    isEnableColor : 'secondary mt-auto',
                    currency : 'Ghanaian Cedi',
                    currency_symbol : '₵',
                    number: '70,548.00'
                },
                {
                    img_class : "flag-icon flag-icon-gb fi-xl fi-round",
                    name : 'UK Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary  mt-auto',
                    currency : 'British Pound',
                    currency_symbol : '£',
                    number: '50,630.50'
                },
                {
                    img_class : "flag-icon flag-icon-us fi-xl fi-round",
                    name : 'US Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary  mt-auto',
                    currency : 'US Dollars',
                    currency_symbol : '$',
                    number: '32,540.30'
                },
                {
                    img_class : "flag-icon flag-icon-eu fi-xl fi-round",
                    name : 'European Office',
                    isEnableText: 'Enable',
                    isEnableColor : 'primary mt-auto',
                    currency : 'Euro',
                    currency_symbol : '€',
                    number: '42,540.30'
                },
                
            ],

            //All Acounts list table json
            items:[
                {currency:{flagClass:"flag-icon flag-icon-gh fi-xl fi-round",cname:'CRE'},name:'Default Account',dopen:'30.12.2016',uuid:'',balance:'₵70,548.00',status:{selected:'accept',class:'btn-outline-success'},show_details:''},
                {currency:{flagClass:"flag-icon flag-icon-gb fi-xl fi-round",cname:'GBP'},name:'UK Office',dopen:'17.01.2017',uuid:'',balance:'£50,630.50',status:{selected:'accept',class:'btn-outline-success'},show_details:''},
                {currency:{flagClass:"flag-icon flag-icon-us fi-xl fi-round",cname:'USD'},name:'US Office',dopen:'15.01.2017',uuid:'',balance:'$32,540.30',status:{selected:'accept',class:'btn-outline-success'},show_details:''},
                {currency:{flagClass:"flag-icon flag-icon-eu fi-xl fi-round",cname:'EUR'},name:'European Office',dopen:'16.01.2017',uuid:'',balance:'€42,540.30',status:{selected:'accept',class:'btn-outline-success'},show_details:''},
            ],
           
            //All Acounts list table header fields
            fields: [
                { key: 'currency', label: 'CURRENCY',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'NAME',sortable: true,thClass:'th_sortfix'},
                { key: 'dopen', label: 'DATE OPENED',sortable: true,thClass:'th_sortfix'},
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'balance', label: 'BALANCE',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'STATUS'},
                { key: 'action', label: 'ACTION', thStyle:'width:50px'},
                { key: 'show_details', label: '', thStyle:'width:40px'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

             //All Acounts list table json
            subsidiariesItems:[
                {currency:{flagClass:"flag-icon flag-icon-gh fi-xl fi-round",cname:'CRE'},name:'PayAngel Group',dopen:'30.12.2016',uuid:'',balance:'₵70,548.00',status:{selected:'accept',class:'btn-outline-success'}},
                {currency:{flagClass:"flag-icon flag-icon-gb fi-xl fi-round",cname:'GBP'},name:'Thememakker Group',dopen:'17.01.2017',uuid:'',balance:'£50,630.50',status:{selected:'accept',class:'btn-outline-success'}},
                {currency:{flagClass:"flag-icon flag-icon-us fi-xl fi-round",cname:'USD'},name:'Wrraptheme Group',dopen:'15.01.2017',uuid:'',balance:'$32,540.30',status:{selected:'accept',class:'btn-outline-success'}},
                {currency:{flagClass:"flag-icon flag-icon-eu fi-xl fi-round",cname:'EUR'},name:'Angel community Group',dopen:'16.01.2017',uuid:'',balance:'€42,540.30',status:{selected:'accept',class:'btn-outline-success'}},
            ],
           
            //All Acounts list table header fields
            subsidiariesFields: [
                { key: 'currency', label: 'CURRENCY',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'NAME',sortable: true,thClass:'th_sortfix'},
                { key: 'dopen', label: 'DATE OPENED',sortable: true,thClass:'th_sortfix'},
                { key: 'uuid', label: 'UUID',sortable: true,thClass:'th_sortfix'},
                { key: 'balance', label: 'BALANCE',sortable: true,thClass:'th_sortfix'},
                { key: 'status', label: 'STATUS'},
                { key: 'action', label: 'ACTION'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All Acounts list table status fields option
            options: [
                { value: 'accept', text: 'Accept' },
                { value: 'cancel', text: 'Cancel' },
            ],
            
             //All Acounts list table status fields option
            subsidiariesOptions: [
                { value: 'accept', text: 'Accept' },
                { value: 'cancel', text: 'Cancel' },
            ], 

            //All Acounts list table pagination options
            totalRows: 1,
            currentPage: 1,
            perPage: 1,
            pageOptions: [1,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            isAllChecked:false,
            subSortBy: '',
            subSortDesc: false,
            //All Acounts curancy dropdawn option
            curancy: [{name: 'Ghanaian Cedi'},{name: 'British Pound'},{name: 'United States Dollar'},{name:'Euro'}],
            currency_options: [
                { id:0,name: 'Ghanaian Cedi', flagClass: "flag-icon flag-icon-gh fi-xl fi-round",isChecked: true},
                { id:1,name: 'British Pound' , flagClass:"flag-icon flag-icon-gb fi-xl fi-round",isChecked: true},
                { id:2,name: 'Indian Rupee', flagClass: "flag-icon flag-icon-in fi-xl fi-round",isChecked: false},
                { id:3,name: 'CFP Franc', flagClass: "flag-icon flag-icon-fr fi-xl fi-round",isChecked: false},
                { id:4,name: 'United States Dollar', flagClass: "flag-icon flag-icon-us fi-xl fi-round",isChecked: true},
                { id:5,name: 'Deutsche Mark',flagClass: "flag-icon flag-icon-de fi-xl fi-round",isChecked: false},
                { id:6,name: 'Euro', flagClass: "flag-icon flag-icon-eu fi-xl fi-round",isChecked: true},
                { id:7,name: 'Russian Ruble', flagClass:"flag-icon flag-icon-ru fi-xl fi-round",isChecked: false},
            ],

            //Add Account Model currency dropdawn v-model Options
            account:{
                currency:null,
                accountName:''
            },

            //Add Account Model currency dropdawn Options
            currencyOptions: [
                { name: 'Ghanaian Cedi', flagClass: "flag-icon flag-icon-gh fi-xl fi-round"},
                { name: 'British Pound' , flagClass:"flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'Indian Rupee', flagClass: "flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'CFP Franc', flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States Dollar', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
                { name: 'Deutsche Mark',flagClass: "flag-icon flag-icon-de fi-xl fi-round"},
                { name: 'Euro', flagClass: "flag-icon flag-icon-eu fi-xl fi-round"},
                { name: 'Russian Ruble', flagClass:"flag-icon flag-icon-ru fi-xl fi-round"},
            ],
            displayAlert: null,
            prevRoute: null
        }
    },methods: {
        //All Acounts list table status fields option on select change color/text 
        onChange(event,index) {
          var classVar =''
            return this.items.map((item,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    item.status.class = classVar
                }
            });
        },

       subsidiariesStatus(event,index) {
          var classVar =''
            return this.subsidiariesItems.map((subitem,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    subitem.status.class = classVar
                }
            });
        },
        //check which tab is active(Grid/List)
        isActiveTab(button_title){
            this.activeTab = button_title;
        },

        //check Single Transfer form Account Number field is empty
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                } else {
                   this.currentPage = paginationMenu;
                }
            } else {
                this.currentPage = 1;
            }
        },

        //All Acounts Currencies dropdawn selection message is no of Currencies selected
        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.curancy[0].name
            }else if(count == this.currency_options.length){
                this.isAllChecked = true;
                return count + ' Currencies Selected.'
              
            }else {
                this.isAllChecked = false;
                return  count + ' Currencies Selected.'
            }
        },

        //Currencies dropdawn checkbox checked(one or more)
        selectAllChanged() {
            if (this.isAllSelected) {
                this.isAllChecked = false;
                this.curancy = [];
                this.currency_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.isAllChecked = true;
                this.curancy = this.currency_options.slice();
                this.currency_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        //all Currencies dropdawn checkbox checked
        isCheckedInput (actionName) {
            this.currency_options[actionName.id].isChecked = !this.currency_options[actionName.id].isChecked
        },

        //add account form submit
        addAccount(){
            this.$bvModal.hide('add_account');
        }
    },mounted() {
        //list table total rows
        this.totalRows = this.items.length
    },computed: {
        //check how many Currencies dropdawn checkbox checked
        isAllSelected: function() {
            return  (this.curancy.length === this.currency_options.length)
        }
    },beforeRouteEnter(to, from, next) {
        next(that => {
            that.prevRoute = from.name
            if(that.prevRoute == 'slogin'){
                const swalWithBootstrapButtons = that.$swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-primary btn-lg my-3',
                        cancelButton: 'btn btn-outline-secondary btn-lg'
                    },
                    buttonsStyling: false
                })
               
                swalWithBootstrapButtons.fire({
                    title: "Setup your profile",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Setup now',
                    cancelButtonText: 'Skip for later',
                }).then((result) => {
                    if (result.isConfirmed) {
                        that.$router.push({ path: '/setting/setup-profile' })
                    }
                })  
            }
        })
    },
}
</script>
