<template>
    <div class="right-content mx-auto my-auto w-50 otp-card">
        <div class="header pb-3 text-center">
            <p class="lead px-3">Ring Ring...</p>
        </div>
        <div class="body">
            <p class="text-center mb-3">Enter the verification code we just sent via text to your mobile phone <strong>+{{contactDetail.userMobile}}</strong></p>
            <p class="text-center mb-3"><a class="mobile-number" href="#">Change your Mobile Number</a></p>
            <div class="otp-form">
                <div id="otp" class="d-flex justify-content-center">
                    <div class="form-group">
                        <v-otp-input
                            ref="otpInput"
                            input-classes="otp-input form-control m-2"
                            separator=" "
                            :num-inputs="5"
                            :should-auto-focus="true"
                            :is-input-num="false"
                            @on-change="handleOnChange"
                            @on-complete="handleOnComplete"
                        />
                    </div>
                </div>
            </div>
            <div class="otp-timer text-center" id="otp_timer">
                <router-link to="#" @click.native="resendOtp()">Resend OTP</router-link>
                <p>
                    <span class="text-danger">{{ wrongOtpMsg }}</span>
                </p>
                <p class="mt-3 mb-0"> 
                    You can request a new code if it does not arrive in: 
                    <label class="timer-text text-danger" id="timer_countdown"></label>
                </p>
            </div>
        </div>
    </div>           
</template>
<script>
// import { mapState } from 'vuex'

export default {
    name:'OtpComponent',
    components: {
        
    },data(){
        return{
            clearTimer:0,
            wrongOtpMsg:''
        }
    },methods: {
        redirectLoginPage: function() {
           this.$router.push({ path: '/authentication/page-login' })
        },

        //timer counter
        countDownTimer() {
            var duration = 24 * 60 * 60
            var timer = duration, hours, minutes, seconds;
            this.clearTimer = setInterval(function () {
                if(document.getElementById("timer_countdown")){
                    hours = parseInt((timer /3600)%24, 10)
                    minutes = parseInt((timer / 60)%60, 10)
                    seconds = parseInt(timer % 60, 10);

                    hours = hours < 10 ? "0" + hours : hours;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    seconds = seconds < 10 ? "0" + seconds : seconds;
                    document.getElementById("timer_countdown").innerHTML = hours +":"+minutes + ":" + seconds;
                }
                --timer;
            }, 1000);
        },
        
        //reset timer 
        resendOtp(){
            let resendOtpData = {
                email: this.contactDetail.userEmail, //"shamik.roy@payincgroup.com",
            }
           this.$http.post('https://standapp.com/api/v1/business/register/resend', resendOtpData)
            .then((res) => {
                //Perform Success Action
                // console.log(res);
                this.$swal('Success!', res.data.message, 'success', 'OK');
                clearInterval(this.clearTimer);
                return this.countDownTimer();
            })
            .catch((error) => {
                // error.response.status Check status code
                // console.log(error.response.data.code);
                this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });

            // clearInterval(this.clearTimer);
            // return this.countDownTimer();
        },

        onLoad: function() {
            var v = this;
            setTimeout(function(){
                v.show_banner = true
            },1000)
        },

        handleOnComplete(value) {
            // console.log('OTP completed: ', value);
            if(value == '11111'){
                this.wrongOtpMsg = 'Please Enter Valid OTP'
            } else{
                this.redirectLoginPage();
            }
        },
        
        // handleOnChange(value) {
        //     console.log('OTP changed: ', value);
        // }
    },created() {
        //OTP Timer
        clearInterval(this.clearTimer)
        this.countDownTimer()
    },beforeDestroy(){
        clearInterval(this.clearTimer)
    }
}
</script>
<style scoped>

</style>