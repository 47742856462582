<template>
     <ul class="header-dropdown">
        <li class="dropdown" v-bind:class="{ 'show': cardToggle}"  @click="cardToggle = !cardToggle" v-click-outside="cardToggleOutside">
            <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"></a>
            <ul class="dropdown-menu dropdown-menu-right" v-bind:class="{ 'show': cardToggle, 'dropdown-light':check_isLight }">
                <li v-for="action in dropdawn_actions" :key="action.key"><a :href="`${action.url}`"><i v-if="action.icon_class" :class="action.icon_class" class="pr-1"></i> {{action.key}}</a></li>
            </ul>
        </li>
    </ul>
</template>
<script>
export default {
    name:'CardActions',
    methods:{
        cardToggleOutside: function() {
            this.cardToggle = false
        },
    },
    props: {
        actions:{
            type: [Array,null],
        },
        isLight: Boolean
    }, 
    data() {
      return {
        cardToggle:false,
        dashboardActions: [
            {
                icon_class: "",
                key: "Action",
                url: "javascript:void(0);"
            },
            {
                icon_class: "",
                key: "Another action",
                url: "javascript:void(0);"
            },
            {
                icon_class: "",
                key: "Something else here",
                url: "javascript:void(0);"
            },
        ]
      }
    },directives: {
        'click-outside': {
            bind: function(el, binding) {
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                if (bubble || (!el.contains(e.target) && el !== e.target)) {
                    binding.value(e)
                }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler)
            },
        }
    },computed: {
        dropdawn_actions: function(){
            if(this.actions){
                return this.actions
            }else{
                return this.dashboardActions;
            }
        },
        
        check_isLight: function(){
            if(this.isLight){
                return true
            }else{
                return false;
            }
        }
    }
}
</script>