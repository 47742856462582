<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb
                :showFilter="true"   
                :showDateFilter="true">
            </bread-crumb>
        </div>
        <!-- Summary -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card top_report card-top-line">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-6" v-for="(toprow,index) in transactions_toprows" :key="index">
                            <transactionstoprow-component
                                :icon = "toprow.icon"
                                :text = "toprow.text"
                                :price = "toprow.price"
                                :progress_class = "toprow.progress_class"
                                :progressbar_width = "toprow.progressbar_width"
                                :compared_text = "toprow.compared_text"
                            ></transactionstoprow-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Recent transactions -->
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header d-flex align-items-center justify-content-between">
                        <h2>Recent transactions</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3 flex-wrap">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="search-transactions">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                                <b-button class="fliter-btn btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                    <div class="d-flex justify-content-between p-3">
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input One"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Two"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Input Three"/>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-primary h-100" @click="isVisibleFilter = false">Apply</button>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false">Cancel</button>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                            <!-- Cancel/Request Approval button-->
                            <div class="btn-grp d-flex flex-column flex-md-row mt-3 mt-lg-0">
                                <b-button variant="outline-primary mr-0 mr-md-1 btn-mw" size="lg" @click="$router.go(-1)">Cancel</b-button>
                                <b-button variant="primary btn-mw mt-3 mt-md-0" size="lg">Request Approval</b-button>
                            </div>
                        </div>
                        <b-table 
                            fixed
                            responsive
                            hover 
                            table-class="js-basic-example table-custom mb-0 risk-table border"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(details)>
                                <b-button 
                                    id="button1" 
                                    variant="outline-primary" 
                                    size="sm" 
                                    v-b-tooltip.hover
                                    :data-original-title="rendomNumber()"
                                    :title="rendomNumber()" 
                                    @click.prevent="copyTooltip($event)">
                                    <i class="fa fa-2x fa-barcode"></i>
                                </b-button>
                            </template>
                            <template #cell(action)>
                                <b-dropdown
                                    size="sm"
                                    split
                                    split-variant="outline-primary"
                                    variant="primary"
                                    text="Select Actions"
                                    menu-class="border-0 shadow">
                                <b-dropdown-item href="#" v-for="(option,index) in options" :key="index" :value="option.value">{{ option.text }}</b-dropdown-item>
                                </b-dropdown>
                            </template>
                            <template #cell(switch)="data">
                                <div class="custom-switch success-bg">
                                    <input type="checkbox" class="custom-switch-input" :id="data.value.id" :checked="data.value.isChecked">
                                    <label class="custom-switch-label" :for="data.value.id">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const TransactionsTopRow = require('@/components/core/TransactionsTopRow.vue').default

export default {
    name:'RiskComponent',
    components: {
        BreadCrumb,
        'transactionstoprow-component': TransactionsTopRow
    },data (){
        return{
            isVisibleFilter: false,
            //Recent transactions action options
            options: [
                { value: 'decline', text: 'Decline' },
                { value: 'decline_blacklist', text: 'Decline Blacklist' },
                { value: 'flag', text: 'Flag' },
                { value: 'process', text: 'Process' },
                { value: 'process3d', text: 'Process3d' },
            ],

            //Recent transactions table data
            items:[
                {code: '40131', description:'Shipping to Billings mismatch',details:'', switch:{id:'customSwitch1',isChecked:false} ,action:''},
                {code: '40132', description:'Shipping to BIN mismatch',details:'', switch:{id:'customSwitch2',isChecked:false} ,action:''},
                {code: '40133', description:'Shipping to IP mismatch',details:'', switch:{id:'customSwitch3',isChecked:false} ,action:''},
                {code: '40134', description:'Shipping to Phone mismatch',details:'', switch:{id:'customSwitch4',isChecked:false} ,action:''},
                {code: '40135', description:'Billings to BIN mismatch',details:'', switch:{id:'customSwitch5',isChecked:true} ,action:''},
                {code: '40136', description:'Billings to IP mismatch',details:'', switch:{id:'customSwitch6',isChecked:false} ,action:''},
                {code: '40137', description:'Billings to Phone mismatch',details:'', switch:{id:'customSwitch7',isChecked:false} ,action:''},
                {code: '40138', description:'IP to BIN mismatch',details:'', switch:{id:'customSwitch8',isChecked:false} ,action:''},
                {code: '40139', description:'BIN to Phone mismatch',details:'', switch:{id:'customSwitch9',isChecked:false} ,action:''},
            ],
           
            //Recent transactions table header
            fields: [
                { key: 'code', label: 'Reason Code',sortable: true,thClass:'th_sortfix'},
                { key: 'description', label: 'Description',sortable: true,thClass:'th_sortfix'},
                { key: 'details', label: 'Details'},
                { key: 'switch', label: 'Status'},
                { key: 'action', label: 'Action'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //Recent transactions table pagination
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            //Summary data
            transactions_toprows:[
                {
                    icon:'fa fa-user-plus',
                    text:'New Members(today)',
                    price:'220',
                    progress_class:'bg-danger',
                    progressbar_width:'87',
                    compared_text:'1% compared to yesterday'
                },
                {
                    icon:'fa fa-calendar',
                    text:'New This Month',
                    price:'100',
                    progress_class:'bg-success',
                    progressbar_width:'28',
                    compared_text:'19% compared to last month'
                },
                {
                    icon:'fa-users',
                    text:'Total Member',
                    price:'3200',
                    progress_class:'bg-info',
                    progressbar_width:'41',
                    compared_text:'19% compared to last year'
                },
                {
                    icon:'fa fa-map-marker',
                    text:'Member Location',
                    price:'3000',
                    progress_class:'bg-warning',
                    progressbar_width:'75',
                    compared_text:'19% compared to last year'
                }
            ],
        }
    },methods: {
        //Recent transactions table go to pagination
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },

        //Recent transactions table Details copy
        copyTooltip: function(e){
            var tooltipText = e.currentTarget.getAttribute('data-original-title');
            const inputCopyText = document.createElement('input')
            inputCopyText.value = tooltipText
            document.body.appendChild(inputCopyText)
            inputCopyText.select()
            document.execCommand('copy')
            document.body.removeChild(inputCopyText)
        },

        //generate random tooltip
        rendomNumber(){
            return Math.floor(Math.random()*90000) + 10000
        }
    },mounted() {
        this.totalRows = this.items.length
    }
}

</script>