<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="card card-top-line no-shadow">
                    <div class="body">
                        <div class="mb-3">
                            <h6>Add New Group</h6>
                        </div>
                        <ValidationObserver v-slot="{ passes }">
                            <form @submit.prevent="passes(groupSubmitForm)" id="add-group">
                                <ul class="list-unstyled mb-0 common-form small-form">
                                    <li>
                                        <ValidationProvider name="groupName" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !group.groupName,'success':group.groupName}">
                                                <span class="float-label">
                                                    <input type="text" v-model="group.groupName" class="form-control" id="groupName" placeholder="Group Name*">
                                                    <label for="groupName" class="control-label">Group Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !group.groupName"></i>
                                                    <i class="fa fa-check" v-if="group.groupName"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                            <div class="country-dropdown">
                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':group.members && !errors[0]}">
                                                    <span class="float-label">
                                                    <label for="Country">Select Members*</label>
                                                        <multiselect
                                                            placeholder=""
                                                            v-model="group.members" 
                                                            :show-labels="false" 
                                                            :options="MemberOptions" 
                                                            :searchable="true">
                                                        </multiselect>  
                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                        <i class="fa fa-check" v-if="group.members && !errors[0]"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </li>
                                    <li>
                                        <strong>Note</strong>
                                        <div class="form-group">
                                            <span class="float-label">
                                                <textarea type="text" v-model="group.note" class="form-control" id="note" placeholder="Note"/>
                                                <div class="line"></div>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group clearfix">
                                            <label class="fancy-checkbox new-fancy">
                                                <input type="checkbox" v-model="group.futureReference">
                                                <span>Save this group for future reference</span>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex flex-column flex-md-row justify-content-center mt-5">
                                            <router-link to="/invoice/customer-list" class="btn btn-secondary btn-lg btn-mw">Cancel</router-link>
                                            <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Use These Details</b-button>
                                        </div>
                                    </li>
                                </ul>
                            </form> 
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
const Multiselect = require('vue-multiselect').default
export default {
    name:'AddGroupComponent',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider,
        Multiselect,
    },data(){
        return{
            group:{
                groupName:'',
                members:null,
                note:'',
                futureReference:true
            },

            MemberOptions:[
                'PayAngel',
                'PayAngel Ghana',
                'PayAngel Group',
                'StandApp Group',
                'Angel Communities'
            ]
        }
    },methods:{
        groupSubmitForm(){
            this.$router.push({ path: '/invoice/customer-list' })
        }
    }
}
</script>