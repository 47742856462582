<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Manage Funds</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Payment reference" aria-label="Pearch by payment reference" aria-describedby="search-fund" v-model="searchText" v-on:keyup.enter="refreshFundTable">
                                    <div class="input-group-append" @click="refreshFundTable()">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                                <b-button class="fliter-btn btn-lg btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                    <div class="d-flex justify-content-between p-3">
                                        <div class="form-group form-select single-multiselect">
                                            <span class="float-label">
                                                <multiselect
                                                    placeholder="Select Filter Type"
                                                    v-model="filterBy"  
                                                    :show-labels="false" 
                                                    :options="filterOption" 
                                                    label="name"
                                                    :searchable="true">
                                                </multiselect> 
                                            </span>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group form-select single-multiselect">
                                            <span class="float-label">
                                                <multiselect
                                                    placeholder="Select Filter Type"
                                                    v-model="condition"  
                                                    :show-labels="false" 
                                                    :options="conditionList" 
                                                    label="name"
                                                    :searchable="true">
                                                </multiselect> 
                                            </span>
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Enter Value" v-model="filterValue" />
                                            <div class="line"></div>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-primary h-100" @click="isVisibleFilter = false; refreshFundTable()">Apply</button>
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false; resetFilterValue()">Cancel</button>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                        <b-table 
                            ref="fundTable"
                            responsive
                            hover 
                            table-class="js-basic-example table-custom mb-0 border"
                            head-variant="light"
                            :items="getFundData" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <!-- <template #cell(dropdawn)="data">
                                <select v-model="data.value.selected" size="sm" class="btn btn-sm btn-filter" :class="data.value.class" @change="onChange($event,(currentPage-1) * perPage + data.index + 1)">
                                    <option v-for="(option,index) in options" :key="index" :value="option.value">{{ option.text }}</option>
                                </select>
                            </template> -->
                            <template #cell(dropdawn)="data">
                                <p class="function-badge badge-group-table mb-0">
                                    <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                </p>
                            </template>
                            <template #cell(proof)="data">
                                <span v-if="data.item.fileexist != ''">
                                    <span @click="downloadfile(data)"><i class="fa fa-download fa-lg" ></i></span>
                                    <span class="ml-4" @click="previewPdf(data.item.id)"><i class="fa fa-eye fa-lg" ></i></span>
                                </span>
                                <span v-if="data.item.fileexist == ''">N/A</span>
                            </template>
                            <template #cell(action)="data">
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item @click="changeStatus(data.item.id,data.item.walletid,'approved')">Approved</b-dropdown-item>
                                    <b-dropdown-item @click="changeStatus(data.item.id,data.item.walletid,'rejected')">Rejected</b-dropdown-item>
                                    <!-- <b-dropdown-item><a href="./Avi-Dave-Resume.pdf" target="_blank">pdf</a></b-dropdown-item> -->
                                    <!--  @click="showAlert" -->
                                </b-dropdown>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
// const { toastFunction } =  require("@/plugins/toast")
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
// const { sweetAlertFunction } =  require("@/plugins/sweetalert2")
import shared from "@/shared.js";
import Multiselect from 'vue-multiselect'

export default {
    name:'FundComponent',
    components: {
        BreadCrumb,
        'main-loader':LoaderComponent,
        Multiselect
    },data(){
        return{
            isVisibleFilter: false,

            transactions_toprows:[
                {
                    icon:'fa-dollar',
                    text:'Amount',
                    price:'$22,500',
                    progress_class:'bg-danger',
                    progressbar_width:'87',
                    compared_text:'19% compared to last week'
                },
                {
                    icon:'fa-bar-chart-o',
                    text:'Count',
                    price:'$500',
                    progress_class:'bg-success',
                    progressbar_width:'28',
                    compared_text:'19% compared to last week'
                },
                {
                    icon:'fa-area-chart',
                    text:'Avg Anount',
                    price:'215',
                    progress_class:'bg-info',
                    progressbar_width:'41',
                    compared_text:'19% compared to last week'
                },
                {
                    icon:'fa-users',
                    text:'No. of Customers',
                    price:'21,215',
                    progress_class:'bg-warning',
                    progressbar_width:'75',
                    compared_text:'19% compared to last week'
                }
            ],

            dashboardActions: [
                {
                    icon_class: "fa fa-send-o",
                    key: "Export as PDF",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "Export as CSV",
                    url: "javascript:void(0);"
                },
            ],

            paymentActions: [
                {
                    icon_class: "fa fa-send-o",
                    key: "Single Payment",
                    url: "javascript:void(0);"
                },
                {
                    icon_class: "fa fa-send-o",
                    key: "Bulk Upload",
                    url: "javascript:void(0);"
                },
            ],

            options: [
                { value: 'pending', text:'Pending' },
                { value: 'approved', text: 'Approved' },
                { value: 'rejected', text: 'Rejected' },
            ],

            items:[
                // {name:'Buy',price:'11900.12',action:''},
                // {name:'Sell',price:'11905.09',action:''},
                // {name:'Buy',price:'11901.85',action:''},
                // {name:'Buy',price:'11899.28',action:''},
            ],
           
            fields: [
                { key: 'name', label: 'Wallet Name',sortable: true,thClass:'th_sortfix'},
                { key: 'busername', label: 'Business User',sortable: true,thClass:'th_sortfix'},
                { key: 'paymentOption', label: 'Payment Option',sortable: true,thClass:'th_sortfix'},
                { key: 'reference', label: 'Payment Reference'},
                { key:'currency', label: 'Currency'},
                { key: 'amount', label:'Amount' },
                { key: 'dropdawn', label: 'Status'},
                { key:'proof', label:'Proof of fund' },
                { key: 'action', label:'Action'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            filterOption: [
                { value: 'walletname', name:'Wallet Name' },
                { value: 'paymentoptionname', name:'Payment Option Name' },
                { value: 'paymentreference', name:'Payment Reference' },
                { value: 'amount', name:'Amount' },
                { value: 'status', name:'Status' },
                { value: 'createdat', name:'Created At'},
                { value: 'createdby', name:'Created By' },
            ],

            conditionList: [
                { value: '==', name:'equals to' },
                { value: '>=', name:'greater than or equals to' },
                { value: '>', name:'greater than' },
                { value: '<', name:'less than' },
                { value: '<=', name:'less than or equals to' },
                { value: 'like', name:'like' }
            ],

            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            searchText: '',
            filterBy: '',
            filterValue: '',
            condition: '',
        }
    },methods: {
        onChange(event,index) {
          var classVar =''
            return this.items.map((item,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    item.dropdawn.class = classVar
                }
            });
        },

        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageJump = e.target.value
                } else {
                   this.currentPage = this.currentPageJump = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageJump = 1;
            }
        },

        async getFundData(ctx) {
            try {
                let page  = '';
                let size = '';
                let sortby = '';
                let sort = false;
                if(ctx.currentPage != undefined) {
                    page =  ctx.currentPage;
                } else {
                    page = 1;
                }
                if(ctx.perPage != undefined) {
                    size = ctx.perPage;
                } else {
                    size = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'createdat'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sort = 'desc'
                } else {
                    sort = 'asc';
                }

                
                this.currentPageJump = page;
                this.items = [];
                let paramData = {
                    sort : sort,
                    sortby : sortby,
                    size : size,
                    page : page,
                    search : this.searchText,
                    filterby : this.filterBy ? this.filterBy.value : '',
                    filtervalue : this.filterValue,
                    condition : this.condition ? this.condition.value : '=='
                }
                const response = await this.$http.get(this.$baseurl+"transactions/funds", { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.transactions.map((item) => {
                    let dropdownMenu = '';
                    if(item.status == 'pending'){
                        dropdownMenu = {selected:'pending',class:'btn-outline-warning',name:'Pending'}
                    } else if(item.status == 'approved') {
                        dropdownMenu = {selected:'approved',class:'btn-outline-success',name:'Approved'}
                    } else {
                        dropdownMenu = {selected:'rejected',class:'btn-outline-danger',name:'Rejected'}
                    }
                    let fundLists = {
                        id:item.id,
                        fileexist: item.downloadfile,
                        walletid : item.walletid,
                        name:item.walletname,
                        busername:item.createdby,
                        paymentOption:item.paymentoptionname,
                        reference:item.paymentreference,
                        currency:item.currencycode,
                        amount:item.amount,
                        dropdawn:dropdownMenu,
                        pdf: this.$baseurl+item.downloadfile,
                        action:''
                    };
                    // console.log(fundLists);
                    this.items.push(fundLists);
                }); 
                this.totalRows = response.data.query.total;
                return this.items;               
            } catch (error) {
                console.log(error);
            }
        },

        refreshFundTable() {
            this.$refs.fundTable.refresh();
        },

        resetFilterValue(){
            this.filterBy= '',
            this.filterValue= '',
            this.condition= '',
            this.refreshFundTable();
        },

        changeStatus(id, walletId, value){
            // console.log(walletId,value);
            this.$refs.loader.show();
            // console.log(event.target.checked, key, row.item.domestic.countryId);
            let dataJson = {
                walletid : walletId,
                status : value
            }
            // console.log(dataJson);
            this.$http.put(this.$baseurl+"transactions/funds/"+id, dataJson,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken") //`Basic ${token}`
                },
            }).then((res) => {
                // console.log(res);
                if(res.data.code == '0101-000') {
                    this.refreshFundTable();
                    this.$refs.loader.hide();
                    shared.toastrSuccess(res.data.message);
                } else {
                    this.refreshFundTable();
                    this.$refs.loader.hide();
                }
            }).catch((error) => {
                //this.$swal('Error!', error.response.data.message, 'error', 'Close');
                this.refreshFundTable();
                this.$refs.loader.hide();
                shared.toastrError(error.response.data.message);
            });
        },

        async downloadfile(data){
            const transactionid = data.item.id;
            const reference = data.item.reference;
            
            try {
                const response = await this.$http.get(this.$baseurl+"transactions/funds/transaction/"+transactionid, { 
                    responseType: 'blob',
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                // Create a blob from the response data
                const blob = new Blob([response.data], { type: 'application/pdf' });

                // Create a link element
                const link = document.createElement('a');

                // Create a URL for the blob and set it as the href attribute
                link.href = window.URL.createObjectURL(blob);

                // Set the download attribute with a filename
                link.download = reference+'.pdf';

                // Append the link to the document body
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Remove the link from the document
                document.body.removeChild(link);
            } catch (error) {
                console.log(error);
            }
        },

        async previewPdf(transactionid) {
            try {
                const response = await this.$http.get(this.$baseurl+"transactions/funds/transaction/"+transactionid, { 
                    responseType: 'blob',
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(blob);
                window.open(pdfUrl, '_blank');
            } catch (error) {
                console.error('Error fetching PDF:', error);
            }
        }
    },mounted() {
        if(sessionStorage.getItem("jwtToken")){
            // this.totalRows = this.items.length;
        } else {
            shared.toastrError('Please login to continue');
            this.$router.push({path:'/standapp-login'});
        }
    }
}
</script>